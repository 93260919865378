/**
 * Reducer for categories
*/
import Types from "../../actions/Types";

export const cost_categories_type_list =(state = {cost_categories:[],isLoaded:false},action)=>{
    switch (action.type) {
        case Types.GET_ALL_COST_CATEGORIES:
            return {...state , cost_categories:action.payload,isLoaded:true};

        case Types.IS_GET_COST_CATEGORY_LOADING:
            return {...state , isLoaded: action.payload}
        default:
            return state;
    }
}

export const cost_category_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_COST_CATEGORY_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}

export const cost_category_data_error = (state  = { visiable : false , errors : [] }  , action) => {
    switch(action.type){
        case Types.IS_GET_COST_CATEGORY_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}


