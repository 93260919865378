const initialState = {
    collapsible: false
}

export const collapsibleDailyDataSections = (state = initialState, action) => {
    switch (action.type) {
        case 'SET':
            return {...state, collapsible:action.payload }
        default:
            return state
    }
}