import Types from "../../actions/Types";
let initialState={
    id: '',
    medicine_name: '',
    unit: '',
    item_code: '',
    description: '',
    long_description: '',
    status: '',
    is_deleted: '',
    doses: '',
    quantity_package_unit: '',
    fk_customer: ''
}
export const medication_type_list =(state ={medication:[],isLoaded:false},action)=>{
    switch (action.type) {
        case Types.GET_ALL_LOOKUPS :
            return {...state,medication:action.payload.medication_names};

        case Types.GET_ALL_MEDICATION_TYPES:
            return {...state,medication:action.payload,isLoaded:true};
        case Types.IS_GET_MEDICATION_TYPE_LOADING:
            return {...state,isLoaded: action.payload}
        default:
            return state;
    }
}
export const medication_type = (state = initialState , action ) => {

    switch(action.type){
        case Types.GET_MEDICATION_TYPE_DATA :
            return  action.payload
        default :
            return state ;
    }
}
export const medication_type_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_MEDICATION_TYPE_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}
export const medication_type_data_error = (state  = { visiable : false , errors : [],initialState }  , action ) => {
    switch(action.type){
        case Types.IS_GET_MEDICATION_TYPE_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}

