/**
 * Reducer for cycle
 */
import Types from "../../actions/Types";
let initialState = {
    id: '',
    cycle_code: '',
}
export const cycle_list = (state = {cycle:[],isLoaded:false} , action ) => {
    switch(action.type){
        case Types.GET_ALL_CYCLES:
            return {...state,cycle: action.payload,isLoaded: true} ;
        case Types.IS_GET_CYCLE_LOADING:
            return {...state,isLoaded: action.payload}
        default :
            return state ;
    }
}
export const cycle_data = (state = initialState , action ) => {
    switch(action.type){
        case Types.GET_CYCLE_DATA :
            return {...state,...action.payload}
        default :
            return state ;
    }
}
export const cycle_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_CYCLE_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}
export const cycle_data_error = (state = { visiable : false , errors : [] }  , action ) => {
    switch(action.type){
        case Types.IS_GET_CYCLE_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}