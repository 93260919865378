import Types from "../../actions/Types";

export const energy_type_list =(state ={energy:[],isLoaded:false},action)=>{
    switch (action.type) {
        case Types.GET_ALL_LOOKUPS :
            return {...state,energy:action.payload.energy_type};

        case Types.GET_ALL_ENERGY_TYPES:
            return {...state,energy: action.payload,isLoaded: true};

        case Types.IS_GET_ENERGY_TYPE_LOADING:
            return {...state,isLoaded: action.payload}

        default:
            return state;
    }
}
export const energy_type = (state = [] , action ) => {
    switch(action.type){
        case Types.GET_ENERGY_TYPE_DATA :
            return [...state , action.payload]
        default :
            return state ;
    }
}
export const energy_type_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_ENERGY_TYPE_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}
export const energy_type_data_error = (state  = { visiable : false , errors : [] }  , action ) => {

    switch(action.type){
        case Types.IS_GET_ENERGY_TYPE_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}


