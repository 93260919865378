import Types from "../../actions/Types";
const initial={
    isLoaded:false,
    cards: [],
    farm_age_chart:[],
    farms: [],
    charts:[],
}
export const dashboard_data_list = (state=initial,action) => {
  switch (action.type) {
      case Types.GET_ALL_DASHBOARD_DATA:
          return {...state,...action.payload,isLoaded: true,}
      default:
          return state
  }
}

export const dashboard_loading = (state = false , action ) => {
    switch (action.type) {
        case Types.DASHBOARD_LOADING :
            return action.payload ;

        default :
            return  state ;
    }
}