/**
 *  Author: Abdulrahman
 *  Reducers File for Other_Types_Category
 */
import Types from "../../actions/Types";
let initialState={
    id: '',
    category_name: '',
}
export const other_type_category_list =(state ={otherCategory:[],isLoaded:false},action)=>{
    
    switch (action.type) {
        case Types.GET_ALL_LOOKUPS :
            return {...state,otherCategory:action.payload.other_type_categories};
        case Types.GET_ALL_OTHER_TYPES_CATEGORIES:
            return {...state,otherCategory: action.payload,isLoaded: true};
        case Types.IS_GET_OTHER_TYPES_CATEGORY_LOADING:
            return {...state,isLoaded: action.payload}
        default:
            return state;
    }
}



export const other_type_category = (state = initialState , action ) => {

    switch(action.type){
        case Types.GET_OTHER_TYPE_CATEGORY_DATA :
            return  action.payload
        default :
            return state ;
    }
}

export const other_type_category_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_OTHER_TYPE_CATEGORY_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}

export const other_type_category_data_error = (state  = { visiable : false , errors : [],initialState }  , action ) => {
    switch(action.type){
        case Types.IS_GET_OTHER_TYPE_CATEGORY_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}


