import axios from "axios";
import SecureLS from "secure-ls";
import { BaseURL } from "./env";

export default () => {

    //getting the user token saved in localstorage and the decrypt it
    var ls = new SecureLS({ encodingType: 'des', isCompression: true, encryptionSecret: '$_!exEcultive-aPp@data*2021.aBuerdAn$$' });
    let token = ls.get('UT')?.data;
    //set baseURL and add organization URL to base URL
    const baseURL = BaseURL;
    const language = localStorage.getItem('i18nextLng') || 'en' ;

    //add token to all APIs headers for authorization and language ...
    let headers = { 'Authorization': `Token ${token}` , "Accept-Language": language } ;

    const requester = axios.create({
        baseURL: baseURL,
        headers,
    });

    //intercept all APIs requests to log them ....
    // requester.interceptors.request.use(request => {
    //     const request_info = JSON.parse(JSON.stringify(request, null, 2)) ;
    //     console.log('Starting Request', request_info.url , request_info.method , request_info.data , request_info.headers['Accept-Language'] ,request_info.headers['Authorization'] )
    //     return request
    // });

    //intercept all APIs responses to check if we got 401 then force logout
    requester.interceptors.response.use(
        (response) =>
            new Promise((resolve, reject) => {
                resolve(response);
            }),
        (error) => {
            if (!error.response) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
            //if any api response with 401 then the app will force logout 
            if (error.response.status === 401) {
                window.localStorage.removeItem('UT');
                window.localStorage.removeItem('i18nextLng')
                window.localStorage.removeItem('persist:root')
                window.location = "/login";
            }
            if (error.response.status === 500) {
                window.location = "/500";
            }else {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
        }
    );

    return requester;
};