export const arabic= {
    "company" : "شركة" ,
    "New version is available" : "يوجد تحديث جديد متاح" ,
    "A new version is available, Install Now to get the new version" : "يتوفر إصدار جديد ، قم بالتثبيت الآن للحصول على الإصدار الجديد",
    "you_don't_have_any_tips" : "ليس لديك نصائح للعنبر المختار في التاريخ الحالي" ,
    "can't_download_report_right_now_try_again_later" : "لا يمكن تنزيل التقرير الآن ، يمكنك المحاولة مرة أخرى لاحقًا." ,
    "Thank you for getting in touch! , One of our colleagues will get back in touch with you soon!Have a great day" : "شكرا لك على تواصلك! ، سيتواصل معك أحد زملائنا قريبًا! نتمنى لك يومًا سعيدًا" ,
    "you_must_select_energy_type" : "يجب عليك تحديد نوع مصدر الطاقة" ,
    "you_must_enter_energy_consumption" : "يجب إدخال استهلاك مصدر الطاقة" ,
    "send" : "أرسل" ,
    "enter_your_text_here" : "أدخل النص هنا..." ,
    "chick_price_must_be_greater_than_or_equal_to_0" : "سعر الكتاكيت يجب ان يكون اكبر من او يساوي 0" ,
    "show_tips" : "عرض النصائح" ,
    "chick_price" : "سعر الكتاكيت" ,
    "extra" : "زيادة" ,
    "please_note_that_you_are_in_a_past_date" : "يرجى ملاحظة أنك في تاريخ ماضي" ,
    "you_can_change_the_date_from_calendar_in_the_header_to_go_to_today_date" : "يمكنك تغيير التاريخ من التقويم في العنوان للانتقال إلى تاريخ اليوم" ,
    "There was a problem with your request" : "حدث خطأ مع طلبك",
    "There was a problem with your request , Don't worry, your data is safe , you can send us an email from here" : "حدث خطأ مع طلبك , لا تقلق بياناتك بأمان , يمكنك ارسال بريد الكترونى لنا من هنا" ,
    "search" : "بحث" ,
    "email_/_phonenumber" : "رقم الهاتف / الايميل" ,
    "please_enter_your_email_or_phonenumber" : "أدخل بريدك الإلكتروني  أو رقم الهاتف",
    "enter_your_email_or_phonenumber_to_reset_password" : "أدخل بريدك الإلكتروني  أو رقم الهاتف لإعادة تعيين كلمة المرور" ,
    "email_or_phone_number_you_entered_is_incorrect" : "عنوان بريد إلكتروني  أو رقم هاتف خاطئ" ,
    "check_your_mail_or_mobile" : "راجع بريدك  أو هاتفك" ,
    "confirm" : "تأكيد",
    "change_language" : "تغيير اللغة" ,
    "are_you_sure_you_want_to_change_app_language_?" : "هل انت متأكد انك تريد تغيير لغة التطبيق ؟" ,
    "digits" : "أرقام" ,
    "edit_farm" : "تعديل بيانات المزرعة" ,
    "no_available_data" : "لا يوجد بيانات متاحة" ,
    "you_can't_access_this_page" : "لا يمكنك الوصول إلى هذه الصفحة" ,
    "add_energy_delivery" : "أضف وارد لمصادر الطاقة" ,
    "add_feed_delivery" : "أضف وارد للعلف" ,
    "it_seems_you_do_not_have_any_active_vaccinations" : "يبدو أنه ليس لديك أي نوع تحصين صالح" ,
    "it_seems_you_do_not_have_any_active_medications" : "يبدو أنه ليس لديك أي نوع علاج صالح" ,
    "it_seems_you_do_not_have_any_energy_types" : "يبدو أنه ليس لديك أي أنواع مصادر طاقة" ,
    "it_seems_you_do_not_have_any_feed_types" : "يبدو أنه ليس لديك أي نوع علف" ,
    "it_seems_you_do_not_have_any_energy_deliveries" : 'يبدو أنه ليس لديك أي وارد من مصادر الطاقة',
    "it_seems_you_do_not_have_any_feed_deliveries" : "يبدو أنه ليس لديك أي وارد من العلف" ,
    "we_sent_you_an_email_or_sms_message_to_confirmed_password_reset":"لقد أرسلنا إليك بريدًا إلكترونيًا أو رسالة نصية قصيرة لإعادة تعيين كلمة المرور",
    //==================== confirmed translation ===============================
    "abu_erdan": "أبو قردان",
    "kg": "كجم",
    "ph": "PH(المياه)",
    "Age": "العمر",
    "Co2": "ثاني أكسيد الكربون",
    "add": "أضف",
    "orp": "ORP (المياه)",
    "ton": "طن",
    "code": "كود",
    "cost": "التكلفة",
    "date": "تاريخ",
    "farm": "مزرعة",
    "feed": "علف",
    "name": "اسم",
    "save": "حفظ",
    "time": "الساعة",
    "type": "النوع",
    "unit": "وحدة",
    "breed": "السلالة",
    "canel": "إلغاء",
    "culls": "إعدامات",
    "cycle": "دورة",
    "doses": "جرعات",
    "email": "بريد الالكتروني",
    "farms": "المزارع",
    "photo": "الصورة",
    "sales": "مبيعات",
    "users": "المستخدمون",
    "Active": "مفعل",
    "active": "مفعل",
    "breeds": "السلالات",
    "cancel": "إلغاء",
    "cycles": "دورات",
    "delete": "حذف",
    "filter": "تصفية",
    "houses": "العنابر",
    "intake": "إستهلاك",
    "log-in": "تسجيل دخول",
    "log_in": "تسجيل دخول",
    "status": "الحالة",
    "synced": "متزامن",
    "update": "تحديث",
    "weight": "وزن",
    "comment": "تعليق",
    "Inactive": "غير مفعل",
    "add_farm": "أضف مزرعة",
    "add_user": "إضافة مستخدم",
    "category": "فئة",
    "comments": "تعليقات",
    "hatchery": "معمل تفريخ",
    "humidity": "الرطوبة",
    "max_temp": " درجة الحرارة العظمي",
    "min_temp": " درجة الحرارة الصغري",
    "password": "كلمه المرور",
    "quantity": "كمية",
    "shortage": "عجز",
    "add_breed": "إضافة سلالة",
    "add_cycle": "أضف دورة",
    "add_house": "أضف عنبر",
    "all_farms": "جميع المزارع",
    "dashbaord": "شاشة العرض",
    "edit_user": "تحرير المستخدم",
    "farm_name": "اسم المزرعة",
    "feed_name": "أسم العلف",
    "item_code": "كود الصنف",
    "last_name": "الأسم الأخير",
    "full_name": "الاسم الكامل",
    "mortality": "النافق",
    "size_area": "المساحة",
    "standards": "قياسي",
    "type_name": "أسم النوع",
    "breed_code": "الكود",
    "breed_name": "اسم السلالة",
    "categories": "الفئات",
    "cycle_code": "كود الدورة",
    "cycle_list": "الدورات",
    "deliveries": "واردات المزرعة",
    "edit_breed": "تحرير السلالة",
    "financials": "الماليات",
    "first_name": "الاسم الأول",
    "house_code": "كود العنبر",
    "house_name": "اسم العنبر",
    "live_birds": "الطيور الحية",
    "medication": "العلاج",
    "other_type": "نوع آخر",
    "placements": "التسكينات",
    "powered by": "مشغل بواسطة",
    "powered_by": "مشغل بواسطة",
    "description": "وصف",
    "direct_cost": "التكاليف المباشرة",
    "farm_filter": "تصفية المزارع",
    "feed_intake": "إستهلاك العلف",
    "governorate": "محافظة",
    "item_status": "حالة الصنف",
    "new_closure": "إضافة إغلاق جديد ",
    "other_types": "أنواع أخرى",
    "select_farm": "اختر المزرعة",
    "temperature": "درجة الحرارة",
    "vaccination": "تحصين",
    "add_new_user": "إضافة مستخدم جديد",
    "add_standard": "أضف قياسي",
    "batch_number": "رقم التشغيلة",
    "edit_closure": "تحرير الإغلاق",
    "farm_closure": "إغلاق المزرعة",
    "inside_house": "داخل العنبر",
    "litter_sales": "مبيعات السبلة",
    "phone_number": "رقم الهاتف",
    "selling_date": "تاريخ البيع",
    "vaccine_name": "اسم التحصين",
    "water_intake": "إستهلاك الماء",
    "add_new_breed": "إضافة سلالة جديدة",
    "add_new_cycle": "أضف دورة جديدة",
    "add_new_house": "أضف عنبر جديد",
    "add_placement": "إضافة تسكين",
    "add_your_farm": "أضف مزرعتك",
    "cost_category": "فئة التكلفة",
    "energy_source": "مصدر طاقة",
    "indirect_cost": "التكاليف غير المباشرة",
    "medicine name": "اسم العلاج",
    "medicine_name": "أسم العلاج",
    "outside_house": "خارج العنبر",
    "unaccountable": "كميات بدون مقابل",
    "Items_per_page": "الصفوف في الجدول",
    "add_other_type": "إضافة نوع آخر",
    "indirect_costs": "التكاليف غير المباشرة",
    "placement_date": "تاريخ التسكين",
    "placement_farm": "مزرعة التسكين",
    "unaccountables": "كميات بدون مقابل",
    "you_are_online": "متصل",
    "add_new_closure": "إضافة إغلاق مزرعة",
    "cost_categories": "فئات التكلفة",
    "dead_on_arrival": "النافق عند الوصول",
    "deliveries_list": "قائمة الواردات",
    "edit_cost_cycle": "تحرير دورة التكلفة",
    "edit_cycle_data": "تحرير بيانات الدورة",
    "edit_house_data": "تحرير بيانات العنبر",
    "expiration_date": "تاريخ إنتهاء الصلاحية",
    "forget_password": "نسيت كلمة المرور؟",
    "medication_name": "اسم العلاج",
    "medication_unit": "الوحدة",
    "placement_house": "عنبر التسكين",
    "you_are_offline": "انت غير متصل",
    "farm_is_required": "أسم المزرعة مطلوبة ",
    "long_description": "وصف طويل",
    "medication_batch": "تشغيلة العلاج",
    "medication_types": "أنواع الأدوية",
    "vaccination_name": "اسم التحصين",
    "vaccination_unit": "وحدة التحصين",
    "add_cost_category": "إضافة فئة التكلفة",
    "add_new_placement": "إضافة تسكين جديد",
    "add_your_house(s)": "أضف العنبر (العنابر)",
    "application_route": "طريقة التطبيق",
    "create_an_account": "انشئ حساب",
    "delete_medication": "حذف العلاج",
    "enter_direct_cost": "أدخل التكلفة المباشرة",
    "place_your_chicks": "ضع الكتاكيت",
    "vaccination_types": "أنواع التحصينات",
    "edit_cost_category": "تحرير فئة التكلفة",
    "placement_quantity": "كمية التسكين",
    "please_enter_email": "الرجاء إدخال البريد الإلكتروني",
    "please_select_farm": "الرجاء إختيار مزرعة",
    "please_select_type": "الرجاء تحديد النوع",
    "add_medication_type": "أضف نوع العلاج",
    "add_new_vaccination": "أضف تحصين جديد",
    "medication_consumed": "العلاج المستهلك",
    "other_category_type": "نوع فئة أخرى",
    "please_select_batch": "الرجاء تحديد التشغيلة",
    "please_select_breed": "الرجاء تحديد السلالة",
    "please_select_cycle": "الرجاء تحديد دورة",
    "please_select_house": "الرجاء تحديد عنبر",
    "delete_energy_delivery" : "حذف وارد مصدر الطاقة",
    "delete_feed_delivery": "حذف وارد الطعام",
    "edit_medication_type": "تعديل نوع العلاج",
    "please_cost_category": "الرجاء إدخال فئة التكلفة",
    "quantity_per_package": "سعة العبوة",
    "add_new_cost_category": "إضافة فئة تكلفة جديدة",
    "quantity_package_unit": "سعة العبوة",
    "add_new_indirect_costs": "إضافة تكاليف غير مباشرة جديدة",
    "breed_code_is_required": "كود السلالة مطلوب",
    "breed_name_is_required": "اسم السلالة مطلوب",
    "house_code_is_required": "كود العنبر مطلوب",
    "house_name_is_required": "اسم العنبر مطلوب",
    "please_enter_farm_name": "الرجاء إدخال اسم المزرعة",
    "please_enter_item_code": "الرجاء إدخال كود  الصنف",
    "please_enter_last_name": "الرجاء إدخال الاسم الأخير",
    "please_enter_full_name": "الرجاء إدخال الاسم الكامل",
    "please_enter_type_name": "الرجاء إدخال اسم النوع",
    "please_select_category": "الرجاء تحديد الفئة",
    "please_select_hatchery": "الرجاء إختيار معمل التفريخ",
    "please_enter_breed_code": "الرجاء إدخال كود السلالة",
    "please_enter_breed_name": "الرجاء إدخال اسم السلالة",
    "please_enter_cycle_code": "الرجاء إدخال كود الدورة",
    "please_enter_first_name": "الرجاء إدخال الاسم الأول",
    "please_enter_house_code": "الرجاء إدخال كود العنبر",
    "please_enter_house_name": "الرجاء إدخال اسم العنبر",
    "please_enter_your_email": "أدخل بريدك الإلكتروني",
    "please_select_energy_type" : "الرجاء تحديد نوع مصدر الطاقة" ,
    "please_select_feed_type": "الرجاء تحديد نوع العلف",
    "select_vaccination_name": "اختر اسم التحصين",
    "add_new_vaccination_type": "إضافة نوع جديد من التحصين",
    "cost_must_be_more_than_0": "يجب أن تكون التكلفة أكثر من 0",
    "please_enter_description": "الرجاء إدخال الوصف",
    "select_vaccination_batch": "اختر تشغيلة التحصين",
    "don't_have_an_account_yet": "لا تملك حسابا حتى الآن",
    "please_enter_doses_number": "الرجاء إدخال عدد الجرعات",
    "please_enter_phone_number": "الرجاء إدخال رقم الهاتف",
    "area_has_to_be_more_than_0": "يجب أن تكون المساحة أكثر من 0",
    "delete_medication_delivery": "حذف وارد العلاج",
    "delete_other_type_delivery": "حذف وارد من أنواع اخرى",
    "please_enter_cost_category": "الرجاء إدخال فئة التكلفة",
    "please_enter_your_password": "أدخل كلمة المرور",
    "select_other_type_category": "حدد فئة النوع الآخر",
    "cycle_code_name_is_required": "كود الدورة مطلوب",
    "delete_vaccination_delivery": "حذف وارد التحصين",
    "expiration_date_is_required": "تاريخ انتهاء الصلاحية مطلوب",
    "you_must_enter_batch_number": "يجب عليك إدخال رقم التشغيلة",
    "edit_cost_cyclecost_category": "تحرير فئة التكلفة ",
    "please_enter_dead_on_arrival": "الرجاء إدخال كمية النافق عند الوصول",
    "please_enter_house_size_area": "الرجاء إدخال مساحة العنبر",
    "please_enter_medication_name": "الرجاء إدخال اسم العلاج",
    "please_enter_medication_unit": "من فضلك إدخل وحدة العلاج",
    "please_select_placement_farm": "الرجاء تحديد مزرعة التسكين",
    "quantity_must_be_more_than_0": "يجب أن تكون الكمية أكثر من 0",
    "vaccination_name_is_required": "اسم التحصين مطلوب",
    "please_enter_long_description": "الرجاء إدخال وصف طويل",
    "please_enter_vaccination_name": "الرجاء إدخال اسم التحصين",
    "please_enter_vaccination_unit": "من فضلك ادخل وحدة  التحصين",
    "please_select_medication_type": "الرجاء تحديد نوع العلاج",
    "please_select_placement_house": "الرجاء إختيار عنبر التسكين",
    "cost_category_name_is_required": "مطلوب اسم فئة التكلفة",
    "please_enter_vaccination_doses": "الرجاء إدخال جرعات  التحصين",
    "please_select_vaccination_type": "الرجاء تحديد نوع التحصين",
    "please_enter_placement_quantity": " الرجاء إدخال الكمية المسكنة",
    "you_must_enter_delivery_quantity": "يجب عليك إدخال كمية الوارد",
    "password_you_entered_is_incorrect": "كلمة المرور التي أدخلتها غير صحيحة",
    "please_enter_quantity_package_unit": "الرجاء إدخال سعة العبوة",
    "please_enter_medication_description": "الرجاء إدخال وصف العلاج",
    "are_you_sure_you_want_to_delete_farm": "هل أنت متأكد انك تريد حذف المزرعة",
    "are_you_sure_you_want_to_delete_user": "هل أنت متأكد انك تريد حذف المستخدم",
    "are_you_sure_you_want_to_delete_breed": "هل أنت متأكد انك تريد حذف السلالة",
    "are_you_sure_you_want_to_delete_cycle": "هل أنت متأكد انك تريد حذف الدورة",
    "are_you_sure_you_want_to_delete_house": "هل أنت متأكد انك تريد حذف العنبر",
    "are_you_sure_you_want_to_delete_closure": "هل أنت متأكد انك تريد حذف الإغلاق",
    "are_you_sure_you_want_to_delete_hatchery": "هل أنت متأكد انك تريد حذف معمل التفريخ",
    "are_you_sure_you_want_to_delete_standard": "هل أنت متأكد انك تريد حذف القياسي",
    "please_enter_medication_long_description": "الرجاء إدخال وصف طويل للعلاج",
    "are_you_sure_you_want_to_delete_placement": "هل أنت متأكد انك تريد حذف التسكين",
    "are_you_sure_you_want_to_delete_medication": "هل أنت متأكد من حذف العلاج",
    "are_you_sure_you_want_to_delete_cost_category": "هل أنت متأكد أنك تريد حذف فئة التكلفة",
    "are_you_sure_you_want_to_delete_feed_delivery": "هل أنت متأكد أنك تريد حذف وارد العلف",
    "are_you_sure_you_want_to_delete_energy_delivery" : "هل أنت متأكد أنك تريد حذف وارد مصدر الطاقة",
    "are_you_sure_you_want_to_delete_other_delivery": "هل أنت متأكد أنك تريد حذف وارد من أنواع اخرى",
    "are_you_sure_you_want_to_delete_medication_delivery": "هل أنت متأكد أنك تريد حذف وارد العلاج",
    "are_you_sure_you_want_to_delete_vaccination_delivery": "هل أنت متأكد أنك تريد حذف وارد التحصين",
    "edit_indirect_cost": "تعديل التكلفة الغير مباشرة",
    "age": "عمر",
    "std": "قياسي",
    "co_2": "ثاني أكسيد الكربون",
    "edit": "تعديل",
    "loss": "خصومات",
    "price": "سعر",
    "selling": "بيع",
    "max_co_2": "الحد الأقصي لثاني أكسيد الكربون",
    "min_co_2": "الحد الأدنى لثاني أكسيد الكربون",
    "the_date": "التاريخ",
    "the_time": "الوقت",
    "total_kg": "وزن المبيعات",
    "best_farm": "الأفضل",
    "dashboard": "شاشة العرض",
    "summaries": "الملخصات",
    "cull_types": "أنواع الإعدامات",
    "daily_data": "البيانات اليومية",
    "farms_data": "بيانات المزارع",
    "farms_list": "قائمة المزارع",
    "feed_types": "أنواع العلف",
    "hatcheries": "معامل التفريخ",
    "price / kg": "السعر / كجم",
    "sales_type": "نوع المبيعات",
    "statistics": "الإحصاء",
    "worst_farm": "الأسوأ",
    "consumption": "الاستهلاك",
    "delete_farm": "حذف المزرعة",
    "energy_type": "نوع الطاقة",
    "reject_type": "نوع الإستبعاد",
    "sales_types": "أنواع المبيعات",
    "select_type": "اختر نوع",
    "total_wight": "إجمالي الوزن",
    "add_cul_type": "إضافة نوع معدم",
    "add_hatchery": "أضف معمل تفريخ",
    "add_new_farm": "أضف مزرعة جديدة",
    "average_co_2": "متوسط ثاني أكسيد الكربون",
    "culls_weight": "وزن المعدم",
    "delete_breed": "حذف السلالة",
    "delete_cycle": "حذف الدورة",
    "delete_house": "حذف العنبر",
    "energy_types": "أنواع الطاقة",
    "feed_type #1": "نوع العلف # 1",
    "feed_type #2": "نوع العلف # 2",
    "select_cycle": "اختر الدورة",
    "un_accounted": "غير محسوب",
    "add_feed_type": "أضف نوع العلف",
    "category_name": "اسم الفئة",
    "feed_batch #1": "تشغيلة العلف # 1",
    "feed_batch #2": "تشغيلة العلف # 2",
    "hatchery_code": "كود معمل التفريخ",
    "hatchery_name": "اسم معمل التفريخ",
    "justification": "التوضيح",
    "quantity_left": "الكمية المتبقية",
    "show_sections": "أظهار جميع الأقسام",
    "add_sales_type": "إضافة نوع المبيعات",
    "culls_quantity": "كمية المعدم",
    "edit_feed_type": "تحرير نوع العلف",
    "feed_intake #1": "إستهلاك العلف # 1",
    "feed_intake #2": "إستهلاك العلف # 2",
    "sales_quantity": "كمية المبيعات",
    "add_energy_type": "أضف نوع الطاقة",
    "delete_hatchery": "حذف معمل التفريخ",
    "document_number": "رقم المستند",
    "other_type_name": "اسم النوع آخر",
    "write_a_comment": "أكتب تعليقًا",
    "add_farm_closure": "إضف إغلاق مزرعة",
    "add_new_hatchery": "أضف معمل تفريخ جديد",
    "code_is_required": "الكود مطلوب",
    "delete_cull_type": "حذف نوع معدم",
    "delete_feed_type": "حذف نوع العلف",
    "delete_placement": "حذف التسكين",
    "delete_sale_type": "حذف نوع المبيعات",
    "energy_type_data": "بيانات نوع الطاقة",
    "energy_type_name": "اسم نوع الطاقة",
    "humidity_evening": " الرطوبة مساء",
    "humidity_mid_day": "الرطوبة في منتصف النهار",
    "humidity_morning": "الرطوبة صباحاً",
    "mortality_weight": "وزن النافق",
    "name_is_required": "الأسم مطلوب",
    "number_of_houses": "عدد العنابر",
    "unit_is_required": "الوحدة مطلوبة",
    "add_new_cull_type": "إضافة نوع معدم جديد",
    "breed_is_required": " السلالة مطلوبة",
    "consumed_quantity": "الكمية المستهلكة",
    "delete_other_type": "حذف نوع آخر",
    "edit_culls_record": "تحرير سجل المعدم",
    "edit_energy_record" : "تعديل سجل الطاقة" ,
    "edit_sales_record": "تحرير سجل المبيعات",
    "please_enter_unit": "الرجاء إدخال الوحدة",
    "select_sales_type": "اختر نوع المبيعات",
    "delete_energy_type": "حذف نوع الطاقة",
    "delete_vaccination": "حذف التحصين",
    "doses_are_required": "الجرعات مطلوبة",
    "edit_hatchery_data": "تحرير بيانات معمل التفريخ",
    "mortality_quantity": "كمية النافق",
    "weight_is_required": "الوزنمطلوب",
    "add_sales_type_data": "أضف نوع المبيعات",
    "delete_farm_closure": "حذف إغلاق المزرعة",
    "edit_cull_type_data": "تحرير بيانات نوع المعدم",
    "edit_placement_data": "تحرير بيانات التسكين",
    "quantity_as_hatched": "الكمية المسكنة",
    "select_batch_number": "حدد رقم التشغيلة",
    "add_vaccination_type": "اضف نوع التحصين",
    "edit_sales_type_data": "تحرير بيانات نوع المبيعات",
    "other_types_category": "فئة الأنواع الأخرى",
    "quantity_is_required": "الكمية مطلوبة",
    "select_category_name": "اختر اسم الفئة",
    "cull_type_is_required": "نوع المعدم مطلوب",
    "edit_energy_type_data": "تحرير بيانات نوع الطاقة",
    "edit_mortality_record": "تحرير سجل النافق",
    "farm_name_is_required": "اسم المزرعة مطلوب",
    "item_code_is_required": "كود الصنف مطلوب",
    "rejects_types_choices": "خيارات أنواع الإستبعادات",
    "type_name_is_required": "اسم النوع مطلوب",
    "cycle_code_is_required": "كود الدورة مطلوب",
    "edit_medication_record": "تعديل سجل العلاج",
    "edit_other_type_record": "تعديل سجل النوع الآخر",
    "live_birds_is_required": "عدد الطيور الحية مطلوب",
    "other_type_consumption": "استهلاك النوع آخر",
    "other_types_categories": "فئات الأنواع الأخرى",
    "please_enter_feed_name": "الرجاء إدخال اسم نوع العلف",
    "sales_type_is_required": "نوع المبيعات مطلوب",
    "select_medication_name": "حدد اسم العلاج",
    "add_medication_delivery": "إضافة علاج وارد",
    "add_other_type_category": "أضف فئة أنواع أخرى",
    "add_other_type_delivery": "إضافة وارد من الأنواع الأخرى",
    "consumption_is_required": "الاستهلاك مطلوب",
    "cull_weight_is_required": "وزن المعدم مطلوب",
    "description_is_required": "الوصف مطلوب",
    "edit_vaccination_record": "تعديل سجل التحصين",
    "energy_type_is_required": "نوع الطاقة مطلوب",
    "sales_price_is_required": "سعر البيع مطلوب",
    "add_vaccination_delivery": "أضف وارد التحصين",
    "batch_number_is_required": "رقم التشغيلة مطلوب",
    "edit_other_type_category": "تحرير فئة أنواع أخرى",
    "other_type_category_name": "اسم فئة النوع آخر",
    "select_application_route": "حدد طريقة التطبيق",
    "selling_date_is_required": "تاريخ البيع مطلوب",
    "edit_unaccountable_record": "تحرير سجل الكميات بدون مقابل",
    "hatchery_code_is_required": "كود معمل التفريخ مطلوب",
    "hatchery_name_is_required": "اسم معمل التفريخ مطلوب",
    "justification_is_required": "التبرير مطلوب",
    "please_enter_litter_sales": "الرجاء إدخال مبيعات السبلة",
    "please_select_governorate": "الرجاء إختيار المحافظة",
    "you_must_select_feed_type": "يجب عليك إختيار نوع العلف",
    "culls_quantity_is_required": "كمية المعدم مطلوبة",
    "dead_on_arrival_as_hatched": "كمية النافق عند الوصول",
    "delete_other_type_category": "حذف فئة أنواع أخرى",
    "edit_vaccination_type_data": "تحرير بيانات نوع التحصين",
    "feed_type_name_is_required": "اسم نوع العلف مطلوب",
    "placement_date_is_required": "تاريخ التسكين مطلوب",
    "please_enter_category_name": "الرجاء إدخال اسم الفئة",
    "please_enter_hatchery_code": "الرجاء إدخال كود معمل التفريخ",
    "please_enter_hatchery_name": "الرجاء إدخال اسم معمل التفريخ",
    "please_enter_rejects_types": "الرجاء إدخال أنواع الإعدام",
    "sales_quantity_is_required": "كمية المبيعات مطلوبة",
    "you_must_enter_feed_intake": "يجب عليك إدخال العلف المستهلك",
    "you_must_select_feed_batch": "يجب عليك إختيار تشغيلة العلف",
    "dead_on_arrival_is_required": "النافق عند الوصول مطلوب",
    "document_number_is_required": "رقم التشغيلة مطلوب",
    "medication_name_is_required": "اسم العلاج مطلوب",
    "medication_unit_is_required": "وحدة العلاج مطلوبة",
    "other_type_name_is_required": "اسم النوع الآخر مطلوب",
    "weight_can't_be_less_than_0": "لا يمكن أن يكون الوزن أقل من 0",
    "long_description_is_required": "الوصف الطويل مطلوب",
    "medication_batch_is_required": "تشغيلة العلاج مطلوبة",
    "vaccination_unit_is_required": "وحدة التحصين مطلوبة",
    "application_route_is_required": "طريقة التطبيق مطلوبة",
    "please_enter_energy_type_name": "الرجاء إدخال اسم نوع الطاقة",
    "vaccination_batch_is_required": "تشغيلة التحصين مطلوبة",
    "you_must_enter_culls_quantity": "يجب عليك إدخال كمية المعدم",
    "max_co2_must_be_numerical_value": "يجب أن يكون الحد الأقصى من ثاني أكسيد الكربون قيمة عددية",
    "min_co2_must_be_numerical_value": "يجب أن يكون الحد الأدنى من ثاني أكسيد الكربون قيمة عددية",
    "intake_must_be_a_positive_number": "الإستهلاك يجب أن يكون رقمًا موجبًا",
    "quantity_must_be_numerical_value": "يجب أن تكون الكمية قيمة عددية",
    "sales_total_quantity_is_required": "الكمية الإجمالية للمبيعات مطلوبة",
    "weight_must_be_a_numerical_value": "يجب أن يكون الوزن قيمة عددية",
    "weight_must_be_a_positive_number": "يجب أن يكون الوزن عددًا موجبًا",
    "houses_number_must_be_more_than_0": "يجب أن يكون رقم العنابر أكثر من 0",
    "please_enter_quantity_per_package": "الرجاء إدخال سعة العبوة",
    "quantity_package_unit_is_required": "سعة العبوة مطلوبة",
    "you_must_enter_mortality_quantity": "يجب إدخال كمية النافق",
    "Farm with this Name already exists": "المزرعة بهذا الاسم موجودة بالفعل",
    "live_birds_must_be_numerical_value": "يجب أن تكون الطيور الحية ذات قيمة عددية",
    "quantity_must_be_a_numerical_value": "يجب أن تكون الكمية قيمة عددية",
    "quantity_must_be_a_positive_number": "يجب أن تكون الكمية رقمًا موجبًا",
    "average_co2_must_be_numerical_value": "يجب أن يكون متوسط ثاني أكسيد الكربون قيمة عددية",
    "are_you_sure_you_want_to_delete_feed": "هل أنت متأكد أنك تريد حذف العلف",
    "are_you_sure_you_want_to_delete_type": "هل أنت متأكد أنك تريد حذف نوع آخر",
    "other_type_category_name_is_required": "مطلوب اسم فئة الأنواع الأخرى",
    "Are you sure you want to delete cycle": "هل_انت_متأكد_انك_تريد_حذف_ الدورة",
    "consumption_must_be_a_numerical_value": "يجب أن يكون الاستهلاك قيمة عددية",
    "sales_price_must_be_a_numerical_value": "يجب أن يكون سعر المبيعات قيمة عددية",
    "sales_price_must_be_a_positive_number": "يجب أن يكون سعر المبيعات رقمًا موجبًا",
    "sales_total_must_be_a_numerical_value": "يجب أن يكون إجمالي المبيعات قيمة عددية",
    "sales_total_must_be_a_positive_number": "يجب أن يكون إجمالي المبيعات رقمًا موجبًا",
    "vaccination_doses_must_be_more_than_0": "يجب أن تكون جرعات التحصين أكبر من 0",
    "rejects_types_choices_name_is_required": "خيارات أنواع اإستبعادات مطلوبة",
    "weight_must_be_less_than_or_equal_to_4": "يجب أن يكون الوزن أقل من أو يساوي 4",
    "min_co2_can_not_be_greater_than_max_co2": "لا يمكن أن يكون الحد الأدنى لثاني أكسيد الكربون أكبر من الحد الأقصى لثاني أكسيد الكربون",
    "temperature_should_be_between_1_and_50": "يجب أن تتراوح درجة الحرارة بين 1 و 50",
    "average_co2_can_not_be_less_than_min_co2": "لا يمكن أن يكون متوسط ثاني أكسيد الكربون أقل من الحد الأدنى لثاني أكسيد الكربون",
    "quantity_per_package_must_be_more_than_0": "يجب أن تكون سعة العبوة أكبر من 0",
    "sales_quantity_must_be_a_numerical_value": "يجب أن تكون كمية المبيعات قيمة عددية",
    "sales_quantity_must_be_a_positive_number": "يجب أن تكون كمية المبيعات رقمًا موجبًا",
    "vaccination_doses_must_be_integer_number": "يجب أن تكون جرعات التحصين عددًا صحيحًا",
    "are_you_sure_you_want_to_delete_cull_type": "هل أنت متأكد أنك تريد حذف نوع المعدم",
    "are_you_sure_you_want_to_delete_sale_type": "هل أنت متأكد أنك تريد حذف نوع المبيعات",
    "item_code_has_to_be_at_most_20_characters": "يجب ألا يزيد كود الصنف عن 20 حرفًا",
    "max_co2_can_not_less_greater_than_min_co2": "لا يمكن أن يكون الحد الأقصى لثاني أكسيد الكربون أقل من الحد الأدنى لثاني أكسيد الكربون",
    "weight_must_be_greater_than_or_equal_to_0": "يجب أن يكون الوزن أكبر من أو يساوي 0",
    "'max_co2_can_not_less_greater_than_min_co2": "لا يمكن أن يكون الحد الأقصى لثاني أكسيد الكربون أقل من الحد الأدنى لثاني أكسيد الكربون",
    "percentage_must_be_less_than_or_equal_to_0": "يجب أن تكون النسبة المئوية أكبر من أو تساوي 0",
    "are_you_sure_you_want_to_delete_energy_type": "هل أنت متأكد أنك تريد حذف نوع الطاقة",
    "are_you_sure_you_want_to_delete_vaccination": "هل أنت متأكد أنك تريد حذف التحصين",
    "average_co2_can_not_be_greater_than_max_co2": "لا يمكن أن يكون متوسط ثاني أكسيد الكربون أكبر من الحد الأقصى لثاني أكسيد الكربون",
    "min_co2_can_not_be_greater_than_average_co2": "لا يمكن أن يكون الحد الأدنى لثاني أكسيد الكربون أكبر من متوسط ثاني أكسيد الكربون",
    "percentage_must_be_less_than_or_equal_to_100": "يجب أن تكون النسبة المئوية أقل من أو تساوي 100",
    "live_birds_must_be_greater_than_or_equal_to_0": "يجب أن تكون الطيور الحية أكبر من أو تساوي 0",
    "max_co2_can_not_less_greater_than_average_co2": "لا يمكن أن يكون الحد الأقصى لثاني أكسيد الكربون أقل من متوسط ثاني أكسيد الكربون",
    "consumption_must_be_greater_than_or_equal_to_0": "يجب أن يكون الاستهلاك أكبر من أو يساوي 0",
    "feed_type_name_has_to_be_at_most_50_characters": "يجب ألا يزيد اسم نوع العلف عن 50 حرفًا",
    "quantity_per_package_must_be_an_integer_number": "يجب أن تكون سعة العبوة عددًا صحيحًا",
    "litter_sales_must_be_greater_than_or_equal_to_0": "يجب أن تكون مبيعات السبلة أكبر من أو تساوي 0",
    "unaccountable_quantity_must_be_a_positive_number": "يجب أن تكون الكمية غير المحسوبة رقمًا موجبًا",
    "vaccination_name_has_to_be_at_most_20_characters": "يجب ألا يزيد اسم التحصين عن 20 حرفًا",
    "vaccination_unit_has_to_be_at_most_20_characters": "يجب ألا تزيد وحدة التحصين عن 20 حرفًا",
    "are_you_sure_you_want_to_delete_placement_on_date": "هل أنت متأكد أنك تريد حذف التسكين في التاريخ",
    "it_seems_you_do_not_have_any_medication_deliveries": "يبدو أنه ليس لديك أي وارد من العلاج",
    "it_seems_you_do_not_have_any_other_type_deliveries": "يبدو أنه ليس لديك أي وارد من الأنواع الأخرى",
    "are_you_sure_you_want_to_delete_other_type_category": "هل أنت متأكد أنك تريد حذف فئة الأنواع الأخرى",
    "it_seems_you_do_not_have_any_other_type_deliveries\n": "يبدو أنه ليس لديك أي وارد من الأنواع الأخرى",
    "it_seems_you_do_not_have_any_vaccination_deliveries": "يبدو أنه ليس لديك أي واردات تحصينات",
    "are_you_sure_you_want_to_delete_farm_closure_in_date": "هل أنت متأكد من أنك تريد حذف تاريخ إغلاق المزرعة",
    "medication_quantity_must_be_greater_than_or_equal_to_0": "يجب أن تكون كمية العلاج أكبر من أو تساوي 0",
    "maximum_temperature_can_not_be_less_than_minimum_temperature": "لا يمكن أن تكون درجة الحرارة العظمى أقل من درجة الحرارة الصغرى",
    "dead_on_arrival_must_be_less_than_or_equal_placement_quantity": "يجب أن يكون النافق عند الوصول أقل من أو يساوي الكمية المسكنة"
    ,
    //==================== end of confirmed translation ========================
//==================================================

    "FCR": "معامل التحويل الفذائي",
    "next": "التالي",
    "costs": "التكاليف",
    "login": "تسجيل الدخول",
    "phone": "هاتف",
    "reset": "إعادة ضبط",
    "arabic": "عربي",
    "finish": "أنهاء",
    "logout": "تسجيل خروج",
    "wizard": "وصول سريع",
    "country": "دولة",
    "english": "إنجليزي",
    "expired": "منتهي الصلاحية",
    "medicine": "العلاج",
    "previous": "سابق",
    "register": "تسجيل",
    "username": "اسم المستخدم",
    "user_name_is_required": "اسم المستخدم مطلوب",
    "please_enter_user_name": "الرجاء إدخال اسم المستخدم",
    "water_ph": "درجة حموضة الماء",
    "cull_type": "نوع المعدم",
    "farm_data": "بيانات المزرعة",
    "feed_type": "نوع العلف",
    "for_cycle": "للدورة",
    "water_orp": "جهد الاختزال للماء",
    "house_area": "مساحة العنبر(بالمتر)",
    "house_data": "بيانات العنبر",
    "management": "إدارة المزارع",
    "create_date": "تاريخ الإنشاء",
    "delete_user": "مسح المستخدم",
    "live_weight": "الوزن",
    "add_house(s)": "إضافة عنبر (عنابر)",
    "breed_filter": "تصفية السلالات",
    "company_name": "اسم الشركة",
    "max_humidity": "الرطوبة القصوى",
    "min_humidity": "الرطوبة الدنيا",
    "new_password": "كلمة مرور جديدة",
    "place_chicks": "التسكين",
    "total_weight": "الوزن الكلي",
    "back_to_login": "العودة إلى تسجيل الدخول",
    "edit_standard": "تحرير المعيار",
    "placement_doa": "النافق عند الوصول",
    "water_orp_can": "علبة ماء orp",
    " edit_standard": "تحرير المعيار",
    "administration": "الادارة",
    "broilers_years": "عدد الدجاج في الدورة",
    "configurations": "الإعدادات",
    "placement_data": "بيانات التسكين",
    "select_a_breed": "اختر سلالة",
    "select_country": "حدد الدولة",
    "add_direct_cost": "أضف التكلفة المباشرة",
    "age_is_required": "العمر مطلوب",
    "check_your_mail": "راجع بريدك",
    "delete_standard": "حذف قياسي",
    "medication_type": "نوع العلاج",
    "other_type_data": "نوع البيانات الأخرى",
    "add_new_standard": "أضف معيارًا جديدًا",
    "confirm_password": "تأكيد كلمة المرور",
    "edit_direct_cost": "تحرير التكلفة المباشرة",
    "please_enter_age": "الرجاء إدخال العمر",
    "please_enter_fcr": "الرجاء إدخال fcr",
    "vaccination_type": "نوع التحصين",
    "add_indirect_cost": "أضف التكلفة غير المباشرة",
    "email_is_required": "البريد الالكتروني مطلوب",
    "email_or_phone_number_is_required": "مطلوب البريد الالكتروني  أو رقم الهاتف",
    "house_is_required": "العنبر مطلوب",
    "delete_direct_cost": "احذف التكلفة المباشرة",
    "server_unreachable": "الخادم لا يمكن الوصول إليه",
    "daily_data_sections": "أقسام البيانات اليومية",
    "invalid_mail_format": "بريد إلكتروني غير صالح",
    "other_type_category": "فئة نوع أخرى",
    "you_must_enter_cost": "يجب عليك إدخال التكلفة",
    "delete_cost_category": "احذف فئة التكلفة",
    "delete_indirect_cost": "احذف التكلفة غير المباشرة",
    "invalid_email_format": "بريد إلكتروني غير صالح",
    "password_is_required": "كلمة المرور مطلوبة",
    "passwords_must_match": "يجب أن تتطابق كلمات المرور",
    "something_went_wrong": "هناك خطأ ما",
    "username_is_required": "اسم المستخدم مطلوب",
    "area_size_is_required": "مساحة العنبر مطلوبة",
    "last_name_is_required": "إسم العائلة مطلوب",
    "please_enter_username": "الرجاء إدخال اسم المستخدم",
    "please_enter_water_ph": "الرجاء إدخال درجة الحموضة في الماء",
    "please_select_country": "الرجاء تحديد الدولة",
    "can_not_save_your_data": "لا يمكن حفظ البيانات الخاصة بك",
    "first_name_is_required": "الإسم الأول مطلوب",
    "house_area_is_required": "مساحة العنبر مطلوبة",
    "please_enter_mortality": "الرجاء إدخال معدل الوفيات",
    "please_enter_type_unit": "الرجاء إدخال نوع الوحدة",
    "please_enter_water_orp": "الرجاء إدخال الماء ORP",
    "broilers_configurations": "إعدادات التسمين",
    "max_outside_temperature": "أقصى درجة حرارة خارجية",
    "min_outside_temperature": "دقيقة خارج درجة الحرارة",
    "please_enter_house_area": "الرجاء إدخال مساحة العنبر",
    "resent_account_password": "إعادة إرسال كلمة مرور الحساب",
    "send_confirmation_email": "إرسال بريد إلكتروني للتأكيد",
    "send_confirmation": "إرسال التأكيد",
    "successfully_registered": "سجلت بنجاح",
    "successfully_registeret": "تم التسجيل بنجاح",
    "FCR_has_to_be_at_least_0": "يجب أن يكون FCR 0 على الأقل",
    "and_confirm_registration": "وقم بتأكيد التسجيل",
    "company_name_is_required": "اسم الشركة مطلوب",
    "phone_number_is_required": "رقم الهاتف مطلوب",
    "please_enter_feed_intake": "الرجاء إدخال كمية العلف",
    "please_enter_live_weight": "الرجاء إدخال الوزن الحي",
    "please_enter_temperature": "الرجاء إدخال درجة الحرارة",
    "houses_number_is_required": "عدد العنابر مطلوب",
    "placement_doa_is_required": "النافق عند الوصول مطلوب",
    "please_enter_company_name": "الرجاء إدخال اسم الشركة",
    "please_enter_max_humidity": "الرجاء إدخال الرطوبة القصوى",
    "please_enter_min_humidity": "الرجاء إدخال الحد الأدنى من الرطوبة",
    "please_enter_water_intake": "الرجاء إدخال كمية الماء",
    "you_must_specify_a_number": "يجب عليك تحديد رقم",
    "please_enter_placement_doa": "الرجاء إدخال النافق عند الوصول",
    "farm_age_has_to_be_at_most_0": "يجب أن يكون عمر المزرعة 0 على الأقل",
    "ph_must_be_a_positive_number": "يجب أن يكون الرقم الهيدروجيني رقمًا موجبًا",
    "you_must_enter_houses_number": "يجب عليك إدخال رقم العنبر",
    "configurations_has_been_saved": "تم حفظ الإعدادات",
    "farm_age_has_to_be_at_least_0": "يجب أن يكون عمر المزرعة 0 على الأقل",
    "mortality_has_to_be_at_most_0": "يجب أن يكون معدل الوفيات 0 على الأقل",
    "orp_must_be_a_positive_number": "يجب أن يكون ORP رقمًا موجبًا",
    "please_enter_number_of_houses": "الرجاء إدخال عدد العنابر",
    "please_select_expiration_date": "الرجاء تحديد تاريخ انتهاء الصلاحية",
    "quantity_has_to_be_at_least_1": "يجب أن تكون الكمية 1 على الأقل",
    "water_ph_has_to_be_at_least_0": "يجب أن يكون الرقم الهيدروجيني للماء 0 على الأقل",
    "email_you_entered_is_incorrect": "عنوان بريد إلكتروني خاطئ",
    "house_area_must_be_more_than_0": "يجب أن تكون مساحة العنبر أكثر من 0",
    "mortality_has_to_be_at_least_0": "يجب أن يكون معدل الوفيات علي الأقل 0",
    "number_of_broilers_is_required": "عدد الدجاج مطلوب",
    "placement_quantity_is_required": "كمية التسكين مطلوبة",
    "please_enter_your_phone_number": "يرجى إدخال رقم الهاتف الخاص بك",
    "water_orp_has_to_be_at_least_0": "يجب أن تكون قيمة orp المائية 0 على الأقل",
    "doses_must_be_a_numerical_value": "يجب أن تكون الجرعات ذات قيمة عددية",
    "house_area_must_be_more_than_0\"": "يجب أن تكون مساحة العنبر أكثر من 0",
    "other_type_category_is_required": "مطلوب فئة نوع أخرى",
    "already_running_with_chicken_app": "هل تمتلك حساب بالفعل ؟",
    "feed_intake_has_to_be_at_least_0": "يجب أن يكون تناول العلف 0 على الأقل",
    "temperature_has_to_be_at_least_0": "يجب أن تكون درجة الحرارة 0 على الأقل",
    "password_confirmation_is_required": "مطلوب تأكيد كلمة المرور",
    "water_intake_has_to_be_at_least_0": "يجب أن يكون استهلاك الماء 0 على الأقل",
    "water_orp_can_not_be_more_than_10": "لا يمكن أن يكون الماء أكثر من 10",
    "email_or_phone_number_are_required": "مطلوب البريد الإلكتروني أو رقم الهاتف",
    "enter_your_email_to_reset_password": "أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور",
    "other_type_consumption_is_required": "مطلوب استهلاك نوع آخر",
    "unaccountable_quantity_is_required": "مطلوب كمية غير قابلة للمساءلة",
    "cost_category_code_name_is_required": "مطلوب اسم كود فئة التكلفة",
    "live_weight_age_has_to_be_at_most_0": "يجب أن يكون عمر الوزن الحي 0 على الأقل",
    "number_of_broilers_must_be_positive": "يجب أن يكون عدد الدجاج موجبًا",
    "house_area_must_be_a_numerical_value": "يجب أن تكون مساحة العنبر قيمة عددية",
    "live_weight_age_has_to_be_at_least_0": "يجب أن يكون عمر الوزن الحي 0 على الأقل",
    "name_has_to_be_at_most_20_characters": "يجب ألا يزيد الاسم عن 20 حرفًا",
    "please_enter_max_outside_temperature": "الرجاء إدخال أقصى درجة حرارة خارجية",
    "please_enter_min_outside_temperature": "الرجاء إدخال دقيقة خارج درجة الحرارة",
    "the_field_should_contain_digits_only": "يجب أن يحتوي الحقل على أرقام فقط",
    "unit_has_to_be_at_most_20_characters": "يجب ألا يزيد طول الوحدة عن 20 حرفًا",
    "can_not_use_the_same_batch_once_again": "لا يمكن استخدام نفس التشغيلة مرة أخرى",
    "quantity_must_be_less_than_live_birds": "يجب أن تكون الكمية أقل من الطيور الحية",
    "house_number_must_be_an_integer_number": "يجب أن يكون رقم العنبر عددًا صحيحًا",
    "placement_quantity_must_be_more_than_0": "يجب أن تكون كمية التسكين أكثر من 0",
    "enter_new_password_and_confirm_password": "أدخل كلمة مرور جديدة وأكد كلمة المرور",
    "placement_date_field_must_be_later_than": "يجب أن يكون تاريخ التسكين بعد أو يساوي تاريخ اليوم",
    "you_have_to_select_different_feed_batch": "يجب عليك تحديد تشغيلة علف مختلفة",
    "doses_must_be_greater_than_or_equal_to_0": "يجب أن تكون الجرعات أكبر من أو تساوي 0",
    "expiration_date_field_must_be_later_than": "يجب أن يكون حقل تاريخ انتهاء الصلاحية بعد",
    "item_code_has_to_be_at_most_4_characters": "يجب أن يتكون كود العنصر من 4 أحرف على الأكثر",
    "live_birds_must_be_less_than_or_equal_to": "يجب أن تكون الطيور الحية أقل من أو تساوي",
    "max_co_2_must_be_greater_than_or_equal_0": "يجب أن يكون الحد الأقصى لنسبة co2 أكبر من أو يساوي 0",
    "min_co_2_must_be_greater_than_or_equal_0": "يجب أن يكون الحد الأدنى من ثاني أكسيد الكربون أكبر من أو يساوي 0",
    "breed_code_has_to_be_at_most_4_characters": "يجب أن يتكون كود السلالة من 4 أحرف على الأكثر",
    "farm_name_has_to_be_at_most_30_characters": "يجب ألا يزيد اسم المزرعة عن 30 حرفًا",
    "house_code_has_to_be_at_most_4_characters": "يجب أن يتكون كود العنبر من 4 أحرف على الأكثر",
    "phone_number_can_not_be_less_or_more_than": "لا يمكن أن يكون رقم الهاتف أقل أو أكثر من",
    "this_filed_has_to_be_at_most_4_characters": "يجب ألا يزيد عدد أحرف هذا الحقل عن 4 أحرف",
    "to_activate_your_account_check_your_email": "لتنشيط حسابك تحقق من بريدك الإلكتروني أو الرمز المرسل عن طريق رسالة نصية الي هاتفك",
    "breed_name_has_to_be_at_most_20_characters": "يجب ألا يزيد اسم السلالة عن 20 حرفًا",
    "house_name_has_to_be_at_most_10_characters": "يجب ألا يزيد عدد أحرف اسم العنبر عن 10 أحرف",
    "house_name_has_to_be_at_most_50_characters": "يجب ألا يزيد اسم العنبر عن 50 حرفًا",
    "number_of_houses_must_be_a_numerical_value": "يجب أن يكون عدد العنابر قيمة عددية",
    "this_filed_has_to_be_at_most_50_characters": "يجب ألا يزيد عدد أحرف هذا الحقل عن 50 حرفًا",
    "description_has_to_be_at_most_50_characters": "يجب ألا يزيد الوصف عن 50 حرفًا",
    "energy_type_has_to_be_at_most_20_characters": "يجب ألا يزيد نوع الطاقة عن 20 حرفًا",
    "placement_quantity_must_be_an_integer_value": "يجب أن تكون كمية التسكين قيمة عدد صحيح",
    "sales_name_has_to_be_at_most_100_characters": "يجب ألا يزيد اسم المبيعات عن 100 حرف",
    "average_co_2_must_be_greater_than_or_equal_0": "يجب أن يكون متوسط co2 أكبر من أو يساوي 0",
    "cost_category_has_to_be_at_most_20_characters": "يجب ألا تزيد فئة التكلفة عن 20 حرفًا",
    "selected_house_does_not_work_in_selected_date": "العنبر المحدد لا يعمل في التاريخ المحدد",
    "cycle_code_name_has_to_be_at_most_6_characters": "يجب ألا يتجاوز اسم كود الدورة 6 أحرف",
    "total_remain_live_birds_must_be_greater_than_0": "يجب أن يكون إجمالي الطيور الحية المتبقية أكبر من 0",
    "medication_unit_has_to_be_at_most_20_characters": "يجب ألا يزيد عدد أحرف وحدة العلاج عن 20 حرفًا",
    "description_unit_has_to_be_at_most_50_characters": "يجب ألا يزيد عدد أحرف وحدة الوصف عن 50 حرفًا",
    "long_description_has_to_be_at_most_50_characters": "يجب ألا يزيد الوصف الطويل عن 50 حرفًا",
    "medication_name_has_to_be_at_most_100_characters": "يجب ألا يزيد اسم العلاج عن 100 حرف",
    "we_sent_you_an_email_to_confirmed_password_reset": "لقد أرسلنا إليك بريدًا إلكترونيًا لإعادة تعيين كلمة المرور المؤكدة",
    "congrats_your_account_has_been_successfully_created": "تهانينا ، لقد تم إنشاء حسابك بنجاح",
    "you_will_receive_an_email_to_confirm_password_reset": "ستتلقى بريدًا إلكترونيًا لتأكيد إعادة تعيين كلمة المرور",
    "you_will_receive_an_email_or_a_sms_message_to_confirm_password_reset": "ستتلقى بريدًا إلكترونيًا  أو رسالة نصية لتأكيد إعادة تعيين كلمة المرور",
    "you_can_only_edit_this_delivery_in_the_creation_time": "يمكنك فقط تعديل هذا الوارد في وقت الإنشاء",
    "feed_intake_quantity_can't_be_more_than_quantity_left": "لا يمكن أن تكون كمية العلف المستهلكة أكثر من الكمية المتبقية",
    "medication_quantity_must_be_greater_than_or_equal_to_1": "يجب أن تكون كمية العلاج أكبر من أو تساوي 1",
    "rejects_types_choices_has_to_be_at_most_100_characters": "يجب ألا يزيد عدد أحرف اختيارات أنواع الاعدامات عن 100 حرف",
    "it_seems_you_can_not_consume_form_this_batch_once_again": "يتم استخدام جميع الدفعات من هذه الفئة في هذا اليوم",
    "vaccination_quantity_must_be_greater_than_or_equal_to_0": "يجب أن تكون كمية التحصين أكبر من أو تساوي 0",
    "are_you_sure_you_want_to_delete_standard_record_for_breed": "هل أنت متأكد أنك تريد حذف السجل القياسي للسلالة",
    "expiration_date_field_must_be_later_than_the_creation_date": "يجب أن يكون حقل تاريخ انتهاء الصلاحية بعد تاريخ الإنشاء",
    "are_you_sure_you_want_to_delete_direct_cost_record_for_farm": "هل أنت متأكد من أنك تريد حذف سجل التكلفة المباشرة للمزرعة",
    "expiration data must be greater than or equal to today date": "يجب أن تكون بيانات انتهاء الصلاحية أكبر من أو تساوي تاريخ اليوم",
    "are_you_sure_you_want_to_delete_indirect_cost_record_for_farm": "هل أنت متأكد أنك تريد حذف سجل التكلفة غير المباشرة للمزرعة",
    "dead_on_arrival_must_be_less_than_or_equal_placement_quantity'": "يجب أن يكون الموتى عند الوصول أقل من أو يساوي كمية التسكين",
    "increase_your_broilers_efficiency_using_chicken_app_application": "زيادة كفاءة دجاج التسمين باستخدام تطبيق ChickenApp",
    "placement_quantity_must_be_greater_than_placement_dead_on_arrival": "يجب أن تكون كمية التسكين أكبر من قيمة التسكين عند الوصول",
    "to_activate_your_account_check_your_email_and_confirm_registration": "لتنشيط حسابك ، تحقق من بريدك الإلكتروني وقم بتأكيد التسجيل",
    "consumption_must_be_less_than_or_equal_to_available_delivery_quantity": "يجب أن يكون الاستهلاك أقل من كمية الوارد المتاحة أو مساويًا لها",
    "to_reset_your_account_password_check_your_email_and_confirm_registration": "لإعادة تعيين كلمة مرور حسابك ، تحقق من بريدك الإلكتروني وقم بتأكيد التسجيل",
    "to_reset_your_account_password_check_your_email_or_mobile_and_confirm_registration": "لإعادة تعيين كلمة مرور حسابك ، تحقق من بريدك الإلكتروني  أو هاتفك وقم بتأكيد التسجيل",

    "incorrect_phone_number": "رقم الهاتف غير صحيح",
    "you_can_not_edit_standard_breed": "لا يمكنك تعديل بيانات الفصائل القياسية",
    "standard_breed": "فصيلة قياسية",
    "login_to_your_account": "تسجيل الدخول",
    "create_new_account": "تسجيل حساب جديد",
    "clear": "مسح",
    "verify_otp": "التحقق من الكود",
    "resend_link": "أعد أرسال كود التحقيق",
    "if_you_did_not_get_a_code_please_click_resend_and_try_again_after": "إذا لم تحصل على الكود ، فالرجاء النقر علي إعادة الإرسال والمحاولة مرة أخرى بعد ذلك",
    "seconds": "ثواني",
    "to_activate_you_account_enter_the_4_digit_code_sent_to": "لتفعيل حسابك أدخل الكود المكون من 4 أرقام المرسل إلى",
    "enter_4_digit_code": "أدخل الكود المكون من 4 أرقام",
    "did_not_get_the_code_yet?": "لم تحصل على الكود بعد؟",
    "you_can_only_consume_from_same_batch_one_time_in_same_day": "يمكنك أن تستهلك من نفس التشغيلة مرة واحدة فقط في نفس اليوم",
    "no_items_available": "لا توجد بيانات متاحة",
    "no_filtering_results_available": "لا توجد نتائج تصفية متاحة",
    "quantity_must_be_an_integer_value": "يجب أن تكون الكمية قيمة عددية صحيحة",
    "this_field_must_be_a_numeric_value": "يجب أن يكون هذا الحقل قيمة عددية صحيحية",
    "you_can_not_exceed_available_delivery_quantity": "لا يمكنك تجاوز كمية الوارد المتاحة",
    "live_birds_must_be_an_integer_value": "يحب ان يكون عدد الطيور الحية قيمة عددية صحيحة",
    "vaccination_quantity_must_be_greater_than_0":"يجب أن تكون كمية التحصين أكبر من 0",
    "you_are_viewing_a_predefined_breed_with_predefined_standards":"أنت تعرض سلالة محددة مسبقًا بمعايير ثابتة",
    "predefined_breeds_and_standards_are_system_specified_and_could_not_be_updated_or_deleted":"السلالات والمعايير المحددة مسبقًا تم وضعهاتلقائياً من قبل النظام ولا يمكن تحديثها أو حذفها",
    "placement_date_must_be_at_earlier_than_or_equal_today_date":"يجب ان يكون تاريخ التسكين قبل أو يساوي تاريخ اليوم",
    "number_of_houses_hint":"(مثال: عنبر-1, عنبر-2, عنبر-3, ...)",
    "can_not_select_date_in_the_future":"لا يمكن أختيار تاريخ في المستقبل",
    "house_name_will_be_common_for_all_create_number_of_houses":"سيكون اسم العنبر مكرراً بالأضافة الي رقم العنبر",
    "to_achieve_better_accessibility_you_can_use_the_wizard_to_quickly_create_farm_house_and_placement":"يمكنك أستخدام الشاشة لإنشاء مزرعة و عنبر و إضافة تسكين بشكل أسرع",
    "on_the_other_hand_you_can_use_the_farm_management_section_to_create_and_maintain_them_separately":"يمكنك استخدام إدارة المزرعة لإنشائها بشكل منفصل",
    "placement_qty_example":"(ستكون العدد متساوي  لكل عنبر من العنابر, مثال: 10,000 لكل عنبر و يمكنك تعديلها لاحقاً من إدارة المزارع)",
    "max_digit":"هذا الحقل يجب ان يحتوي علي قيمة أقل من تساوي 1000000",
    "max_house":"عدد العنابر يجب ان يكون أقل من أو يساوي 100",
    "phone_number_should_contain_digits_only":"يجب أن يحتوي رقم الهاتف على أرقام فقط",
    "search_and_filter":"بحث و تصفية",
    "farm_age_chart":"أعمار المزارع",
    "water_ph_can_not_be_more_than_10":"لا يمكن أن تكون درجة الحموضة في الماء أكثر من 10",
    "cost_max":"يجب أن تكون التكلفة أقل من أو تساوي 1،000،000",
    "delivery_qty_max":"يجب أن تكون الكمية أقل من أو تساوي",
    "delivery_energy_qty_max" : "يجب أن تكون الكمية أقل من أو تساوي " ,
    "delivery_feed_qty_max":"يجب أن تكون الكمية أقل من أو تساوي ",
    "shortage_max_qty":"يجب أن تكون كمية النقصان أكبر من أو تساوي -1000",
    "DOA_MAX":"يجب أن تكون كمية النافق عند الوصول أقل من أو تساوي 1,000",
    "QTY_MAX":"يجب أن تكون الكمية أقل من أو تساوي",
    "DOSES_MAX":"يجب أن يكون عدد الجرعات أقل من أو يساوي",
    "SIZE_AREA_MAX":"يجب أن تكون المساحة أقل من أو تساوي",
    "MAX_STD_AGE":"يجب أن يكون العمر أقل من أو يساوي",
    "MAX_STD_LIVE_WEIGHT":"يجب أن يكون الوزن أقل من أو يساوي",
    "MAX_STD_MORTALITY":"يجب ان تكون نسبة النافق أقل من أو يساوي",
    "MAX_STD_FEED_INTAKE":"يجب أن يكون استهلاك العلف أقل من أو يساوي",
    "MAX_STD_WATER_PH":"يجب أن يكون الأس الهيدروجيني أقل من أو يساوي",
    "MAX_STD_WATER_INTAKE":"يجب أن يكون استهلاك الماء أقل من أو يساوي",
    "MAX_STD_ORP":"يجب أن يكون أو ار بي أقل من أو يساوي",
    "MAX_STD_HUMIDITY":"يكون ان الرطوبة تكون أقل من أو يساوي",
    "MAX_STD_FCR":"يجب أن يكون معامل التحويل الغذائي أقل من أو يساوي",
    "MAX_STD_TEMP":"يجب أن تكون الحرارة أقل من أو يساوي",
    "DOA_MIN":"يجب أن تكون كمية النافق عند الوصول أقل من أو تساوي",
    "are_you_sure_you_want_to_logout_?":"هل أنت متأكد من تسجيل الخروج",
    "DATE_MAX_TODAY":"يجب أن يكون التاريخ قبل أو يساوي تاريخ اليوم",
    "placement_quantity_must_be_greater_than_or_equal_to_0":"يجب أن تكون كمية النافق عند التسكين أكبر من أو تساوي 0",
    "your_recorded_daily_data_will_be_lost":"سوف تفقد البينات اليومية التي تم أدخالها",
    "DAILY_DATA_LOST_WARNING":"تأكد من حفظ التغيرات قبل التبديل إلى قسم الواردات ، و إلا ستفقد بياناتك الغير المحفوظة",
    "switch_to_deliveries":"الذهاب لقسم الواردات",
    "back":"رجوع",
    "it_seems_you_do_not_have_any_vaccination":"يبدو أنه ليس لديك أي نوع من أنواع التحصينات حتي الآن",
    "add_vaccinations":"أضف تحصين",
    "it_seems_you_do_not_have_any_medication":"يبدو أنه ليس لديك أي نوع من أنواع العلاجات حتي الآن",
    "add_medication":"أضف علاج",
    "add_delivery":"أضف وارد",
    "it_seems_you_do_not_have_any_other_type":"يبدو أنه ليس لديك أي نوع من الأنواع الأخري حتي الآن",
    //--------------------
    "MAX_SIZE_AREA_MAX": "يجب أن تكون مساحة العنبر أقل من أو تساوي",
    "form_here_you_can_enter_farm_name":"يمكنك من هنا أدخال أسم المزرعة",
    "and_here_you_can_enter_farm_location_or_governorate":"وهنا يمكنك إدخال موقع المزرعة أو المحافظة",
    "settings":"إعدادات النظام",
    "system_system":"إعدادات النظام",
    "choose_preferred_system_language":"اختر لغة النظام المفضلة",
    "QTY_MIN":"يجب أن تكون الكمية أكبر من أو تساوي",
    "shortage_must_be_greater_than_or_equal_to":"يجب أن تكون كمية النقصان أكبر من أو تساوي",
    "extra_must_be_less_than_or_equal_to":"يجب أن تكون الكمية الزائدة أقل من أو تساوي",
    "it_seems_you_do_not_configured_daily_data_sections":"يبدو أنك لم تقم بأظهار أي قسم من خلال الأعدادات",
    "you_can_change_configuration_and_show_more_sections":"يمكن تغير الإعدادات و أظهار الأقسام",
    "configure_sections":"إعداد الاقسام",
    "save_language":"حفظ اللغة",
    "language_settings":"إعدادات اللغة",
    "date_is_required":"الترايخ مطلوب",
    "cycle_is_required":"يجب تحديد الدورة",
    "assumed_weight_is_required":"الوزن مطلوب",
    "reports":"التقارير",
    "financial_report":"التقرير المالي",
    "farm_report":"التقرير اليومى للمزارع",
    "generate_report":"إنشاء تقرير",
    "assumed_weight":"الوزن المفترض",
    "assumed_weight_must_be_grater_than_0":"يجب أن يكون الوزن المفترض أكبر من 0",
    "please_enter_assumed_weight":"الرجاء تحديد الوزن المفترض",
    "date_must_be_earlier_than":"يجب أن يكون التاريخ قبل تاريخ",
    "date_must_be_late_than":"يجب أن يكون التاريخ بعد تاريخ",
    "CSV_DOWNLOAD":"تحميل بصيغة CSV",
    "PDF_DOWNLOAD":"تحميل بصيغة PDF",
    "you_have_to_select_farm_and_cycle_first":"يجب عليك تحديد مرزعة و دورة أولاً",
    "credits":"لديك 100 نقطة مجانية (صالحة لـ 10 تسكينات)",
    "profile":"الملف الشخصي",
    "please_select_vaccination": "الرجاء إختيار التحصين",
    "please_select_medication": "الرجاء إختيار العلاج",
    "please_select_feed": "الرجاء إختيار العلف",
    "category_name_is_required": "مطلوب اسم الفئة",
    "SIDEBAR_TOUR_EXPLAIN": "يمكنك من خلال هذه القائمة التنقل بين أقسام النظام المختلفة",
    "DASHBOARD_SIDEBAR_EXPLAIN": "من هنا يمكنك عرض الشاشة الرئيسية و تصفح التحليلات  و الإحصائيات الخاصة بأداء جميع المزارع",
    "FARM_MANAGEMENT_EXPLAIN": "القسم الخاص بإدارة المزارع",
    "ADMINISTRATION_EXPLAIN": "القسم الخاص بالإدارة",
    "COSTS_EXPLAIN": "القسم الخاص بالماليات",
    "WIZARD_EXPLAIN": "من خلال الوصول السريع يمكنك إنشاء مزرعة و عنابر للمزرعة و إضافة تسكين بشكل أسرع",
    "SETTINGS_EXPLAIN": "من هنا يمكنك التحكم في أعدادات النظام مثل اللغة و أظهار/إخفاء أقسام البيانات اليومية",
    "CONFIG_FARM_DELIVERY_EXPLAIN": "يمكنك التحكم في تمكين أو تعطيل الواردات من هنا , عند تعطيل الواردات يمكنك الاستهلاك من أنوع العلاجات او التحصينات او العلف مباشرة دون  الحاجة لتحديد رقم تشغيلة معينة",
    "CONFIG_FINANCIAL_EXPLAIN": "يمكنك التحكم في أظهار قسم الماليات من هنا",
    "CONFIG_DAILY_DATA_SECTION_EXPLAIN": "يمكنك من هنا التحكم في إظهار كل قسم من أقسام البيانات اليومية حسب الحاجة",
    "FARM_CARD_EXPLAIN": "هنا يتم عرض جميع المزارع بالإضافة الي عمر المزرعة و نسبة خسارة الطيور في المزرعة وما اذا كانت هي أفضل مزرعة أو أسوء مزرعة",
    "FARM_SUMMARIES_EXPLAIN": "من هنا يتم عرض ملخصات و أحصائيات المزارع",
    "CHART_EXPLAIN": "من هنا يتم عرض الرسومات البيانية الخاصة بأداء جميع المزارع مقارنة بالمعاير القاسية للسلالات المسكنة",
    "DASHBOARD_CALENDAR_EXPLAIN": "تقويم التطبيق حيث يمكنك تغير التاريخ ليكون التاريخ في الماضي لإظهار الواردات ومعلومات البيانات اليومية في هذا التاريخ",
    "WIZARD_PLACEMENT_DATE_EXPLAIN": "هنا يمكنك تحديد تاريخ التسكين للمزرعة",
    "WIZARD_PLACEMENT_QUANTITY_EXPLAIN": "قم بتحديد كمية التسكين من هنا, و ستكون الكمية متساوية لكل عدد من العنابر الموجودة في المزرعة",
    "WIZARD_PLACEMENT_DOA_EXPLAIN": "يمكنك أيضاً تحديد كمية النافق من التسكين",
    "WIZARD_PLACEMENT_BREED_EXPLAIN": "يمكنك تحديد سلالة التسكين من هنا, بعض السلالات تم اضافتها من قبل النظام بطريقة إفتراضية نظراً لكونها سلالة معروفة بمعاير قياسية",
    "WIZARD_HOUSE_EXPLAIN": "أدخل مساحة العنبر هنا. ستكون مساحة العنبر متساوية لجميع العنابر. في حالة رغبتك في تغيير مساحة عنبر معين يمكنك تغييرها من إدارة المزرعة",
    "WIZARD_HOUSE_NAME_EXPLAIN": "من هنا يمكنك إدخال اسم العنبر",
    "WIZARD_HOUSE_NUMBER_EXPLAIN": "يمكنك إدخال عدد عنابر المزرعة من هنا",
    "WIZARD_PLACEMENT_DATE": "من هنا يمكنك تحديد تاريخ التسكين",
    "WIZARD_PLACEMENT_QUANTITY": "يمكنك إدخال كمية التسكين من هنا, وسوف تكون متساوية لكل العنابر .في حالة رغبتك في تغيير كمية تسكين عنبر معين فيمكنك تغييرها من إدارة المزرعة",
    "WIZARD_PLACEMENT_DEAD_ON_ARRIVAL_QUANTITY": "يمكن إدخال كمية النافق عند التسكين من هنا وسوف تكون متساوية لكل العنابر .في حالة رغبتك في تغيير نافق تسكين عنبر معين فيمكنك تغييرها من إدارة المزرعة",
    "WIZARD_PLACEMENT_BREED": "يمكنك أختيار سلالة التسكين من هنا, يمكن أختيار أحد السلالات القياسية التي تم تعريفها مسبقاً",
    "farm_management_section": "قسم إدارة المزارع",
    "administration_section": "قسم الإدارة",
    "financial_section": "قسم الماليات",
    "wizard_section": "الوصول السريع",
    "settings_section": "قسم الإعدادات",
    "main_dashboard": "لوحة العرض الرئيسية",
    "skip": "تخطي",
    "loading": "تحميل",
    "remain_quantity": "الكمية المتبقية",
    "your_message": "أدخل رسالتك",
    "DELIVERY_FEED_SECTION_EXPLAIN":"من هنا يمكنك إضافة واردات العلف عن طريق تحديد نوع العلف ,قم التشغيلة والكمية ، كما يمكنك تحديد عنبر معين من المفترض أن ترتبط به الواردات",
    //"DELIVERY_ENERGY_SECTION_EXPLAIN":"<<<__>>>",
    "DELIVERY_OTHER_TYPE_SECTION_EXPLAIN":"من هنا يمكنك إضافة أي واردات أخري عن طريق تحديد نوع الوارد ,رقم التشغيلة والكمية ، كما يمكنك تحديد عنبر معين من المفترض أن ترتبط به الواردات",
    "DELIVERY_VACCINATION_SECTION_EXPLAIN":"من هنا يمكنك إضافة واردات التحصينات عن طريق تحديد نوع التحصين ,رقم التشغيلة والكمية ، كما يمكنك تحديد عنبر معين من المفترض أن ترتبط به الواردات",
    "DELIVERY_MEDICATION_SECTION_EXPLAIN":"من هنا يمكنك إضافة واردات العلف عن طريق تحديد نوع العلف ,رقم التشغيلة والكمية ، كما يمكنك تحديد عنبر معين من المفترض أن ترتبط به الواردات",
    "DAILY_DATE_TEMPERATURE_TITLE_EXPLAIN": "يمكنك إدخال درجة الحرارة اليومية الخاصة بالعنبر من هنا",
    "DAILY_DATE_TEMPERATURE_SECTION_EXPLAIN": "تتمثل دارجة الحرارة اليومية الخاصة بالعنبر في درجة الحرارة الصغري و الكبري داخل و خارج العنبر.",
    "DAILY_DATE_HUMIDITY_SECTION_EXPLAIN": "- تتضمن معلومات الرطوبة نسبة الرطوبة الصباحية ونسبة الرطوبة في منتصف النهار ونسبة الرطوبة المسائية داخل العنبر.",
    "DAILY_DATE_CO2_SECTION_EXPLAIN": "- تتضمن معلومات ثاني أكسيد الكربون الحد الأدني و الاقصي ومتوسط نسبة ثاني أكسيد الكربون",
    "DAILY_DATE_FEED_INTAKE_SECTION_EXPLAIN": "- يمكنك إضافة نوعين مختلفين من أنواع العلف لليوم الواحد حسب التشغيلات أو أنواع العلف المستخدمة. مثال: إذا كنت تقوم بالتبديل إلى نوع علف آخر أو  تشغيلة أخرى بعد الانتهاء من النوع السابق",
    "DAILY_DATE_WATER_INTAKE_SECTION_EXPLAIN": "يمكنك أيضًا إضافة استهلاك المياه ، ومستوى الحموضة (PH) ، وإمكانية تقليل الأكسدة (ORP)",
    "DAILY_DATE_WEIGHT_SECTION_EXPLAIN": "هنا يمكنك إضافة متوسط وزن الدجاج اليومي, (يضاف الوزن بالكيلوجرام)",
    "DAILY_DATE_OTHER_TYPES_TITLE_EXPLAIN": "هنا ، يمكنك إضافة الأنواع الأخرى من استهلاك الموارد التي تريد تتبعها داخل عنبرك",
    "DAILY_DATE_OTHER_TYPES_SECTION_EXPLAIN": "- يمكنك إضافة استهلاك الموارد المختلفة في نفس اليوم (عدة مرات). - يمكن استخدام نفس الدفعة مرة واحدة فقط في نفس اليوم. - يمكنك تغيير قيمة الاستهلاك لنفس الدفعة حسب رغبتك لاستخدامها مرة أخرى في نفس اليوم لنفس العنبر",
    "DAILY_DATE_MEDICATION_SECTION_EXPLAIN": "يمكنك اختيار اسم علاج (إذا لم يكن متوفرًا ،أضف اسمًا جديدًا عنبر طريق الإدارة) - اختر دفعة لهذا العلاج ثم اكتب الاستهلاك. - إذا كان العلاج سيستخدم لجميع العنابر فلا داعي لإضافة عدد الطيور المعرضة للعلاج. - بعد ذلك يمكنك اختيار طريقة تطبيق العلاج المستخدمة داخل العنبر",
    "DAILY_DATA_MEDICATION_NAME_EXPLAIN": "يمكنك اختيار اسم علاج (إذا لم يكن متوفرًا ،أضف اسمًا جديدًا عنبر طريق الإدارة)",
    "DAILY_DATA_MEDICATION_BATCH_EXPLAIN": "أختر دفعة لهذا العلاج",
    "DAILY_DATA_MEDICATION_QUANTITY_EXPLAIN": "ثم اكتب الاستهلاك هنا",
    "DAILY_DATA_MEDICATION_LIVE_BIRDS_EXPLAIN": " من هنا يمكن أدخال عدد الطيور المعرضة للعلاج اذا كان العلاج سيستخدم لجميع العنابر فلا داعي لإضافة عدد الطيور المعرضة للعلاج",
    "DAILY_DATA_MEDICATION_APPLICATION_ROUTE_EXPLAIN": "بعد ذلك يمكنك اختيار طريقة تطبيق العلاج المستخدمة داخل العنبر",
    "DAILY_DATA_VACCINATION_NAME_EXPLAIN": "يمكنك اختيار اسم التحصين (إذا لم يكن متوفرًا ،أضف اسمًا جديدًا عنبر طريق الإدارة)",
    "DAILY_DATA_VACCINATION_BATCH_EXPLAIN": "أختر دفعة لهذا التحصين",
    "DAILY_DATA_VACCINATION_QUANTITY_EXPLAIN": "ثم اكتب الاستهلاك التحصين هنا",
    "DAILY_DATA_VACCINATION_LIVE_BIRDS_EXPLAIN": " من هنا يمكن أدخال عدد الطيور المعرضة للتحصين اذا كان التحصين سيستخدم لجميع العنابر فلا داعي لإضافة عدد الطيور المعرضة للتحصين",
    "DAILY_DATA_VACCINATION_APPLICATION_ROUTE_EXPLAIN": "بعد ذلك يمكنك اختيار طريقة تطبيق التحصين المستخدمة داخل العنبر",
    "DAILY_DATE_VACCINATION_SECTION_EXPLAIN": "- يمكنك اختيار اسم التحصين (إذا لم يكن متوفرًا ،أضف اسمًا جديدًا عنبر طريق الإدارة) - اختر دفعة لهذا التحصين ثم اكتب الاستهلاك. - إذا كان التحصين سيستخدم لجميع العنابر فلا داعي لإضافة عدد الطيور المعرضة للتحصين - بعد ذلك يمكنك اختيار طريقة تطبيق التحصين المستخدمة داخل العنبر",
    "DAILY_DATE_MORTALITY_SECTION_EXPLAIN": "- يمكنك إضافة  كمية الدجاج النافق ووزنها (إن وجد)",
    "DAILY_DATE_CULLS_SECTION_EXPLAIN": "- يمكنك إضافة كمية الدجاج المعدم اليوم ووزنها (إن وجد). - يمكنك أيضًا اختيار نوع عمليات الإعدام. إذا لم يكن لديك أي نوع ، يمكنك إضافة الأنواع الخاصة بك من الإدارة",
    "DAILY_DATE_SALES_TITLE_EXPLAIN": "هنا، يمكنك إضافة مبيعات اليوم",
    "DAILY_DATE_SALES_SECTION_EXPLAIN": "- يمكنك كتابة عدد الدجاج المباع والسعر/ كجم واجمالي الوزن المباع ونوع المبيعات (يمكنك إضافة نوع المبيعات الخاص بك من الإدارة)  - يمكنك إضافة المبيعات عدة مرات في اليوم حسب عدد المرات / الأنواع / الأسعار التي قمت ببيع الدجاج بها",
    "DAILY_DATE_UNACCOUNTABLE_SECTION_EXPLAIN": "- الدواجن التي لا تخضع للمساءلة هي التي تباع بدون مقابل مادي سواء للأعمال الخيرية أو المطبخ أو أي مبرر آخر تحتاجه. - سيتم خصم عدد الدجاج المباع من الطيور الحية في ذلك اليوم",
    "DAILY_DATE_COMMENTS_SECTION_EXPLAIN": "- يمكنك إضافة تعليقات متعددة في نفس اليوم. سيتم حفظ هذه التعليقات مع الدورة الحالية والعنبر الذي تضيف التعليق فيه",
    'age_in_days': "العمر بالأيام",
    'analysis': "تحليلات",
    "feed_deliveries": "واردات العلف",
    "other_types_deliveries": "واردات الأنواع الأخري",
    "vaccination_deliveries": "واردات التحصين",
    "medication_deliveries": "واردات العلاج",
    "dashboard_empty_add_farm": "يمكنك البدء في إضافة مزارع و تسكينات لأظهار شاشة العرض",
    "add_farm_and_placement": "إضافة مزارع و تسكينات",
    "under_the_neck": "أسفل الرقبة",
    "drinking_water": "مياه الشرب",
    "occular": "عن طريق العين",
    "intramuscular": "حقن عضلي",
    "subcutaneous": "تحت الجلد",
    "spray": "رش",
    "breast_injection": "حقن عضلة الصدر",
    "wing_injection": "حقن جناح" ,
    "terms_consent":"إنشاء حساب يعني أنك توافق على ",
    "terms_of_services_and_our_privacy_policy":" الشروط والأحكام و سياسة الخصوصية",
    "Term1":"محتوى صفحات هذا الموقع لمعلوماتك العامة و الاستخدام فقط . و هو معرض لأي تغيير دون إشعار.",
    "Term2":"هذا الموقع يستخدم تحليلات جوجل لمتابعة تفاصيل التصفح. إذا سمحت باستخدام هذا، سيقوم الموقع بتخزين بعض البيانات الشخصية.",
    "Term3":"نحن نستخدم cookies لجمع إحصاءات الزوار مجهولة المصدر عن طريق تحليلات جوجل، لعرض إعلانات شخصية عن طريق Google AdSense, لحفظ الإعدادات وللكشف إذا كانت هذه هي المرة الأولى التي تقوم بزيارة الموقع .لمزيد من التفاصيل، يرجى الإطلاع على القسم الثالث من سياسات الخصوصية : Google Analytics, Google AdSense.",
    "Term4":"نحن نقوم بعرض اعلانات و قد نضع رابط عشوائي في نهاية المستندات الخالية.",
    "Term5":"لا نقدم نحن ولا أي طرف ثالث أي ضمان فيما يتعلق بدقة أو حسن توقيت أو أداء أو اكتمال أو ملاءمة المعلومات والمواد الموجودة أو المعروضة على هذا الموقع لأي غرض معين. أنت تقر بأن هذه المعلومات والمواد قد تحتوي على معلومات غير دقيقة أو أخطاء ، ونحن غير مسؤولون عن أي عدم دقة أو أخطاء من هذا القبيل إلى أقصى حد يسمح به القانون.",
    "Term6":"إن استخدامك لأية معلومات أو مواد على هذا الموقع يكون على مسؤوليتك الخاصة بالكامل ، ولن نتحمل المسؤولية تجاهه. تقع على عاتقك مسؤولية التأكد من أن أي منتجات أو خدمات أو معلومات متوفرة من خلال هذا الموقع تلبي متطلباتك المحددة.",
    "Term7":"يحتوي هذا الموقع على مواد مملوكة لنا أو مرخصة لنا. تتضمن هذه المواد ، على سبيل المثال لا الحصر ، نص التنظيف والتصميم والتخطيط والشكل والمظهر والرسومات. يحظر الاستنساخ بخلاف ما يتوافق مع إشعار حقوق النشر ، والذي يشكل جزءًا من هذه الشروط والأحكام.",
    "Term8":"جميع العلامات التجارية المستنسخة في هذا الموقع والتي ليست مملوكة أو مرخصة للمشغل معترف بها على هذا الموقع.",
    "Term9":"الاستخدام غير المصرح به لهذا الموقع قد يؤدي إلى مطالبة بالتعويض عن الأضرار و / أو يكون جريمة جنائية.",
    "Term10":"من وقت لآخر ، قد يتضمن هذا الموقع أيضًا روابط لمواقع أخرى. يتم توفير هذه الروابط لراحتك لتقديم مزيد من المعلومات. إنها لا تعني أننا نصادق على الموقع (المواقع). لا نتحمل أي مسؤولية عن محتوى الموقع (المواقع) المرتبطة.",
    "terms_and_conditions_privacy_policy":"الشروط والأحكام و سياسة الخصوصية",
    "you_must_accept_the_terms_and_conditions":"يجب عليك قبول الأحكام والشروط",
    "invalid_phone_number_format":"تنسيق رقم الهاتف غير صالح",
    "number":"رقم",
    "terms_intro":"مرحبا بكم في موقعنا على الإنترنت. إذا كنت مستمر بالتصفح واستخدام هذا الموقع، فإنك توافق على الامتثال والالتزام ببنود وشروط الاستخدام التالية، و التي تحكم بجانب سياسة الخصوصية الخاصة بنا، علاقة اتش تي ام ال اولاين معك فيما يتعلق بهذا الموقع. إذا كنت لا توافق على أيٍ من هذه الشروط الرجاء عدم استخدام هذا الموقع.",
    "term_sub":"هذا الموقع لا يجمع أي بيانات شخصية ولكن نستخدم أدوات الطرف الثالث التي يمكن أن تفعل ذلك. يرجى مراجعة سياسات الخصوصية الخاصة بها للحصول على مزيد من العلوماتGoogla Analytics, Google AdSense, Google Mail, Facebook, Godaddy hosting",
    "quantity_must_be_greater_than_0":"يجب أن تكون الكمية أكبر من 0",
    "DAILY_DATE_ENERGY_SECTION_EXPLAIN": "+++",
    "DELIVERY_ENERGY_SECTION_EXPLAIN": "+++",
    "energy_deliveries":"واردات الطاقة",
    "phone_must_be_at_most_15_digits":"يجب أن يكون رقم الهاتف أقل من 15 رقم",
    "user_name_must_be_at_most_150_characters":"يجب أن يكون أسم المستخدم أقل من 150 حرف",
    "name_must_be_at_most_150_characters":"يجب أن يكون الأسم أقل من 150 حرف",
    "selling_date_must_be_later_than_today_date":"يجب أن يكون تاريخ البيع قبل تاريخ اليوم",
    "MAX_LITTER_SALES":"يجب أن تكون مبيعات السبلة أقل من",
    "assumed_weight_must_be_less_than_or_equal_to_5":"يجب أن يكون الوزن المفترض أقل من 5 كيلو جرامات ",
    "quantity_must_be_less_than_or_equal_live_birds":"يجب أن تكون الكمية أقل من أو تساوي كمية الطيور الحية",
    "liter":"لتر",
    "DANGER_ZONE_TITLE":"تحذير",
    "DANGER_ZONE_DETAILS":"من خلال التغيير في هذا القسم ، ستقوم بتغيير بيانات تسجيل الدخول.",
    "active_user_status":"نشط",
    "ph_max":"يجب أن تكون قيمة PH أقل من 14",
    "Free trial":"تجربة مجانية",
    "You are now using the free trial version, you can use the application for 30 days for free!": "أنت الآن تستخدم الإصدار التجريبي المجاني ، يمكنك استخدام التطبيق لمدة 30 يومًا مجانًا!",
    "Continue": "أستمرار",
    "Try with demo data": "جرب مع البيانات التجريبية",
    "or": "أو",
    "total_cost" : "التكلفة الكلية",
}