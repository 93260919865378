/**
 * Reducer for user
 */
import Types from "../../actions/Types";
let initialState={
    id:'',
    fk_user:{
        id:'',
        first_name:'',
        last_name:'',
        email:'',
        phone:'',
        is_active:''
    }
}
export const users_list = (state = {users:[], isLoaded:false} , action ) => {
    switch(action.type){
        case Types.GET_ALL_USERS:
            return {...state,users: action.payload, isLoaded: true} ;
        default :
            return state ;
    }
}
export const user_data = (state = initialState , action ) => {
    switch(action.type){
        case Types._GET_USER_DATA :
            return {...state ,...action.payload}
        default :
            return state ;
    }
}
export const get_user_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_USER_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}
export const get_user_data_error = (state = { visiable : false , errors : [] }  , action ) => {
    switch(action.type){
        case Types.IS_GET_USER_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}

export const toggleUserState=(state= {userState:'active'}, action)=>{
    switch (action.type){
        case Types.TOGGLE_USER_STATE:
            return {...state, userState: 'inactive'};
        default :
            return state ;
    }

}