export const english={
    "company" : "Company" ,
    "New version is available" : "New version is available" ,
    "A new version is available, Install Now to get the new version" : "A new version is available, Install Now to get the new version" ,
    "you_don't_have_any_tips" : "You don't have tips for selected house in current date" ,
    "Thank you for getting in touch! , One of our colleagues will get back in touch with you soon!Have a great day" : "Thank you for getting in touch! , One of our colleagues will get back in touch with you soon!Have a great day" ,
    "you_must_select_energy_type" : "You must select energy type" ,
    "you_must_enter_energy_consumption" : "You must enter energy consumption" ,
    "send" : "Send" ,
    "enter_your_text_here" : "Enter your text here..." ,
    "can't_download_report_right_now_try_again_later" : "can't download report right now , you can try again later." ,
    "chick_price_must_be_greater_than_or_equal_to_0" : "Chick Price must be greater than or equal to 0" ,
    "show_tips" : "Show Tips" ,
    "chick_price" : "Chick Price" ,
    "extra" : "Extra" ,
    "DASHBOARD_CALENDAR_EXPLAIN": "App calendar where you can set the date to be date in past to show deliveries and daily data information at this date",
    "please_note_that_you_are_in_a_past_date" : "Please note that you are in a past date" ,
    "you_can_change_the_date_from_calendar_in_the_header_to_go_to_today_date" : "You can change the date from calendar in the header to go to today date" ,
    "There was a problem with your request" : "There was a problem with your request" ,
    "There was a problem with your request , Don't worry, your data is safe , you can send us an email from here" : "There was a problem with your request , Don't worry, your data is safe , You can send us an email from here" ,
    "search" : "Search" ,
    "confirm" : "confirm" ,
    "email_/_phonenumber" : "Email / Phone Number" ,
    "enter_your_email_or_phonenumber_to_reset_password" : "Enter your email or phone number to reset password" ,
    "please_enter_your_email_or_phonenumber" : "Please enter your email or phone number",
    "email_or_phone_number_you_entered_is_incorrect" : "Email or phone number you entered is incorrect" ,
    change_language: 'Change Language',
    'are_you_sure_you_want_to_change_app_language_?': 'Are you sure you want to change application language ?',
    abu_erdan: 'AbuErdan',
    digits: 'Digits',
    edit_farm: 'Edit farm',
    no_available_data: 'No available data',
    "add_energy_delivery" : "Add Energy Delivery" ,
    add_feed_delivery: 'Add Feed Delivery',
    "it_seems_you_do_not_have_any_active_vaccinations" : "It seems you do not have any active vaccinations" ,
    "it_seems_you_do_not_have_any_active_medications" : "It seems you do not have any active medications" ,
    "it_seems_you_do_not_have_any_energy_types" : "It seems you do not have any energy types" ,
    "it_seems_you_do_not_have_any_feed_types" : "It seems you do not have any feed types" ,
    "it_seems_you_do_not_have_any_energy_deliveries" : 'It seems you do not have any energy deliveries',
    it_seems_you_do_not_have_any_feed_deliveries: 'It seems you do not have any feed deliveries',
    "you_can't_access_this_page": "You can't access this page",
    kg: 'Kg',
    ph: 'Ph',
    Age: 'Age',
    Co2: 'CO2',
    add: 'Add',
    ORP: 'ORP',
    ton: 'Ton',
    code: 'Code',
    cost: 'Cost',
    date: 'Date',
    farm: 'Farm',
    feed: 'Feed',
    name: 'Name',
    save: 'Save',
    time: 'Time',
    type: 'Type',
    unit: 'Unit',
    breed: 'Breed',
    canel: 'Cancel',
    culls: 'Culls',
    cycle: 'Cycle',
    doses: 'Doses',
    email: 'Email',
    farms: 'Farms',
    photo: 'Photo',
    sales: 'Sales',
    users: 'Users',
    Active: 'Active',
    active: 'Active',
    breeds: 'Breeds',
    cancel: 'Cancel',
    cycles: 'Cycles',
    delete: 'Delete',
    filter: 'Filter',
    houses: 'Houses',
    intake: 'Intake',
    'log-in': 'Log in',
    log_in: 'Log in',
    status: 'Status',
    synced: 'Synced',
    update: 'Update',
    weight: 'Weight',
    comment: 'Comment',
    Inactive: 'Inactive',
    add_user: 'Add User',
    category: 'Category',
    comments: 'Comments',
    hatchery: 'Hatchery',
    humidity: 'Humidity',
    max_temp: 'Max Temp',
    min_temp: 'Min Temp',
    password: 'Password',
    quantity: 'Quantity',
    shortage: 'Shortage',
    add_breed: 'Add Breed',
    add_cycle: 'Add Cycle',
    add_house: 'Add New House',
    all_farms: 'All Farms',
    dashbaord: 'Dashboard',
    edit_user: 'Edit user',
    feed_name: 'Feed Type Name',
    item_code: 'Item code',
    last_name: 'Last Name',
    full_name: 'Full Name',
    mortality: 'Mortality',
    size_area: 'Size Area',
    standards: 'Standards',
    type_name: 'Type name',
    breed_code: 'Breed code',
    breed_name: 'Breed name',
    categories: 'Categories',
    cycle_code: 'Cycle Code',
    cycle_list: 'Cycles',
    deliveries: 'Deliveries',
    edit_breed: 'Edit breed',
    first_name: 'First Name',
    house_code: 'House Code',
    house_name: 'House Name',
    live_birds: 'Live Birds',
    medication: 'Medication',
    other_type: 'Other type',
    placements: 'Placements',
    'powered by': 'Powered by',
    powered_by: 'Powered by',
    description: 'Description',
    direct_cost: 'Direct Cost',
    farm_filter: 'Farm Filter',
    feed_intake: 'Feed Intake',
    item_status: 'Item Status',
    new_closure: 'New closure',
    select_farm: 'Select farm',
    temperature: 'Temperature',
    vaccination: 'Vaccination',
    add_new_user: 'Add New User',
    add_standard: 'Add Standard',
    batch_number: 'Batch number',
    edit_closure: 'Edit Closure',
    farm_closure: 'Farm closure',
    inside_house: 'Inside House',
    litter_sales: 'Litter Sales',
    selling_date: 'Selling Date',
    vaccine_name: 'Vaccine name',
    water_intake: 'Water Intake',
    add_new_breed: 'Add New Breed',
    add_new_cycle: 'Add New Cycle',
    add_new_house: 'Add New House',
    add_placement: 'Add Placement',
    add_your_farm: 'Add Your Farm',
    cost_category: 'Cost Category',
    energy_source: 'Energy Source',
    indirect_cost: 'Indirect cost',
    'medicine name': 'Medicine name',
    medicine_name: 'Medicine name',
    outside_house: 'Outside House',
    unaccountable: 'Unaccountable',
    Items_per_page: 'Rows in Table',
    indirect_costs: 'Indirect costs',
    placement_date: 'Placement Date',
    placement_farm: 'Placement farm',
    unaccountables: 'Unaccountable',
    you_are_online: 'You are online',
    add_new_closure: 'Add New Closure',
    cost_categories: 'Cost categories',
    dead_on_arrival: 'Dead on arrival\n',
    deliveries_list: 'Deliveries list',
    edit_cost_cycle: 'Edit Cost Cycle',
    edit_cycle_data: 'Edit Cycle Data',
    edit_house_data: 'Edit House Data',
    expiration_date: 'Expiration date',
    forget_password: 'Forgot password ?',
    medication_name: 'Medication Name',
    medication_unit: 'Medication Unit',
    placement_house: 'Placement House',
    you_are_offline: 'You are offline',
    long_description: 'Long Description',
    medication_batch: 'Medication Batch',
    medication_types: 'Medication Names',
    vaccination_name: 'Vaccination Name',
    vaccination_unit: 'Vaccination Unit',
    add_cost_category: 'Add Cost Category',
    add_new_placement: 'Add New Placement',
    'add_your_house(s)': 'Add Your House(s)',
    application_route: 'Application Route',
    create_an_account: 'Create new account',
    enter_direct_cost: 'Enter direct cost',
    place_your_chicks: 'Place your Chicks',
    vaccination_types: 'Vaccination Names',
    edit_cost_category: 'Edit Cost Category',
    placement_quantity: 'Placement Quantity',
    please_enter_email: 'Please enter your email',
    please_select_farm: 'Please select farm',
    please_select_type: 'Please select type',
    add_medication_type: 'Add Medication Name',
    add_new_vaccination: 'Add New Vaccination',
    medication_consumed: 'Medication Consumed',
    other_category_type: 'Other category type',
    please_select_batch: 'Please select batch',
    please_select_house: 'Please select house',
    "delete_energy_delivery" : "Delete energy delivery",
    delete_feed_delivery: 'Delete feed delivery',
    edit_medication_type: 'Edit Medication Name ',
    please_cost_category: 'Please cost category',
    quantity_per_package: 'Quantity Per Package',
    add_new_cost_category: 'Add New Cost Category',
    quantity_package_unit: 'quantity per unit',
    add_new_indirect_costs: 'Add New Indirect Costs',
    breed_code_is_required: 'Breed code is required',
    breed_name_is_required: 'Breed name is required',
    house_code_is_required: 'House code is required',
    please_enter_item_code: 'Please enter item code',
    please_enter_last_name: 'Please enter last name',
    please_enter_full_name: 'Please enter full name',
    please_enter_type_name: 'Please enter type name',
    please_select_category: 'Please select category',
    please_select_hatchery: 'Please select hatchery',
    please_enter_breed_code: 'Please enter breed code',
    please_enter_breed_name: 'Please enter breed name',
    please_enter_cycle_code: 'Please enter cycle code',
    please_enter_first_name: 'Please enter first name',
    please_enter_house_code: 'Please enter house code',
    please_enter_your_email: 'Please enter your email',
    please_select_feed_type: 'Please select feed type',
    "please_select_energy_type" : "Please select energy type" ,
    select_vaccination_name: 'Select vaccination name',
    add_new_vaccination_type: 'Add Vaccination Name',
    cost_must_be_more_than_0: 'Cost must be more than 0',
    please_enter_description: 'Please enter description',
    select_vaccination_batch: 'Select vaccination batch',
    "don't_have_an_account_yet": "Don't have an account yet",
    please_enter_doses_number: 'Please enter doses',
    please_enter_phone_number: 'Please enter phone number',
    delete_medication_delivery: 'Delete medication delivery',
    delete_other_type_delivery: 'Delete other type delivery',
    please_enter_your_password: 'Please enter your password',
    select_other_type_category: 'Select other type category',
    cycle_code_name_is_required: 'Cycle code name is required',
    delete_vaccination_delivery: 'Delete vaccination delivery',
    expiration_date_is_required: 'Expiration date is required',
    you_must_enter_batch_number: 'You must enter batch number',
    edit_cost_cyclecost_category: 'Edit cost cycle Cost category',
    please_enter_dead_on_arrival: 'Please enter dead on arrival',
    please_enter_house_size_area: 'Please enter house size area',
    please_enter_medication_name: 'Please enter medication name',
    please_enter_medication_unit: 'Please enter medication unit',
    please_select_placement_farm: 'Please select placement farm',
    quantity_must_be_more_than_0: 'Quantity must be more than 0',
    please_enter_long_description: 'Please enter long description',
    please_enter_vaccination_name: 'Please enter vaccination name',
    please_enter_vaccination_unit: 'Please enter vaccination unit',
    please_select_medication_type: 'Please select medication type',
    please_select_placement_house: 'Please select placement house',
    cost_category_name_is_required: 'Cost category  name is required',
    please_enter_vaccination_doses: 'Please enter vaccination doses',
    please_select_vaccination_type: 'Please select vaccination type',
    please_enter_placement_quantity: 'Please enter placement quantity',
    you_must_enter_delivery_quantity: 'You must enter delivery quantity',
    please_enter_quantity_package_unit: 'Please enter quantity per unit',
    please_enter_medication_description: 'Please enter medication description',
    are_you_sure_you_want_to_delete_cycle: 'Are you sure you want to delete cycle',
    are_you_sure_you_want_to_delete_closure: 'Are you sure you want to delete closure',
    are_you_sure_you_want_to_delete_standard: 'Are you sure you want to delete standard',
    please_enter_medication_long_description: 'Please enter medication long description',
    are_you_sure_you_want_to_delete_placement: 'Are you sure you want to delete placement',
    are_you_sure_you_want_to_delete_medication: 'Are you sure you want to delete medication',
    are_you_sure_you_want_to_delete_feed_delivery: 'Are you sure you want to delete feed delivery',
    "are_you_sure_you_want_to_delete_energy_delivery" : "Are you sure you want to delete energy delivery",
    are_you_sure_you_want_to_delete_other_delivery: 'Are you sure you want to delete other delivery',
    are_you_sure_you_want_to_delete_medication_delivery: 'Are you sure you want to delete medication delivery',
    are_you_sure_you_want_to_delete_vaccination_delivery: 'Are you sure you want to delete vaccination delivery',
    edit_indirect_cost: 'Edit indirect cost',
    age: 'Age',
    std: 'Std',
    co_2: 'Co 2',
    edit: 'Edit',
    loss: 'Loss',
    price: 'Price',
    selling: 'Selling',
    add_farm: 'Add Farm',
    max_co_2: 'Max Co2',
    min_co_2: 'Min Co2',
    the_date: 'The date',
    the_time: 'The time',
    total_kg: 'Sales weight',
    best_farm: 'Best',
    dashboard: 'Dashboard',
    farm_name: 'Farm Name',
    summaries: 'Summaries',
    cull_types: 'Cull types',
    daily_data: 'Daily data',
    farms_data: 'Farms Data',
    farms_list: 'Farms List',
    feed_types: 'Feed types',
    hatcheries: 'Hatcheries',
    'price / kg': 'Price / kg',
    sales_type: 'Sales type',
    statistics: 'Statistics',
    worst_farm: 'Worst',
    consumption: 'Consumption',
    delete_farm: 'Delete Farm',
    energy_type: 'Energy type',
    governorate: 'Governorate',
    other_types: 'Other types',
    reject_type: 'Cull type',
    sales_types: 'Sales types',
    select_type: 'Select type',
    total_wight: 'Total wight',
    add_cul_type: 'Add Cull Type',
    add_hatchery: 'Add Hatchery',
    add_new_farm: 'Add New Farm',
    average_co_2: 'Average Co2',
    culls_weight: 'Culls weight',
    delete_breed: 'Delete Breed',
    delete_cycle: 'Delete Cycle',
    delete_house: 'Delete House',
    energy_types: 'Energy types',
    'feed_type #1': 'Feed type #1',
    'feed_type #2': 'Feed type #2',
    select_cycle: 'Select cycle',
    un_accounted: 'Unaccounted',
    add_feed_type: 'Add Feed Type',
    category_name: 'Category name',
    'feed_batch #1': 'Feed batch #1',
    'feed_batch #2': 'Feed batch #2',
    hatchery_code: 'Hatchery Code',
    hatchery_name: 'Hatchery Name',
    justification: 'Justification',
    quantity_left: 'Quantity left',
    show_sections: 'Show Sections',
    add_other_type: 'Add Other Type',
    add_sales_type: 'Add Sales Type',
    culls_quantity: 'Culls quantity',
    edit_feed_type: 'Edit Feed Type',
    'feed_intake #1': 'Feed intake #1',
    'feed_intake #2': 'Feed intake #2',
    sales_quantity: 'Sales quantity',
    add_energy_type: 'Add Energy Type',
    delete_hatchery: 'Delete Hatchery',
    document_number: 'Document number',
    other_type_name: 'Other type name',
    write_a_comment: 'Write a comment',
    add_farm_closure: 'Add Farm Closure',
    add_new_hatchery: 'Add New Hatchery',
    code_is_required: 'Code is required',
    delete_cull_type: 'Delete Cull Type',
    delete_feed_type: 'Delete Feed Type',
    delete_placement: 'Delete placement',
    delete_sale_type: 'Delete Sale type',
    energy_type_data: 'Energy type data',
    energy_type_name: 'Energy Type Name',
    farm_is_required: 'Farm is name required',
    humidity_evening: 'Humidity evening',
    humidity_mid_day: 'Humidity mid day',
    humidity_morning: 'Humidity morning',
    mortality_weight: 'Mortality weight',
    name_is_required: 'Name is required',
    number_of_houses: 'Number Of Houses',
    unit_is_required: 'Unit is required',
    add_new_cull_type: 'Add Cull Type',
    breed_is_required: 'Breed is required',
    consumed_quantity: 'Consumed quantity',
    delete_medication: 'Delete Medication',
    delete_other_type: 'Delete Other type',
    edit_culls_record: 'Edit culls record',
    edit_energy_record : "Edit Energy record" ,
    edit_sales_record: 'Edit sales record',
    please_enter_unit: 'Please enter unit',
    select_sales_type: 'Select sales type',
    delete_energy_type: 'Delete energy type',
    delete_vaccination: 'Delete Vaccination',
    doses_are_required: 'Doses are required',
    edit_hatchery_data: 'Edit Hatchery Data',
    mortality_quantity: 'Mortality quantity',
    weight_is_required: 'Weight is required',
    add_sales_type_data: 'Add Sales Type ',
    delete_farm_closure: 'Delete Farm Closure',
    edit_cull_type_data: 'Edit Cull Type Data',
    edit_placement_data: 'Edit placement data',
    quantity_as_hatched: 'Quantity as hatched',
    select_batch_number: 'Select batch number',
    add_vaccination_type: 'Add Vaccination Name',
    edit_sales_type_data: 'Edit sales type data',
    other_types_category: 'Other types category',
    quantity_is_required: 'Quantity is required',
    select_category_name: 'Select category name',
    cull_type_is_required: 'Cull type is required',
    edit_energy_type_data: 'Edit energy Type Data',
    edit_mortality_record: 'Edit mortality record',
    farm_name_is_required: 'Farm name is required',
    item_code_is_required: 'Item Code is required',
    rejects_types_choices: 'Rejects types choices',
    type_name_is_required: 'Type name is required',
    cycle_code_is_required: 'Cycle code is required',
    edit_medication_record: 'Edit medication record',
    edit_other_type_record: 'Edit other type record',
    live_birds_is_required: 'Live Birds is required',
    other_type_consumption: 'Other Type Consumption',
    other_types_categories: 'Other types categories',
    please_enter_farm_name: 'Please enter farm name',
    please_enter_feed_name: 'Please enter feed type name',
    sales_type_is_required: 'Sales type is required',
    select_medication_name: 'Select medication name',
    add_medication_delivery: 'Add Medication Delivery',
    add_other_type_category: 'Add Other Type Category',
    add_other_type_delivery: 'Add Other Type Delivery',
    consumption_is_required: 'Consumption is required',
    cull_weight_is_required: 'Cull weight is required',
    description_is_required: 'Description is required',
    edit_vaccination_record: 'Edit vaccination record',
    energy_type_is_required: 'Energy type is required',
    please_enter_house_name: 'Please enter house name',
    sales_price_is_required: 'Sales price is required',
    add_vaccination_delivery: 'Add Vaccination Delivery',
    batch_number_is_required: 'Batch number is required',
    edit_other_type_category: 'Edit Other Type Category',
    other_type_category_name: 'Other type category',
    MAX_SIZE_AREA_MAX:"Size are must be less than or equal to",
    select_application_route: 'Select application route',
    selling_date_is_required: 'Selling date is required',
    edit_unaccountable_record: 'Edit Unaccountable Record',
    hatchery_code_is_required: 'Hatchery code is required',
    hatchery_name_is_required: 'Hatchery name is required',
    justification_is_required: 'Justification is required',
    please_enter_litter_sales: 'Please enter litter sales',
    please_select_governorate: 'Please select governorate',
    you_must_select_feed_type: 'You must Select Feed Type',
    culls_quantity_is_required: 'Culls quantity is required',
    dead_on_arrival_as_hatched: 'Dead on arrival as hatched',
    delete_other_type_category: 'Delete Other Type Category',
    edit_vaccination_type_data: 'Edit Vaccination Name',
    feed_type_name_is_required: 'Feed type name is required',
    placement_date_is_required: 'Placement date is required',
    please_enter_category_name: 'Please enter category name',
    please_enter_hatchery_code: 'Please enter hatchery code',
    please_enter_hatchery_name: 'Please enter hatchery name',
    please_enter_rejects_types: 'Please enter cull types',
    sales_quantity_is_required: 'Sales quantity is required',
    you_must_enter_feed_intake: 'You must Enter Feed Intake',
    you_must_select_feed_batch: 'You must Select Feed Batch',
    dead_on_arrival_is_required: 'Dead on arrival is required',
    document_number_is_required: 'Document number is required',
    medication_name_is_required: 'Medication name is required',
    medication_unit_is_required: 'Medication unit is required',
    other_type_name_is_required: 'Other type name is required',
    "weight_can't_be_less_than_0": "Weight can't be less than 0",
    long_description_is_required: 'Long description is required',
    medication_batch_is_required: 'Medication batch is required',
    vaccination_name_is_required: 'Vaccination name is required',
    vaccination_unit_is_required: 'Vaccination unit is required',
    application_route_is_required: 'Application route is required',
    please_enter_energy_type_name: 'Please enter energy type name',
    vaccination_batch_is_required: 'Vaccination batch is required',
    you_must_enter_culls_quantity: 'You must Enter Culls Quantity',
    max_co2_must_be_numerical_value: 'Max co2 must be numerical value',
    min_co2_must_be_numerical_value: 'Min co2 must be numerical value',
    intake_must_be_a_positive_number: 'Intake must be a positive number',
    quantity_must_be_numerical_value: 'Quantity must be numerical value',
    sales_total_quantity_is_required: 'Sales total quantity is required',
    weight_must_be_a_numerical_value: 'Weight must be a numerical value',
    weight_must_be_a_positive_number: 'Weight must be a positive number',
    houses_number_must_be_more_than_0: 'Houses Number must be more than 0',
    please_enter_quantity_per_package: 'Please enter quantity per package',
    quantity_package_unit_is_required: 'quantity per unit is required',
    you_must_enter_mortality_quantity: 'You must enter mortality quantity',
    'Farm with this Name already exists': 'Farm with this Name already exists',
    live_birds_must_be_numerical_value: 'Live birds must be numerical value',
    quantity_must_be_a_numerical_value: 'Quantity must be a numerical value',
    quantity_must_be_a_positive_number: 'Quantity must be a positive number',
    average_co2_must_be_numerical_value: 'Average co2 must be numerical value',
    are_you_sure_you_want_to_delete_farm: 'Are you sure you want to delete farm',
    are_you_sure_you_want_to_delete_feed: 'Are you sure you want to delete feed',
    are_you_sure_you_want_to_delete_type: 'Are you sure you want to delete type',
    other_type_category_name_is_required: 'Other type category name is required',
    'Are you sure you want to delete cycle': 'Are_you_sure_you_want_to_delete_cycle',
    are_you_sure_you_want_to_delete_breed: 'Are you sure you want to delete breed',
    are_you_sure_you_want_to_delete_house: 'Are you sure you want to delete house',
    consumption_must_be_a_numerical_value: 'Consumption must be a numerical value',
    sales_price_must_be_a_numerical_value: 'Sales price must be a numerical value',
    sales_price_must_be_a_positive_number: 'Sales price must be a positive number',
    sales_total_must_be_a_numerical_value: 'Sales total must be a numerical value',
    sales_total_must_be_a_positive_number: 'Sales total must be a positive number',
    vaccination_doses_must_be_more_than_0: 'Vaccination Doses must be more than 0',
    rejects_types_choices_name_is_required: 'Cull types choices is required',
    weight_must_be_less_than_or_equal_to_4: 'Weight must be less than or equal to 4',
    min_co2_can_not_be_greater_than_max_co2: 'Min co2 can not be greater than max Co2',
    temperature_should_be_between_1_and_50: 'Temperature should be between 1 and 50',
    are_you_sure_you_want_to_delete_hatchery: 'Are you sure you want to delete hatchery',
    average_co2_can_not_be_less_than_min_co2: 'Average co2 can not be less than min Co2',
    quantity_per_package_must_be_more_than_0: 'Quantity per package must be more than 0',
    sales_quantity_must_be_a_numerical_value: 'Sales quantity must be a numerical value',
    sales_quantity_must_be_a_positive_number: 'Sales quantity must be a positive number',
    vaccination_doses_must_be_integer_number: 'Vaccination doses must be an integer number',
    are_you_sure_you_want_to_delete_cull_type: 'Are you sure you want to delete cull type',
    are_you_sure_you_want_to_delete_sale_type: 'Are you sure you want to delete sale type',
    item_code_has_to_be_at_most_20_characters: 'Item Code has to be at most 20 characters',
    max_co2_can_not_less_greater_than_min_co2: 'Max co2 can not less greater than min co2',
    weight_must_be_greater_than_or_equal_to_0: 'Weight must be greater than or equal to 0',
    "'max_co2_can_not_less_greater_than_min_co2": "Max co2 can not less greater than min Co2",
    percentage_must_be_less_than_or_equal_to_0: 'Percentage must be less than or equal to 0',
    are_you_sure_you_want_to_delete_energy_type: 'Are you sure you want to delete energy type',
    are_you_sure_you_want_to_delete_vaccination: 'Are you sure you want to delete vaccination',
    average_co2_can_not_be_greater_than_max_co2: 'Average co2 can not be greater than max co2',
    min_co2_can_not_be_greater_than_average_co2: 'Min co2 can not be greater than average Co2',
    percentage_must_be_less_than_or_equal_to_100: 'Percentage must be less than or equal to 100',
    live_birds_must_be_greater_than_or_equal_to_0: 'Live birds must be greater than or equal to 0',
    max_co2_can_not_less_greater_than_average_co2: 'Max co2 can not less greater than average Co2',
    consumption_must_be_greater_than_or_equal_to_0: 'Consumption must be greater than or equal to 0',
    feed_type_name_has_to_be_at_most_50_characters: 'Feed type name has to be at most 50 characters',
    quantity_per_package_must_be_an_integer_number: 'Quantity per package must be an integer number',
    litter_sales_must_be_greater_than_or_equal_to_0: 'Litter Sales must be greater than or equal to 0',
    unaccountable_quantity_must_be_a_positive_number: 'Unaccountable quantity must be a positive number',
    vaccination_name_has_to_be_at_most_20_characters: 'Vaccination name has to be at most 20 characters',
    vaccination_unit_has_to_be_at_most_20_characters: 'Vaccination unit has to be at most 20 characters',
    are_you_sure_you_want_to_delete_placement_on_date: 'Are you sure you want to delete placement on date',
    it_seems_you_do_not_have_any_medication_deliveries: 'It seems you do not have any medication deliveries',
    it_seems_you_do_not_have_any_other_type_deliveries: 'It seems you do not have any other type deliveries',
    are_you_sure_you_want_to_delete_other_type_category: 'Are you sure you want to delete other type category',
    'it_seems_you_do_not_have_any_other_type_deliveries\n': 'It seems you do not have any other type deliveries',
    it_seems_you_do_not_have_any_vaccination_deliveries: "It seems you don't have any vaccination deliveries",
    are_you_sure_you_want_to_delete_farm_closure_in_date: 'Are you sure you want to delete farm closure in date',
    medication_quantity_must_be_greater_than_or_equal_to_0: 'Medication quantity must be greater than or equal to 0',
    maximum_temperature_can_not_be_less_than_minimum_temperature: 'Maximum Temperature can not be less than Minimum Temperature',
    dead_on_arrival_must_be_less_than_or_equal_placement_quantity: 'Dead on arrival must be less than or equal placement quantity',
    FCR: 'FCR',
    next: 'Next',
    costs: 'Costs',
    login: 'Login',
    phone: 'Phone',
    reset: 'Reset',
    arabic: 'Arabic',
    finish: 'Finish',
    logout: 'Logout',
    wizard: 'Wizard',
    country: 'Country',
    english: 'English',
    expired: 'Expired',
    medicine: 'Medicine',
    previous: 'Previous',
    register: 'Register',
    username: 'Username',
    water_ph: 'Water pH',
    cull_type: 'Cull type',
    farm_data: 'Farm data',
    feed_type: 'Feed type',
    for_cycle: 'For cycle',
    water_orp: 'Water ORP',
    house_area: 'House area',
    house_data: 'House Data',
    management: 'Farm management',
    create_date: 'Delivery Date',
    delete_user: 'Delete User',
    live_weight: 'Live weight',
    'add_house(s)': 'Add  House(s)',
    breed_filter: 'Breed filter',
    company_name: 'Company name',
    max_humidity: 'Max humidity',
    min_humidity: ' Min humidity',
    new_password: 'New Password',
    place_chicks: 'Place Chicks',
    total_weight: 'Total weight',
    back_to_login: 'Back to login',
    edit_standard: 'Edit standard',
    placement_doa: 'Dead on arrival',
    water_orp_can: 'Water ORP can',
    ' edit_standard': 'Edit standard',
    administration: 'Administration',
    broilers_years: 'Number of Broilers Per Cycle',
    configurations: 'Configurations',
    placement_data: 'Placement data',
    select_a_breed: 'Select a breed',
    select_country: 'Select country',
    add_direct_cost: 'Add Direct Cost',
    age_is_required: 'Age is required',
    check_your_mail: 'Check Your Mail',
    delete_standard: 'Delete Standard',
    medication_type: 'Medication names',
    other_type_data: 'Other type data',
    add_new_standard: 'Add New Standard',
    confirm_password: 'Confirm Password',
    edit_direct_cost: 'Edit direct cost',
    please_enter_age: 'Please enter age',
    please_enter_fcr: 'Please enter fcr',
    vaccination_type: 'Vaccination names',
    add_indirect_cost: 'Add Indirect Cost',
    email_is_required: 'Email is required',
    house_is_required: 'House is required',
    delete_direct_cost: 'Delete direct cost',
    server_unreachable: 'Server unreachable',
    daily_data_sections: 'Daily Data Sections',
    invalid_mail_format: 'Invalid mail format',
    other_type_category: 'Other type category',
    you_must_enter_cost: 'You must Enter cost',
    delete_cost_category: 'Delete cost category',
    delete_indirect_cost: 'Delete indirect cost',
    invalid_email_format: 'Invalid email format',
    password_is_required: 'Password is required',
    passwords_must_match: 'Passwords must match',
    something_went_wrong: 'Something went wrong',
    username_is_required: 'Username is required',
    area_size_is_required: 'Area size is required',
    last_name_is_required: 'Last name is required',
    please_enter_username: 'Please enter your username',
    please_enter_water_ph: 'Please enter water pH',
    please_select_country: 'Please select country',
    can_not_save_your_data: 'Can not save your data',
    first_name_is_required: 'First name is required',
    house_area_is_required: 'House Area is required',
    please_enter_mortality: 'Please enter mortality',
    please_enter_type_unit: 'Please enter type unit',
    please_enter_water_orp: 'Please enter water ORP',
    broilers_configurations: 'Broilers Configurations',
    max_outside_temperature: 'Max temperature',
    min_outside_temperature: 'Min temperature',
    please_enter_house_area: 'Please enter house area',
    resent_account_password: 'Resent Account Password',
    send_confirmation_email: 'Send confirmation email',
    successfully_registered: 'Successfully Registered',
    successfully_registeret: 'Successfully registered !',
    FCR_has_to_be_at_least_0: 'FCR has to be at least 0',
    and_confirm_registration: 'And confirm registration',
    company_name_is_required: 'Company name is required',
    phone_number_is_required: 'Phone number is required',
    please_enter_feed_intake: 'Please enter feed intake',
    please_enter_live_weight: 'Please enter live weight',
    please_enter_temperature: 'Please enter temperature',
    houses_number_is_required: 'Houses Number is required',
    placement_doa_is_required: 'Placement dead on arrival  quantity is required',
    please_enter_company_name: 'Please enter company name',
    please_enter_max_humidity: 'Please enter max humidity',
    please_enter_min_humidity: 'Please enter min humidity',
    please_enter_water_intake: 'Please enter water intake',
    you_must_specify_a_number: 'You must specify a number',
    please_enter_placement_doa: 'Please enter placement dead on arrival',
    farm_age_has_to_be_at_most_0: 'Farm age has to be at least  0',
    ph_must_be_a_positive_number: 'PH must be a positive number',
    you_must_enter_houses_number: 'You must Enter Houses Number',
    configurations_has_been_saved: 'Configurations has been saved',
    farm_age_has_to_be_at_least_0: 'Farm age has to be at least 0',
    mortality_has_to_be_at_most_0: 'Mortality has to be at least 0',
    orp_must_be_a_positive_number: 'ORP must be a positive number',
    please_enter_number_of_houses: 'Please enter number of houses',
    please_select_expiration_date: 'Please Select expiration date',
    quantity_has_to_be_at_least_1: 'Quantity has to be at least 1',
    water_ph_has_to_be_at_least_0: 'Water pH has to be at least 0',
    email_you_entered_is_incorrect: 'Wrong email address',
    house_area_must_be_more_than_0: 'House area must be more than 0',
    mortality_has_to_be_at_least_0: 'Mortality has to be at east 0',
    number_of_broilers_is_required: 'Number of broilers is required',
    placement_quantity_is_required: 'Placement quantity is required',
    please_enter_your_phone_number: 'Please enter your phone number',
    water_orp_has_to_be_at_least_0: 'Water ORP has to be at least 0',
    doses_must_be_a_numerical_value: 'Doses must be a numerical value',
    'house_area_must_be_more_than_0"': 'House Area must be more than 0"',
    other_type_category_is_required: 'Other type category is required',
    already_running_with_chicken_app: 'Already running with chicken.App ?',
    feed_intake_has_to_be_at_least_0: 'Feed intake has to be at least 0',
    temperature_has_to_be_at_least_0: 'Temperature has to be at least 0',
    password_confirmation_is_required: 'Password Confirmation is required',
    water_intake_has_to_be_at_least_0: 'Water intake has to be at least 0',
    water_orp_can_not_be_more_than_10: 'Water ORP can not be more than 10',
    email_or_phone_number_are_required: 'Email or phone number are required',
    enter_your_email_to_reset_password: 'Enter your email to reset password',
    other_type_consumption_is_required: 'Other type consumption is required',
    unaccountable_quantity_is_required: 'Unaccountable Quantity is required',
    cost_category_code_name_is_required: 'Cost category code name is required',
    live_weight_age_has_to_be_at_most_0: 'Live weight age has to be at least 0',
    number_of_broilers_must_be_positive: 'Number of broilers must be positive',
    house_area_must_be_a_numerical_value: 'House area must be a numerical value',
    live_weight_age_has_to_be_at_least_0: 'Live weight age has to be at least 0',
    name_has_to_be_at_most_20_characters: 'Name has to be at most 20 characters',
    please_enter_max_outside_temperature: 'Please enter max outside temperature',
    please_enter_min_outside_temperature: 'Please enter min outside temperature',
    the_field_should_contain_digits_only: 'The field should contain digits only',
    unit_has_to_be_at_most_20_characters: 'Unit has to be at most 20 characters',
    can_not_use_the_same_batch_once_again: 'Can not use the same batch once again',
    quantity_must_be_less_than_live_birds: 'Quantity must be less than Live Birds',
    house_number_must_be_an_integer_number: 'House number must be an integer number',
    placement_quantity_must_be_more_than_0: 'Placement Quantity must be more than 0',
    enter_new_password_and_confirm_password: 'Enter new password and confirm password',
    placement_date_field_must_be_later_than: 'Placement date field must be later than or equal',
    you_have_to_select_different_feed_batch: 'You have to select different feed batch',
    doses_must_be_greater_than_or_equal_to_0: 'Doses must be greater than or equal to 0',
    expiration_date_field_must_be_later_than: 'Expiration date field must be later than',
    item_code_has_to_be_at_most_4_characters: 'Item Code has to be at most 4 characters',
    live_birds_must_be_less_than_or_equal_to: 'Live birds must be less than or equal to',
    max_co_2_must_be_greater_than_or_equal_0: 'Max co2 must be greater than or equal 0',
    min_co_2_must_be_greater_than_or_equal_0: 'Min co2 must be greater than or equal 0',
    breed_code_has_to_be_at_most_4_characters: 'Breed code has to be at most 4 characters',
    farm_name_has_to_be_at_most_30_characters: 'Farm name has to be at most 30 characters',
    house_code_has_to_be_at_most_4_characters: 'House code has to be at most 4  characters',
    phone_number_can_not_be_less_or_more_than: "Phone number can't be less or more than",
    this_filed_has_to_be_at_most_4_characters: 'This filed has to be at most 4 characters',
    to_activate_your_account_check_your_email: 'To activate your account check your email or OTP sent to your phone',
    breed_name_has_to_be_at_most_20_characters: 'Breed  name has to be at most 20 characters',
    house_name_has_to_be_at_most_10_characters: 'House name has to be at most 10 characters',
    house_name_has_to_be_at_most_50_characters: 'House name has to be at most 50 characters',
    number_of_houses_must_be_a_numerical_value: 'Number of houses must be a numerical value',
    this_filed_has_to_be_at_most_50_characters: 'This filed has to be at most 50 characters',
    description_has_to_be_at_most_50_characters: 'Description has to be at most 50 characters',
    energy_type_has_to_be_at_most_20_characters: 'Energy type has to be at most 20 characters',
    placement_quantity_must_be_an_integer_value: 'Placement quantity must be an integer value',
    sales_name_has_to_be_at_most_100_characters: 'Sales name has to be at most 100 characters',
    average_co_2_must_be_greater_than_or_equal_0: 'Average co2 must be greater than or equal 0',
    cost_category_has_to_be_at_most_20_characters: 'Cost category has to be at most 20 characters',
    selected_house_does_not_work_in_selected_date: 'Selected house does not work in selected date',
    cycle_code_name_has_to_be_at_most_6_characters: 'Cycle code name has to be at most 6 characters',
    total_remain_live_birds_must_be_greater_than_0: 'Total remain live birds  must be greater than 0',
    medication_unit_has_to_be_at_most_20_characters: 'Medication unit has to be at most 20 characters',
    description_unit_has_to_be_at_most_50_characters: 'Description unit has to be at most 50 characters',
    long_description_has_to_be_at_most_50_characters: 'Long description has to be at most 50 characters',
    medication_name_has_to_be_at_most_100_characters: 'Medication name has to be at most 100 characters',
    we_sent_you_an_email_to_confirmed_password_reset: 'We sent you an email to confirmed password reset',
    congrats_your_account_has_been_successfully_created: 'Congrats your account has been successfully created',
    you_will_receive_an_email_to_confirm_password_reset: 'You will receive an email to confirm password reset',
    you_can_only_edit_this_delivery_in_the_creation_time: 'You can only edit this delivery in the creation time',
    "feed_intake_quantity_can't_be_more_than_quantity_left": "Feed intake quantity can't be more than QuantityLeft",
    medication_quantity_must_be_greater_than_or_equal_to_1: 'Medication quantity must be greater than or equal to 1',
    rejects_types_choices_has_to_be_at_most_100_characters: 'Cull types choices has to be at most 100 characters',
    it_seems_you_can_not_consume_form_this_batch_once_again: 'All batches are used from this category in this day',
    vaccination_quantity_must_be_greater_than_or_equal_to_0: 'Vaccination quantity must be greater than or equal to 0',
    are_you_sure_you_want_to_delete_standard_record_for_breed: 'Are you sure you want to delete standard record for breed',
    expiration_date_field_must_be_later_than_the_creation_date: 'Expiration date field must be later than the creation date',
    are_you_sure_you_want_to_delete_direct_cost_record_for_farm: 'Are you sure you want to delete direct cost record for farm',
    'expiration data must be greater than or equal to today date': 'Expiration data must be greater than or equal to today date',
    are_you_sure_you_want_to_delete_indirect_cost_record_for_farm: 'Are you sure you want to delete indirect cost record for farm',
    "dead_on_arrival_must_be_less_than_or_equal_placement_quantity'": "Dead on arrival must be less than or equal placement quantity'",
    increase_your_broilers_efficiency_using_chicken_app_application: 'Increase your broilers efficiency using ChickenApp Application',
    placement_quantity_must_be_greater_than_placement_dead_on_arrival: 'Placement Quantity must be greater than Placement dead on arrival',
    to_activate_your_account_check_your_email_and_confirm_registration: 'To activate your account check your email and confirm registration',
    consumption_must_be_less_than_or_equal_to_available_delivery_quantity: 'Consumption must be less than or equal to available delivery quantity',
    to_reset_your_account_password_check_your_email_and_confirm_registration: 'To reset your account password check your email and confirm registration',
    financials: 'Financials',
    area_has_to_be_more_than_0: 'Are has to be more than 0',
    phone_number: 'Phone Number',
    please_select_breed: 'Please select breed',
    please_select_cycle: 'Please select cycle',
    house_name_is_required: 'House name is required',
    password_you_entered_is_incorrect: 'Password you entered is incorrect',
    are_you_sure_you_want_to_delete_user: 'Are you sure you want to delete user',
    are_you_sure_you_want_to_delete_cost_category: 'Are you sure you want to delete cost category',
    incorrect_phone_number: 'Incorrect phone number',
    standard_breed:'predefined',
    you_can_not_edit_standard_breed:'You can not edit standard breed',
    login_to_your_account:'Log in to your account',
    create_new_account:"Create new account",
    clear:"Clear",
    verify_otp:"Verify OTP",
    resend_link:"Resend Code",
    if_you_did_not_get_a_code_please_click_resend_and_try_again_after:"If you didn't get a code, please click resend and try again after",
    seconds:"seconds",
    to_activate_you_account_enter_the_4_digit_code_sent_to:"To activate you account enter the 4 digit code sent to",
    enter_4_digit_code:"Enter 4 digit code",
    "did_not_get_the_code_yet?":"Did not get the code yet",
    you_can_only_consume_from_same_batch_one_time_in_same_day:"You can only consume from same batch one time in same day",
    no_items_available:"No items available",
    no_filtering_results_available:"No filtering results available",
    "quantity_must_be_an_integer_value": "quantity must be an integer value",
    "this_field_must_be_a_numeric_value": "This field must be a numeric value",
    "you_can_not_exceed_available_delivery_quantity": "You can not exceed available delivery quantity",
    "live_birds_must_be_an_integer_value": "Live birds must be an integer value",
    "vaccination_quantity_must_be_greater_than_0":"Vaccination quantity must be greater than 0",
    "you_will_receive_an_email_or_a_sms_message_to_confirm_password_reset" : "You will receive an email or a sms message to confirm password reset" ,
    "send_confirmation" : "Send confirmation" ,
    "check_your_mail_or_mobile":"Check your mail or mobile",
    "we_sent_you_an_email_or_sms_message_to_confirmed_password_reset":"We sent you an email or SMS message to confirm password reset",
    "to_reset_your_account_password_check_your_email_or_mobile_and_confirm_registration" : "To reset your account password check your email or mobile and confirm registration",
    "email_or_phone_number_is_required":"Email or phone number is required",
    "you_are_viewing_a_predefined_breed_with_predefined_standards":"You are viewing a predefined breed with predefined standards",
    "predefined_breeds_and_standards_are_system_specified_and_could_not_be_updated_or_deleted":"Predefined breeds and standards are system specified and could not be updated or deleted",
    "placement_date_must_be_at_earlier_than_or_equal_today_date":"Placement date must be at earlier than or equal today date",
    "number_of_houses_hint":"(eg: House-1, House-2, House-3, ...)",
    "please_enter_user_name":"Please enter user name",
    "user_name_is_required":"User name is required",
    "can_not_select_date_in_the_future":"Can not select date in the future",
    "house_name_will_be_common_for_all_create_number_of_houses":"House name will be common for all created a houses in addition to the house number",
    "to_achieve_better_accessibility_you_can_use_the_wizard_to_quickly_create_farm_house_and_placement":"you can use the wizard to quickly create farm, house and placement",
    "on_the_other_hand_you_can_use_the_farm_management_section_to_create_and_maintain_them_separately":"Also you can use the farm management to create and maintain them separately",
    "placement_qty_example":"(Placement quantity will be equally assigned to each house, eg: 10,000 for each house and could be changed later in farm management)",
    "max_digit":"This field must be less than or equal to 1000000",
    "max_house":"Number of houses must be less than or equal to 100",
    "phone_number_should_contain_digits_only":"Phone number should contain digits only",
    "search_and_filter":"Search and Filter",
    "farm_age_chart":"Farm ages",
    "water_ph_can_not_be_more_than_10":"Water ph can not be more than 10",
    "cost_max":"Cost must be less than or equal to 1,000,000",
    "shortage_max_qty":"Shortage/Extra must be less than or equal to",
    "shortage_min_qty":"Shortage/Extra must be  greater or equal to",
    "DOA_MAX":"Dead on arrival must be less than or equal to",
    "QTY_MAX":"Quantity must be less than or equal to",
    "DOSES_MAX":"Doses must be less than or equal to",
    "SIZE_AREA_MAX":"Size are must be less than or equal to",
    "MAX_LITTER_SALES":"Litter sales must be less than or equal to",
    "MAX_STD_AGE":"Max standard age must be less than or equal to",
    "MAX_STD_LIVE_WEIGHT":"Max standard weight must be less than or equal to",
    "MAX_STD_MORTALITY":"Max standard mortality must be less than or equal to",
    "MAX_STD_FEED_INTAKE":"Max standard feed intake must be less than or equal to",
    "MAX_STD_WATER_PH":"Max standard water ph must be less than or equal to",
    "MAX_STD_WATER_INTAKE":"Max standard water intake must be less than or equal to",
    "MAX_STD_ORP":"Max standard water ORP must be less than or equal to",
    "MAX_STD_HUMIDITY":"Max standard humidity must be less than or equal to",
    "MAX_STD_FCR":"Max standard fcr must be less than or equal to",
    "MAX_STD_TEMP":"Max standard standard temperature  must be less than or equal to",
    "DOA_MIN":"Dead on arrival must be greater than or equal to",
    "delivery_energy_qty_max" : "Energy delivery must be less than or equal to" ,
    "delivery_feed_qty_max":"Feed delivery must be less than or equal to",
    "are_you_sure_you_want_to_logout_?":"Are you sure you want to log out?",
    "DATE_MAX_TODAY":"Date must be earlier than or equal to today date",
    "delivery_qty_max":"Quantity must be less than or equal to",
    "placement_quantity_must_be_greater_than_or_equal_to_0":"Placement quantity must be greater than or equal to 0",
    "your_recorded_daily_data_will_be_lost":"Your recorded daily data will be lost",
    "DAILY_DATA_LOST_WARNING":"Make sure you saved changes before switching to delivery, or your unsaved data will be lost",
    "switch_to_deliveries":"Switch to deliveries",
    "back":"Back",
    "it_seems_you_do_not_have_any_vaccination":"It seems you do not have any vaccination",
    "add_vaccinations":"Add vaccinations",
    "it_seems_you_do_not_have_any_medication":"It seems you do not have any medication",
    "add_medication":"Add medication",
    "it_seems_you_do_not_have_any_other_deliveries":"It seems you do not have any other deliveries",
    "add_delivery":"Add delivery",
    "it_seems_you_do_not_have_any_other_type":"It seems you do not have any other type",
    "settings":"Settings",
    "system_system":"System settings",
    "choose_preferred_system_language":"Choose preferred system language",
    "form_here_you_can_enter_farm_name":"From here you can enter farm name",
    "and_here_you_can_enter_farm_location_or_governorate":"And here you can specify farm governorate",
    "QTY_MIN":"Quantity must be greater than or equal to",
    "shortage_must_be_greater_than_or_equal_to":"Shortage must be greater than or equal to",
    "extra_must_be_less_than_or_equal_to":"Extra must be less than or equal to",
    "it_seems_you_do_not_configured_daily_data_sections":"It seems you do not configured daily data sections",
    "you_can_change_configuration_and_show_more_sections":"You can change configuration and show more sections",
    "configure_sections":"Configure Sections",
    "save_language":"Save language",
    "language_settings":"Language settings",
    "date_is_required":"Date is required",
    "cycle_is_required":"Cycle is required",
    "assumed_weight_is_required":"Assumed weight is required",
    "reports":"Reports",
    "financial_report":"Financial Report",
    "farm_report":"Daily Report",
    "generate_report":"Generate Report",
    "assumed_weight":"Assumed weight",
    "assumed_weight_must_be_grater_than_0":"Assumed weight must be grater than 0",
    "please_enter_assumed_weight":"Please enter assumed weight",
    "date_must_be_earlier_than":"Date must be earlier than",
    "date_must_be_late_than":"Date must be later than",
    "CSV_DOWNLOAD":"Download as CVS",
    "PDF_DOWNLOAD":"Download as PDF",
    "you_have_to_select_farm_and_cycle_first":"You have to select farm and cycle first",
    "credits":"100 free points (valid for 10 placements)",
    "profile":"Profile",
    "please_select_feed":"Please select feed",
    "category_name_is_required":"Category name is required",
    //####################
    "please_select_vaccination":"Please select vaccination",
    "please_select_medication":"Please select medication",
    "here_you_can_enter_houses_number":"Here you can enter houses number",
    "enter_the_houses_are_here":"Enter the houses area here. It will be the same for all houses. In case you want to change it you can change it from farm management",
    "the_house_name_will_be_common":"The house name will be common addition to the house number",
    "SIDEBAR_TOUR_EXPLAIN":"Through this menu, you can navigate between the different sections of the system",
    "FARM_MANAGEMENT_EXPLAIN":"Form here you can access the farm management section",
    "ADMINISTRATION_EXPLAIN":"Form here you can access the administration section",
    "COSTS_EXPLAIN":"Form here you can access the financial section",
    "WIZARD_EXPLAIN":"Using wizard you can create a farm,houses and placements faster",
    "SETTINGS_EXPLAIN":"From here you can control system settings such as language and show/hide daily data sections",
    "CONFIG_FARM_DELIVERY_EXPLAIN":"You can control enabling or disabling farm deliveries from here. When deliveries are disabled, you can consume from the medications, vaccinations, or feed directly without the need to specify a specific batch number",
    "CONFIG_FINANCIAL_EXPLAIN":"You can control the display of the financial section from here",
    "CONFIG_DAILY_DATA_SECTION_EXPLAIN":"From here, you can control the display of each section of the daily data as needed",
    "DASHBOARD_SIDEBAR_EXPLAIN":"From here you can view the main dashboard and browse analysis and performance of all farms and statistics on the",
    "FARM_SUMMARIES_EXPLAIN": "From here, summaries and farm statistics are displayed",
    "FARM_CARD_EXPLAIN": "Here all the farms are displayed in addition to the age of the farm and loss percentage and whether it is the best farm or the worst farm",
    "CHART_EXPLAIN":"From here the graphs of the performance of all farms against the standards of the sedated strains are displayed",
    "farm_management_section":"Farm management section",
    "administration_section":"Administration_section",
    "financial_section":"Financial section",
    "wizard_section":"Wizard_section",
    "settings_section":"Settings section",
    "main_dashboard":"Main dashboard",
    "skip":"Skip",
    "loading":"Loading",
    "remain_quantity":"remain quantity",
    "your_message":"Your message",
    "DELIVERY_FEED_SECTION_EXPLAIN": "From here you can add feed deliveries by specifying the type batch number and quantity, also you can select a specific house which deliveries are meant to be associated with ",
    //"DELIVERY_ENERGY_SECTION_EXPLAIN": "From here you can add energy deliveries by specifying the type batch number and quantity, also you can select a specific house which deliveries are meant to be associated with ",
    "DELIVERY_OTHER_TYPE_SECTION_EXPLAIN": "From here you can add any other deliveries by specifying the type batch number and quantity, also you can select a specific house which deliveries are meant to be associated with ",
    "DELIVERY_VACCINATION_SECTION_EXPLAIN": "From here you can add vaccination deliveries by specifying the type batch number and quantity, also you can select a specific house which deliveries are meant to be associated with ",
    "DELIVERY_MEDICATION_SECTION_EXPLAIN": "From here you can add medication deliveries by specifying the type batch number and quantity, also you can select a specific house which deliveries are meant to be associated with ",

    "DAILY_DATE_TEMPERATURE_TITLE_EXPLAIN":"From here You can enter your daily house temperature",
    "DAILY_DATE_TEMPERATURE_SECTION_EXPLAIN":"The daily temperature of the house is the minimum and maximum temperature inside and outside the house.",
    "DAILY_DATE_HUMIDITY_SECTION_EXPLAIN":"Humidity information includes morning humidity, midday humidity and evening humidity inside the house.",
    "DAILY_DATE_CO2_SECTION_EXPLAIN":"Carbon dioxide information includes minimum, maximum and average carbon dioxide levels",
    "DAILY_DATE_FEED_INTAKE_SECTION_EXPLAIN":"You can add two different feed types for the day depending on the batches or feed types used. Example: If you are switching to another feed type or another batch after finishing the previous one.",
    "DAILY_DATE_WATER_INTAKE_SECTION_EXPLAIN":"You can also add the water consumption, acidity level (PH), and the oxidation reduction potential(ORP).",
    "DAILY_DATE_WEIGHT_SECTION_EXPLAIN":"Here you can add the average daily weight of the chicken, (weight is added in kilograms)",
    "DAILY_DATE_OTHER_TYPES_SECTION_EXPLAIN":"You can add the consumption of different resources in the same day (multiple times). The same batch can be used only once in the same day.You can change the consumption value of the same batch in ase you wan to use it again in the same day for the same house.",
    "DAILY_DATA_MEDICATION_NAME_EXPLAIN":"You can choose a medicine name (if not available, add a new one inside the administration)",
    "DAILY_DATA_MEDICATION_BATCH_EXPLAIN":"Choose a batch for this medicine",
    "DAILY_DATA_MEDICATION_QUANTITY_EXPLAIN":" Then you can  enter the medication consumption",
    "DAILY_DATA_MEDICATION_LIVE_BIRDS_EXPLAIN":"If the medicine will be used for all the house, you do not need to add the number of birds affected by the medication.",
    "DAILY_DATA_MEDICATION_APPLICATION_ROUTE_EXPLAIN":"After that you can choose the application route that you used to apply the medicine inside the house.",
    "DAILY_DATA_VACCINATION_NAME_EXPLAIN":"You can choose a vaccine name (if not available, add a new one inside the administration)",
    "DAILY_DATA_VACCINATION_BATCH_EXPLAIN":"Choose a batch for this vaccine",
    "DAILY_DATA_VACCINATION_QUANTITY_EXPLAIN":" Then you can  enter the vaccination consumption",
    "DAILY_DATA_VACCINATION_LIVE_BIRDS_EXPLAIN":"If the vaccination will be used for all the house, you do not need to add the number of birds affected by the vaccination.",
    "DAILY_DATA_VACCINATION_APPLICATION_ROUTE_EXPLAIN":"After that you can choose the application route that you used to apply the vaccine inside the house.",
    "DAILY_DATE_MORTALITY_SECTION_EXPLAIN":" You can add the number of chicken mortality and their weight (if applicable)",
    "DAILY_DATE_CULLS_SECTION_EXPLAIN":"You can add the number of chicken culled today and their weight (if applicable). - You can also choose the type of culls. If you don't have any types, you can add your own types from the administration.",
    "DAILY_DATE_SALES_SECTION_EXPLAIN":"You can write the number of chicken sold, price/kg, the total weight sold, and the sales type (you can add your own sales type from the administration) - You can add the sales multiple times per day depending on the number of times/types/prices you sold the chicken with.",
    "DAILY_DATE_UNACCOUNTABLE_SECTION_EXPLAIN":"Unaccountables are chicken sold without money either for charity, kitchen, or any other justification you need. - The number of chicken sold will be deducted from the live birds in that day.",
    "DAILY_DATE_COMMENTS_SECTION_EXPLAIN":"You can add multiple comments in the same day. These comments will be saved with the current cycle and house you are adding the comment in.",
    'age_in_days':"Age in days",
    'analysis':"Analysis",
    "feed_deliveries":"Feed Deliveries",
    "other_types_deliveries":"Other types Deliveries",
    "vaccination_deliveries":"Vaccination Deliveries",
    "medication_deliveries":"Medication Deliveries",
    "WIZARD_HOUSE_EXPLAIN": "Enter the house area from here. The area will be equal for all houses. In case you want to change the area of particular house, you can change it from the farm management",
    "WIZARD_HOUSE_NAME_EXPLAIN": "From here you can enter house name",
    "WIZARD_HOUSE_NUMBER_EXPLAIN": "You can enter number of houses from here",
    "WIZARD_PLACEMENT_DATE": "Here you can enter placement date",
    "WIZARD_PLACEMENT_QUANTITY": "You can enter the placement quantity from here, and it will be equal for all the houses.",
    "WIZARD_PLACEMENT_DEAD_ON_ARRIVAL_QUANTITY": "From here you can enter the placement dead on arrival. and it will be equal for all the houses , in case you want to change it for specific house,you can perform this from farm management",
    "WIZARD_PLACEMENT_BREED": "You can choose the placement breed from here, you can choose one of the previously defined standard breeds",
    "DAILY_DATE_INTAKE_SECTION_EXPLAIN": "",
    "DAILY_DATE_MEDICATION_SECTION_EXPLAIN": "Here, you can add the medication consumption for house with specified medication, batch number and quantity.",
    "DAILY_DATE_VACCINATION_SECTION_EXPLAIN": "Here, you can add the vaccination consumption for house with specified vaccine, batch number and quantity.",
    "terms_consent":"Creating an account means you're agreeing with with our ",
    "terms_of_services_and_our_privacy_policy":" terms of services and our privacy policy",
    "Term1":"The content of the pages of this website is for your general information and use only. It is subject to change without notice.",
    "Term2":"This website uses Google Analytics to monitor browsing preferences. If you do allow this to be used, some personal information may be stored by us.",
    "Term3":"We use cookies to collect anonymous visitor statistics through Google Analytics, to show personalized ads with Google AdSense, to save your editor settings and to detect if this is the first time you're visiting the site. Please read third party privacy policies for more details: Google Analytics, Google AdSense.",
    "Term4":"We show ads and might place randomly a link to the end of the cleaned documents.",
    "Term5":"Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.",
    "Term6":"Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.",
    "Term7":"This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the cleaning script, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.",
    "Term8":"All trademarks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.",
    "Term9":"Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.",
    "Term10":"From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).",
    "terms_and_conditions_privacy_policy":"Terms , conditions and privacy policy",
    "you_must_accept_the_terms_and_conditions":"You must accept the terms and conditions",
    "invalid_phone_number_format":"Invalid phone number format",
    "number":"Number",
    "terms_intro":"Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern HTML-Online's relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.",
    "term_sub":"This website is not collecting personal data, but we use third party tools that might do so. Please check their privacy policies for further information: Googla Analytics, Google AdSense, Google Mail, Facebook, Godaddy hosting.",
    "add_farm_and_placement": "Add farm and placement",
    "under_the_neck": "Under the neck",
    "drinking_water": "Drinking water",
    "occular": "Ocular",
    "intramuscular": "Intramuscular",
    "subcutaneous": "Subcutaneous",
    "spray": "Spray",
    "breast_injection": "Breast injection",
    "wing_injection": "Wing injection",
    "please_enter_cost_category": "Please enter cost category",
    "quantity_must_be_greater_than_0":"Quantity must be greater than 0",
    "DAILY_DATE_ENERGY_SECTION_EXPLAIN": "+++",
    "DELIVERY_ENERGY_SECTION_EXPLAIN": "+++",
    "energy_deliveries":"Energy deliveries",
    "phone_must_be_at_most_15_digits":"Phone must be at most 15 digits",
    "user_name_must_be_at_most_150_characters":"User name must be at most 150 characters",
    "name_must_be_at_most_150_characters":"Name must be at most 150 characters",
    "selling_date_must_be_later_than_today_date":"Selling date must be later than today date",
    "assumed_weight_must_be_less_than_or_equal_to_5":"Assumed weight must be less than or equal to 5",
    "quantity_must_be_less_than_or_equal_live_birds":"Quantity must be less than or equal live birds",
    "liter":"Liter",
    "DANGER_ZONE_TITLE":"Warning",
    "DANGER_ZONE_DETAILS":"By changing in this section you will change in the login credentials.",
    "active_user_status":"Active",
    "ph_max":"PH must be less than or equal to 14",
    "Free trial":"Free trial",
    "You are now using the free trial version, you can use the application for 30 days for free!": "You are now using the free trial version, you can use the application for 30 days for free!",
    "Continue": "Continue",
    "Try with demo data": "Try with demo data",
    "or": "Or",
    "total_cost" : "Total Cost",
}
