import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill';
import { icons } from './assets/icons';
import { Provider } from 'react-redux';
import { store } from './store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
import reportWebVitals from './reportWebVitals';
import './App.css'
import './i18n/i18n'

React.icons = icons

ReactDOM.render(
    <>
        <ServiceWorkerWrapper/>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();