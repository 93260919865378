import {
    faHome
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {BaseURL} from "../api/env";
import SecureLS from "secure-ls";
import i18n from "i18next";

export function create_navigation_links (_navSection , dynamic_routes , _nav , deliveries , deliveries_cost){
    let navigation_routes = [] ;

    for (let section_item of _navSection) {
        navigation_routes.push(section_item)
    }

    for (let item of _nav) {
        if(deliveries_cost){
            navigation_routes.push(item)
        }else{
            if(item.name === 'Reports' || item.name === "التقارير"){
                let newChildren =[]
                for (let obj of item._children){
                    if(obj.name != "Financial Report" && obj.name != "التقرير المالي"){
                        newChildren.push(obj)
                    }
                }
                const newItem = {_tag: 'CSidebarNavDropdown', name: i18n.t('reports'), to: '/management/wizard', icon: 'cil-newspaper', _children: newChildren }
                navigation_routes.push(newItem)
            }

            if(item.name !== 'Financials' && item.name !== 'Reports' && item.name != "الماليات" && item.name != "التقارير"){
                navigation_routes.push(item)
            }
        }
    }

    for(let farm of dynamic_routes){
        if (farm.has_active_houses){
            let farm_children = []
            if(deliveries) {
                farm_children.push({
                    _tag: 'CSidebarNavItem',
                    name: i18n.t('deliveries'),
                    to: `/farmDeliveries/${farm.farm_id}`,
                });
            }
            for(let house of farm.houses ){
                farm_children.push({
                    _tag: 'CSidebarNavItem',
                    name: `${house.house_name}`,
                    to: `/dailydata/${house.house_id}`,
                });
            }
            navigation_routes.push({ _tag: 'CSidebarNavDropdown',
                name: `${farm.farm_name}`,
                route: `${farm.farm_name}`,
                icon: <FontAwesomeIcon icon={faHome} className={"sidebar-icon"}/>,
                _children:farm_children
            });
        }
    }
    return navigation_routes;
}

export function getTodayDate(){
    const today= new Date();
    const month = ("0" + (today.getMonth() + 1)).slice(-2);
    const day = ("0" + today.getDate()).slice(-2);
    return today.getFullYear()+'-'+month+'-'+day;
}

export function convert_date(date){
    const today = new Date(date);
    const Date_text = today.getFullYear() + "-" + String(today.getMonth() + 1).padStart(2, '0') + "-" + String(today.getDate()).padStart(2, '0');
    return Date_text ;
}

export function get_string_date(string_date_obj){
    return  string_date_obj.Year + "-" + string_date_obj.Month + "-" + string_date_obj.Day ;
}

export function convert_date_to_string_format(date){
    const today = new Date(date);
    return { Day : String(today.getDate()).padStart(2, '0') , Month : String(today.getMonth() + 1).padStart(2, '0') , Year : today.getFullYear()};
}

export function convert_string_date(string_date_obj){
    const string_date_format = string_date_obj.Year + "-" + string_date_obj.Month + "-" + string_date_obj.Day ;
    return new Date(string_date_format);
}

export function convert_time(){
    const today = new Date();
    return today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds() ;
}

export function generate_random_ID() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
};

export const sanitizeInput=(value)=>{
    if (value == null)
        return ""
    else
        return value
}

export const checkFormEmptyIntValue=(value)=> {
    if (value === "")
        return 0
    else
        return value
}

export const divide_array_items = (array , seperatorValue) => {
    let newDividedArray = [];
    const divided_array_number = parseInt(array.length % seperatorValue) > 0 ? parseInt(array.length / seperatorValue) + 1 : parseInt(array.length / seperatorValue)
    for(let arraynumber = 0 ; arraynumber < divided_array_number ; arraynumber++){
        let newarrayItem = array.slice((arraynumber*seperatorValue) , ((seperatorValue*arraynumber)+seperatorValue));
        newDividedArray.push(newarrayItem)
    }
    return newDividedArray
}

export const DownloadFileAsPDF = (fileName , fileUrl , dispatch) => {

    const url = BaseURL + fileUrl ;
    const ls = new SecureLS({ encodingType: 'des', isCompression: true, encryptionSecret: '$_!exEcultive-aPp@data*2021.aBuerdAn$$' });
    const token = ls.get('UT')?.data;
    const language = localStorage.getItem('i18nextLng') || 'en' ;
    //Create XMLHTTP Request.
    var req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.setRequestHeader('Authorization' , `Token ${token}`);
    req.setRequestHeader("Accept-Language" , language);
    req.responseType = "blob";
    req.onload = function () {
        if (req.readyState === 4) {
            if (req.status === 200) {
                //Convert the Byte Data to BLOB object.
                if(/iphone|ipad|ipod/.test( navigator.userAgent.toLowerCase())){
                    var blob = new Blob([req.response], { type: "application/octetstream" });
                }else{
                    var blob = new Blob([req.response], { type: "application/pdf" });
                }
                //Check the Browser type and download the File.
                var isIE = false || !!document.documentMode;
                if (isIE) {
                    window.navigator.msSaveBlob(blob, fileName);
                } else {
                    var url = window.URL || window.webkitURL;
                    let link = url.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.setAttribute("download", fileName);
                    a.setAttribute("href", link);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            } else {
                dispatch({ type : "IS_GET_DOWNLOAD_REPORT_ERROR" , payload : true , errors : [[i18n.t("can't_download_report_right_now_try_again_later")],] })
            }
        }
    };
    req.send();
};

export const DownloadFileAsExcel = (fileName , fileUrl , dispatch) => {

    const url = BaseURL + fileUrl ;
    const ls = new SecureLS({ encodingType: 'des', isCompression: true, encryptionSecret: '$_!exEcultive-aPp@data*2021.aBuerdAn$$' });
    const token = ls.get('UT')?.data;
    const language = localStorage.getItem('i18nextLng') || 'en' ;
    //Create XMLHTTP Request.
    var req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.setRequestHeader('Authorization' , `Token ${token}`);
    req.setRequestHeader("Accept-Language" , language);
    req.responseType = "blob";
    req.onload = function () {
        if (req.readyState === 4) {
            if (req.status === 200) {
                //Convert the Byte Data to BLOB object.
                if(/iphone|ipad|ipod/.test( navigator.userAgent.toLowerCase())){
                    var blob = new Blob([req.response], { type: "application/octetstream" });
                }else{
                    var blob = new Blob([req.response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                }
                //Check the Browser type and download the File.
                var isIE = false || !!document.documentMode;
                if (isIE) {
                    window.navigator.msSaveBlob(blob, fileName);
                } else {
                    var url = window.URL || window.webkitURL;
                    let link = url.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.setAttribute("download", fileName);
                    a.setAttribute("href", link);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            } else {
                dispatch({ type : "IS_GET_DOWNLOAD_REPORT_ERROR" , payload : true , errors : [[i18n.t("can't_download_report_right_now_try_again_later")],] })
            }
        }
    };
    req.send();
};

export const get_configuarations_save_body = (request_body) => {
    let selected_sections = [] ;
    const all_daily_data_sections= {
        'temperature' : { "value": 'temperature', "label": "Temperature" },
        'humidity' : { "value": 'humidity', "label": "Humidity" },
        'intake' : { "value": 'intake', "label": "Intake" },
        'carbon_dioxde' : { "value": 'carbon_dioxde', "label": "CO2" },
        'weight' : { "value": 'weight', "label": "Weight" },
        'energy_consumption' : { "value": 'energy_consumption', "label": "Energy Source"},
        'other_type_consumption' : { "value": 'other_type_consumption', "label": "Other Type Consumption" },
        'vaccination' : { "value": 'vaccination', "label": "Vaccination" },
        'medication' : { "value": 'medication', "label": "Medication" },
        'culls' : { "value": 'culls', "label": "Culls" },
        'mortality' : { "value": 'mortality', "label": "Mortality" },
        'unaccountables' : { "value": 'unaccountables', "label": "Unaccountable" },
        'sales' : { "value": 'sales', "label": "Sales" },
        'comments' : { "value": 'comments', "label": "Comments" },
    };

    for(let section of request_body){
        selected_sections.push(all_daily_data_sections[section['value']])
    }

    return selected_sections ;
}

export const trimFirstZero = (values) => {
    if(values.hasOwnProperty('country_code')){
        if(values.country_code.toLowerCase()==='eg' && values.phone.length === 11)
        {
            values.phone=values.phone.substring(1)
            return values
        }
        return values
    }
    throw new Error('Error while trimming first zero from phone')
}