import Types from "../../actions/Types";

export const vaccination_types_list = (state = {vaccinations:[],isLoaded:false} , action ) => {
    switch (action.type) {
        case Types.GET_ALL_LOOKUPS :
            return {...state,vaccinations:action.payload.vaccination_names};

        case Types.GET_ALL_VACCINATION_TYPES:
            return {...state,vaccinations:action.payload,isLoaded:true};

        case Types.IS_GET_VACCINATION_TYPE_LOADING:
            return {...state,isLoaded: action.payload}

        default:
            return state;
    }
}

export const vaccination_type = (state = [] , action ) => {
    switch(action.type){
        case Types.GET_VACCINATION_TYPE_DATA :
            return [...state , action.payload]
        default :
            return state ;
    }
}

export const vaccination_type_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_VACCINATION_TYPE_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}

export const vaccination_type_data_error = (state = { visiable : false , errors : [] }  , action ) => {
    switch(action.type){
        case Types.IS_GET_VACCINATION_TYPE_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}