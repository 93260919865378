import React from "react";
import {CCard,CCardFooter,CCardBody,CCardHeader,CListGroupItem,CListGroup,CButton} from "@coreui/react";
import {useTranslation} from "react-i18next";
import {Link, useHistory, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare} from "@fortawesome/free-solid-svg-icons";

const Terms = (props) => {
    const {t} = useTranslation()
    const Location = useLocation()
    const  History  = useHistory()
    return (<>
        <CCard className={"m-0 rounded-0 border-0"} >
            <CCardHeader style={{"padding":"40px"}} className={"bg-primary text-white"}>
                <h2 className={"text-center"}>{ t('terms_and_conditions_privacy_policy') }</h2>
            </CCardHeader>
            <CCardBody className={"p-5"}>
                <h4 className={"mb-5 d-block"}>{ t ('terms_intro') }</h4>
                <h5 className={"p-3 border-bottom"}>
                <strong>{ t('term_sub') }</strong>
                </h5>
                <CListGroup>
                    {Array.from(Array(10), (e, i) =>


                        <CListGroupItem key={i} className={"d-flex align-items-start p-3 border-0 "} style={ {gap: "10px"}}>
                            <FontAwesomeIcon icon={faCheckSquare}/>
                            <span style={{marginTop:"-5px"}}>{ t ('Term'+(i+1)) }</span>
                        </CListGroupItem>)
                    }

                </CListGroup>
            </CCardBody>
            <CCardFooter>
                <CButton className={"btn btn-primary"} onClick={()=>{
                    History.push({
                        pathname:'register',
                        state:Location.state

                                 })
                }}>{ t('back') }</CButton>
            </CCardFooter>
        </CCard>

    </>)

}
export default Terms