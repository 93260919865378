import Types from "../../actions/Types";

export const other_type_list =(state ={other:[],isLoaded:false},action)=>{
    
    switch (action.type) {
        case Types.GET_ALL_LOOKUPS :
            return {...state,other:action.payload.other_types};

        case Types.GET_ALL_OTHER_TYPES:
            return {...state,other: action.payload,isLoaded: true};
        case Types.IS_GET_OTHER_TYPES_LOADING:
            return {...state,isLoaded: action.payload}

        default:
            return state;
    }
}

export const other_type_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_OTHER_TYPE_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}
export const other_type_data_error = (state  = { visiable : false , errors : [] }  , action ) => {
    switch(action.type){
        case Types.IS_GET_OTHER_TYPE_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}


