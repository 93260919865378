import Types from "../../actions/Types";

const Init_state = { alert_msg : false , loading : "no request" } ;

export const serverErrorForm = (state = Init_state , action ) => {
    switch(action.type){

        case Types.SET_SUCCESS_MSG_IN_SERVER_ERROR_PAGE :
            return {...state , alert_msg : action.payload };

        case Types.SET_SERVER_ERROR_EMAIL_LOADING_REQUEST :
            return {...state , loading: action.payload };

        default :
            return state ;
    }
};