/**
 * Reducer for hatchery
 */
import Types from "../../actions/Types";
let initialState = {
    name: '',
    code: '',
}
export const hatchery_list = (state = {hatcheries:[],isLoaded:false} , action ) => {
    switch(action.type){
        case Types.GET_ALL_HATCHERIES:
            return {...state,hatcheries: action.payload,isLoaded: true} ;
        case Types.IS_GET_HATCHER_LOADING:
            return {...state , isLoaded: action.payload}
        default :
            return state ;
    }
}
export const hatchery_data = (state = initialState , action ) => {
    switch(action.type){
        case Types.GET_HATCHERY_DATA :
            return {...state,...action.payload}
        default :
            return state ;
    }
}
export const hatchery_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_HATCHERY_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}
export const hatchery_data_error = (state = { visiable : false , errors : [] }  , action ) => {
    switch(action.type){
        case Types.IS_GET_HATCHERY_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}