import React from 'react'
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react'

const Modals = (props) => {

  const do_modal_action = () => {
        props.confirm_action()
        props.setmodalVisiable(false)
  }

  return (
            <CModal
              show={props.modalVisiable}
              onClose={() => props.setmodalVisiable(false)}
              size={props.ModalSize}
              color={props.color}
              centered={props.centered}
            >
              <CModalHeader closeButton>
                <CModalTitle>{props.ModalTitle}</CModalTitle>
              </CModalHeader>
              <CModalBody>
                {props.modalmessage}
              </CModalBody>
              <CModalFooter>
                <CButton style={props.confirm_style} color={props.confirm_color} onClick={do_modal_action}>{props.confirm_text}</CButton>{' '}
                <CButton style={props.cancel_style} color={props.cancel_color} onClick={() => props.setmodalVisiable(false)}>{props.cancel_text}</CButton>
              </CModalFooter>
            </CModal>
  )
}

export default Modals
