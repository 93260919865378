import Types from "../../actions/Types";

export const login = (state = true, action) => {
  switch (action.type) {
    case Types.LOGIN:
      return action.payload
    case Types.CLEAR_LOGIN:
      return null
    default:
      return state;
  }
};

export const user_role = (state = "admin", action) => {
  switch (action.type) {
    case Types.SET_USER_MODEL:
      return action.payload
    default:
      return state;
  }
};

export const register = (state = true, action) => {
  switch (action.type) {
    case Types.LOGIN:
      return action.payload
    case Types.CLEAR_LOGIN:
      return null
    default:
      return state;
  }
};

export const get_otp_page_data_error = (state = { visiable : false , errors : [] }  , action ) => {
  switch(action.type){
    case Types.IS_GET_SMS_CODE_ERROR :
      return {...state , visiable : action.payload , errors : action.errors } ;
    default :
      return state ;
  }
}

export const personal_data = (state={personal_info:''},action)=>{
  switch (action.type) {
    case Types.GET_USER_PERSONAL_INFO:
      return {...state,personal_info: action.payload}
    default:
      return state

  }
}

export const geo_info = (state={country_code : ""},action)=>{
  switch (action.type) {
    case Types.GET_GEO_INFO:
      return {...state,country_code:action.payload.location.country.code}

    default:
      return state
  }
}