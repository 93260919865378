import Types from "../../actions/Types";
let initialState=
    {
        name:''
    }
export const feed_type_list =(state ={feed:[],isLoaded:false},action)=>{
    switch (action.type) {
        case Types.GET_ALL_LOOKUPS :
            return {...state,feed:action.payload.feed_types};

        case Types.GET_ALL_FEED_TYPES:
            return {...state,feed:action.payload,isLoaded:true};
        case Types.IS_GET_FEED_TYPE_LOADING:
            return {...state,isLoaded:action.payload};
        default:
            return state;
    }
}
export const feed_type = (state = initialState , action ) => {
    switch(action.type){
        case Types.GET_FEED_TYPE_DATA :
            return  action.payload
        default :
            return state ;
    }
}
export const feed_type_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_FEED_TYPE_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}
export const feed_type_data_error = (state  = { visiable : false , errors : [],initialState }  , action) => {
    switch(action.type){
        case Types.IS_GET_FEED_TYPE_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}

