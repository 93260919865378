import Types from "../../actions/Types";

export const farms_and_houses = ( state = [] , action ) => {
    switch (action.type){
        case Types.GET_ALL_FARMS_HOUSES :
            return action.payload ;
        default :
            return state ;
    }
}

export const farms_and_cycles = ( state = [] , action ) => {
    switch (action.type){
        case Types.GET_ALL_FARMS_CYCLES :
            return action.payload ;
        default :
            return state ;
    }
}

export const tips = ( state = [] , action ) => {
    switch (action.type){
        case Types.GET_ALL_TIPS :
            return action.payload ;

        case Types.SHOW_ALL_TIPS :
            return state.map(tipObj => { return {...tipObj, show : true }})

        case Types.HIDE_SELECTED_TIP :
            return state.map(tipObj => tipObj.id.toString() === action.payload.toString() ? { ...tipObj, show : false } : tipObj);

        default :
            return state ;
    }
}

export const no_tips = ( state = {show : true } , action ) => {
    switch (action.type){

        case Types.SHOW_ALL_TIPS :
            return { ...state , show: true } ;

        case Types.HIDE_NO_TIP_MSG :
            return { ...state , show: false } ;

        default :
            return state ;
    }
}

export const all_houses = ( state = [] , action ) => {
    switch (action.type) {
        case Types.GET_ALL_LOAD_DATA :
            return action.payload.houses ;
        default :
            return state ;
    }
}

export const governorates = ( state = [] , action ) => {
    switch (action.type){
        case Types.GET_ALL_LOOKUPS :
            return action.payload.governorates ;
        default :
            return state ;
    }
}

export const countries = ( state = [] , action ) => {
    switch (action.type){
        case Types.GET_ALL_COUNTRIES :
            return action.payload ;
        default :
            return state ;
    }
}

export const network_state = (state = true , action) => {
    switch (action.type) {
        case Types.CHANGE_NETWORK_STATE :
            return action.payload
        default :
            return state
    }
}