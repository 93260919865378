import React, {useEffect} from "react";
import CIcon from "@coreui/icons-react";
import {CInput, CInvalidFeedback} from "@coreui/react";

import {useHistory} from "react-router-dom";
import {Formik} from "formik";
import * as Yup from "yup";
import getData from "../../../api/getData";
import {useTranslation} from "react-i18next";

function ResetPassword(props) {
    const {t} = useTranslation()
    let history = useHistory()
    const getToken=()=>{
        return window.location.search.split('token=')[1]
    }
    useEffect(()=>{
        require('../../../scss/registration.scss')
        require('../../../scss/setter.scss')
    })
    const validationSchema = Yup.object().shape({
        password: Yup.string().required(t('password_is_required')),
        passwordConfirmation:Yup.string().oneOf([Yup.ref('password'),null],t('passwords_must_match')).required(t('password_confirmation_is_required'))
    });
    const sendResetPasswordCreateRequest=(values)=>{
       getData.post('broilers/customer/reset_password/confirm',values).then(res=> {

            if (res.status===200)
            {
                history.push('/login')
            }
        }).catch(err=>console.error(err))
    }
    const prepareRequestBody=(values)=>{
        return {
            password: values.password,
            confirm_password: values.passwordConfirmation,
            token: getToken(),
        }
    }
    const handleSubmit=(values)=>{
        sendResetPasswordCreateRequest(prepareRequestBody(values))
    }
    return (
        <Formik initialValues={
            {
                password:'',
                passwordConfirmation:''
            }
        } onSubmit={handleSubmit}
                validationSchema={validationSchema}
        >
            {({ handleChange , handleSubmit , handleBlur , errors , values , setFieldValue , touched }) => (
                <>

                    <div className="container  vh-100  d-flex justify-content-center align-items-center ">
                        <div className="row registration-wrapper ">
                            <div className="col m-2">
                                <div className="card m-0">
                                    <div className="card-header  d-flex justify-content-center">
                                        <CIcon
                                            name="logo-negative"
                                            width={135}
                                            src={'/avatars/appLogoBlack.svg'}
                                        />
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className={"mb-3 mt-3 d-flex flex-column"}>
                                            <div>
                                                <strong >{t('resent_account_password')}</strong>
                                            </div>
                                            <small className={"text-gray-100"}>{t('enter_new_password_and_confirm_password')}</small>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className={"form-row"} >
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label htmlFor="password">{t('new_password')}</label>
                                                        <CInput
                                                            type="password"
                                                            className="form-control text-body"
                                                            id="password"
                                                            name="password"
                                                            value={values.password}
                                                            invalid={touched.password && !!errors.password}
                                                            aria-describedby="emailHelp"
                                                            placeholder={t('password')}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                        <CInvalidFeedback>{errors.password}</CInvalidFeedback>

                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label htmlFor="passwordConfirmation">{t('confirm_password')}</label>
                                                        <CInput
                                                            type="password"
                                                            className="form-control text-body"
                                                            id="passwordConfirmation"
                                                            name="passwordConfirmation"
                                                            value={values.passwordConfirmation}
                                                            invalid={touched.passwordConfirmation && !!errors.passwordConfirmation}
                                                            onChange={handleChange}                                                            aria-describedby="emailHelp"
                                                            placeholder={t('confirm_password')}
                                                            onBlur={handleBlur}

                                                        />
                                                        <CInvalidFeedback>{errors.passwordConfirmation}</CInvalidFeedback>

                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary w-100 mt-1">{t('save')}</button>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    </>
                )}
        </Formik>

    )
}

export default ResetPassword