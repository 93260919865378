/**
 * Reducer for direct cost
 */
import Types from "../../actions/Types";

export const direct_cost_list =(state = {cost:[],isLoaded:false},action)=>{
    switch (action.type) {
        case Types.GET_ALL_DIRECT_COSTS:
            return {...state,cost:action.payload,isLoaded:true};
        case Types.IS_DIRECT_COST_LOADING :
            return {...state,isLoaded: action.payload};
        default:
            return state;
    }
}

export const direct_cost_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_DIRECT_COST_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}

export const direct_cost_data_error = (state  = { visiable : false , errors : [] }  , action) => {
    switch(action.type){
        case Types.IS_GET_DIRECT_COST_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}
