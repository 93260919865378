import React, { useEffect, useState } from 'react';
import styles from "./styles.module.scss";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { CToaster, CToast, CToastBody, CToastHeader, CButton } from '@coreui/react';
import { useLocation } from 'react-router-dom'

function FreeTrialToast({ isLoggedIn }) {
    const { t } = useTranslation()
    const [toastIsOpen, setToastIsOpen] = useState(false)
    const location = useLocation();
    const token = localStorage.getItem('UT')
    const plan = localStorage.getItem('plan')

    useEffect(() => {
        if (isLoggedIn && token && !plan) {
            setToastIsOpen(true)
        }
    }, [location.pathname])


    const onContinue = () => {
        setToastIsOpen(false)
        localStorage.setItem("plan", "free")
    }

    return (
        <CToaster position={`${i18n.language === "ar" ? "top-left" : "top-right"}`}>
            <CToast show={toastIsOpen} autohide={false} fade={true} className={styles.freeTrialToast}>
                <CToastHeader closeButton={false} className={styles.freeTrialToast__head}>
                    {t("Free trial")}
                </CToastHeader>
                <CToastBody className={styles.freeTrialToast__body}>
                    <h5 className={styles.freeTrialToast__body__text}>
                        {t("You are now using the free trial version, you can use the application for 30 days for free!")}
                    </h5>
                    <div className={styles.freeTrialToast__body__btn}>
                        <CButton type="submit" color="info" onClick={onContinue}>{t("Continue")}</CButton>
                    </div>
                </CToastBody>
            </CToast>
        </CToaster>
    )
}

export default FreeTrialToast