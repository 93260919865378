/**
 * Reducer for standard
 */
import Types from "../../actions/Types";

export const standard_list = (state = [] , action ) => {
    switch(action.type){
        case Types.GET_ALL_STANDARDS:
            return action.payload ;
        default :
            return state ;
    }
}

export const get_standard_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_STANDARD_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}
export const get_standard_data_error = (state = { visiable : false , errors : [] }  , action ) => {
    switch(action.type){
        case Types.IS_GET_STANDARD_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}