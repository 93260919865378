import Types from "../../actions/Types";

export const feedDeliveries = (state= [] , action) => {
    switch (action.type){
        case Types.GET_ALL_LOAD_DATA :
            state = action.payload.deliveries.feed_delivery ;
            return state ;

        case Types.GET_ALL_FEED_DELIVERIES :
            state = action.payload ;
            return state ;

        default :
            return state ;

    }
}

export const get_feed_delivery_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_FEED_DELIVERY_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}

export const get_feed_delivery_data_error = (state = { visiable : false , errors : [] }  , action ) => {
    switch(action.type){
        case Types.IS_GET_FEED_DELIVERY_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}

export const get_feed_delivery_loading = (state = false , action)=> {
    switch (action.type) {
        case Types.IS_GET_FEED_DELIVERY_LOADING :
            return action.payload
        default :
            return  state
    }
}