import Types from "../../actions/Types";
let initialState={
    id: '',
    name: '',

}
export const sales_type_list =(state = {salesTypes:[],isLoaded:false},action)=>{
    switch (action.type) {
        case Types.GET_ALL_LOOKUPS :
            return {...state,salesTypes:action.payload.sales_type};
        case Types.GET_ALL_SALES_TYPES:
            return {...state,salesTypes:action.payload,isLoaded:true};
        case Types.IS_GET_SALES_TYPE_LOADING:
            return {...state,isLoaded: action.payload}
        default:
            return state;
    }
}
export const sales_type = (state = initialState , action ) => {
    switch(action.type){
        case Types.GET_SALES_TYPE_DATA :
            return  action.payload
        default :
            return state ;
    }
}
export const sales_type_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_SALES_TYPE_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}
export const sales_type_data_error = (state  = { visiable : false , errors : [],initialState }  , action) => {
    switch(action.type){
        case Types.IS_GET_SALES_TYPE_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}


