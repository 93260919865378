import requester from "../../../api/requester";
import Types from "../Types";
import {GeoInfoAPIKey} from "../../../api/env";


export const get_all_farms_houses = (selected_date) => async dispatch => {
    await requester()
        .get(`broilers/farm_houses?selected_date=${selected_date}`)
        .then((response) => {
            if (response) {
                dispatch({
                    type: Types.GET_ALL_FARMS_HOUSES,
                    payload: response.data,
                });
            }
        })
        .catch((error) => {
            console.log(error, "error message");
        });
};

export const get_all_farms_cycles = () => async dispatch => {
    await requester()
        .get(`broilers/farm_cycles`)
        .then((response) => {
            if (response) {
                dispatch({
                    type: Types.GET_ALL_FARMS_CYCLES,
                    payload: response.data,
                });
            }
        })
        .catch((error) => {
            console.log(error, "error message");
        });
};

export const get_all_tips = () => async dispatch => {
    await requester()
        .get(`broilers/tips`)
        .then((response) => {
            if (response) {
                dispatch({
                    type: Types.GET_ALL_TIPS,
                    payload: response.data,
                });
            }
        })
        .catch((error) => {
            console.log(error, "error message");
        });
};

export const show_all_tips = () => async dispatch => {
    dispatch({
        type : Types.SHOW_ALL_TIPS ,
    })
}

export const hide_selected_tip = (tip_Id) => async dispatch => {
    dispatch({
        type : Types.HIDE_SELECTED_TIP ,
        payload : tip_Id
    })
}

export const hide_no_tip_msg = () => async dispatch => {
    dispatch({
        type : Types.HIDE_NO_TIP_MSG ,
    })
}

export const get_all_lookups = () => async dispatch => {
    await requester()
        .get('lookups/')
        .then((response) => {
            if (response) {
                dispatch({
                    type: Types.GET_ALL_LOOKUPS,
                    payload: response.data,
                });
            }
        })
        .catch((error) => {
            console.log(error, "error message");
        });
};

export const get_all_countries = () => async dispatch => {
    await requester()
        .get('lookups/country')
        .then((response) => {
            if (response) {
                dispatch({
                    type: Types.GET_ALL_COUNTRIES,
                    payload: response.data,
                });
            }
        })
        .catch((error) => {
            console.log(error, "error message");
        });
};

export const get_all_loadData = (selected_date) => async dispatch => {
    dispatch({ type : Types.APP_LOADING_DATA , payload : true });
    await requester()
        .get(`dailydata/load_data?selected_date=${selected_date}`)
        .then((response) => {
            if (response) {
                dispatch({
                    type: Types.GET_ALL_LOAD_DATA,
                    payload: response.data,
                });
            }
            dispatch({ type : Types.APP_LOADING_DATA , payload : false });
        })
        .catch((error) => {
            console.log(error, "error message");
            dispatch({ type : Types.APP_LOADING_DATA , payload : false });
        });
};

export const change_network_state = network => {
    return { type : Types.CHANGE_NETWORK_STATE , payload : network } ;
}

export const get_geo_info = () => async dispatch => {
    await requester()
        .get(`https://api.ipregistry.co/?key=${GeoInfoAPIKey}`)
        .then((response) => {
            if (response) {
                dispatch({
                     type: Types.GET_GEO_INFO,
                     payload: response.data,
                 });
            }
        })
        .catch((error) => {
            console.log(error, "error message");
        });
}


