const initialState = {
  sidebarShow: 'responsive'
}
let registrationData= {
  name:'',
  companyName:'',
  email:'',
  password:'',
  passwordConfirmed:'',
  number_broilers_year:'',
  country:'0',
  phone:''
}
export const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}
