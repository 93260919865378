import React from "react";
import i18n from "../../i18n/i18n";
export function AuthLanguageToggle(props) {
    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
        window.location.reload()
    };
    return (<>
        <div className={"d-flex justify-content-center align-items-center"}>
            <span className={` hover-cursor ${i18n.language!=='ar' ? 'text-gray-100 ' : ''}` } onClick={()=>{changeLanguage("ar")}}><u>العربية</u></span>
            <span className={"mr-2 ml-2"}>.</span>
            <span  className={` hover-cursor ${i18n.language!=='en' ? 'text-gray-100' : ''}`} onClick={()=>{changeLanguage("en")}}><u>English</u></span>
        </div>

        </>)
}
export default AuthLanguageToggle
