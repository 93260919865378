import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import {arabic} from "./locales/ar";
import {english} from "./locales/en";
import detectBrowserLanguage from "detect-browser-language";

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    resources: {
        ar: {translation: arabic},
        en: {translation: english},
    },

    fallbackLng: detectBrowserLanguage().includes('en') ? 'en':'ar',
    debug: false,
    detection: {
        order: ['localStorage','cookie' ,'queryString'],
        cache: ['localStorage','cookie']
    },
    interpolation: {
        escapeValue: false
    }
})
export default i18n

