import Types from "../../actions/Types";
import i18n from "../../../i18n/i18n";

const daily_data_sections = [
        { "value": 'temperature', "label": i18n.t('temperature') },
        { "value": 'humidity', "label": i18n.t('humidity') },
        { "value": 'intake', "label": i18n.t('intake') },
        { "value": 'carbon_dioxde', "label": i18n.t('Co2') },
        { "value": 'weight', "label": i18n.t('weight') },
        { "value": 'energy_consumption', "label": i18n.t('energy_source') },
        { "value": 'other_type_consumption', "label": i18n.t('other_type_consumption') },
        { "value": 'vaccination', "label": i18n.t('vaccination') },
        { "value": 'medication', "label": i18n.t('medication') },
        { "value": 'culls', "label": i18n.t('culls') },
        { "value": 'mortality', "label": i18n.t('mortality') },
        { "value": 'unaccountables', "label": i18n.t('unaccountables') },
        { "value": 'sales', "label": i18n.t('sales') },
        { "value": 'comments', "label": i18n.t('comments') },
];

const Init_state = {  deliveries : true , deliveries_cost : true , selected_daily_data_sections : daily_data_sections , all_daily_data_sections : daily_data_sections , alert_msg : 0 , error_alert_msg : 0 , loading : "no request" } ;

export const configurationForm = (state = Init_state , action ) => {
    switch(action.type){

        case Types.GET_ALL_CONFIGURATIONS :
            return {...state , id : action.payload.id , deliveries: action.payload.is_using_delivery , deliveries_cost : action.payload.financials  , selected_daily_data_sections : action.payload.daily_data_selected_sections , all_daily_data_sections : daily_data_sections } ;

        case Types.SAVE_CONFIGURATIONS_FORM :
            return {...state , deliveries: action.payload.deliveries , deliveries_cost : action.payload.deliveries_cost  , selected_daily_data_sections : action.payload.selected_sections } ;

        case Types.RESET_CONFIGURATIONS_FORM :
            return {...state , deliveries: Init_state.deliveries , deliveries_cost : Init_state.deliveries_cost  , selected_daily_data_sections : Init_state.selected_daily_data_sections , alert_msg : Init_state.alert_msg , error_alert_msg :Init_state.error_alert_msg , loading : Init_state.loading } ;

        case Types.SET_CONFIGUARTION_SUCCESS_MSG :
            return {...state , alert_msg : action.payload };

        case Types.SET_CONFIGUARTION_ERROR_MSG :
            return {...state , error_alert_msg : action.payload };

        case Types.SET_CONFIGUARTION_LOADING_REQUEST :
            return {...state , loading: action.payload };
        default :
            return state ;
    }
};