/**
 * Reducer for Placement
 */
import Types from "../../actions/Types";
let initialState = {
    id:'',
    placement_date: '',
    quantity_as_hatched:'',
    dead_on_arrival_as_hatched:'',
    fk_house:'',
    fk_cycle:'',
    fk_hatchery:''
}
export const placement_list = (state = {placements: [],isLoaded:false} , action ) => {
    switch(action.type){
        case Types.GET_ALL_PLACEMENTS:
            return {...state,placements: action.payload,isLoaded: true} ;
        case Types.IS_GET_PLACEMENT_LOADING:
            return {...state,isLoaded: action.payload} ;
        default :
            return state ;
    }
}
export const placement_data = (state = initialState , action ) => {
    switch(action.type){
        case Types.GET_PLACEMENT_DATA :
            return {...state , ...action.payload}
        default :
            return state ;
    }
}
export const get_placement_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_PLACEMENT_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}
export const get_placement_data_error = (state = { visiable : false , errors : [] }  , action ) => {
    switch(action.type){
        case Types.IS_GET_PLACEMENT_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}