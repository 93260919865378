/**
 * Reducer for cycle
 */
import Types from "../../actions/Types";

export const breed_list = (state = {breeds:[],isLoaded:false} , action ) => {
    switch(action.type){
        case Types.GET_ALL_LOOKUPS :
            return {...state,breeds:action.payload.breeds};

        case Types.GET_ALL_BREEDS:
            return {...state,breeds: action.payload,isLoaded: true} ;

        case Types.IS_GET_BREED_LOADING :
            return {...state,isLoaded: action.payload};

        default :
            return state ;
    }
}

export const breed_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_BREED_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}

export const breed_data_error = (state = { visiable : false , errors : [] }  , action ) => {
    switch(action.type){
        case Types.IS_GET_BREED_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}