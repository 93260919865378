import React, { useEffect } from 'react';
import { Route, Switch , Redirect , useHistory } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import './scss/style.scss';
import './scss/custom.scss'
import './App.css'
import ResetEmailPassword from "./views/registeration/PasswordReset/ResetMailPassword";
import ResetPassword from "./views/registeration/PasswordReset/ResetPassword";
import {Detector} from "react-detect-offline";
import {change_network_state} from "./redux/actions/lookups";
import { connect } from "react-redux";
import {sync_offline_data} from "./redux/actions/dailydata";
import Terms from "./static/Terms";
import FreeTrialToast from './reusable/freeTrialToast';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/registeration/loginPage'));
const Registration = React.lazy(() => import('./views/registeration/RegistrationPage'));
const SmsVerification = React.lazy(() => import('./views/registeration/smsVerificationPage'));

function App(props){

    const token = localStorage.getItem('UT')
    const language = localStorage.getItem("i18nextLng") || "en" ;
    const history = useHistory();

    if(language.includes("en")){
        document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    }else{
        document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    }

    document.addEventListener('touchmove', function (event) {
        if (event.scale !== 1) { event.preventDefault(); }
    }, false);

    document.addEventListener('gesturestart', function(e) {
        e.preventDefault();
        // special hack to prevent zoom-to-tabs gesture in safari
        document.body.style.zoom = 0.99;
    });

    document.addEventListener('gesturechange', function(e) {
        e.preventDefault();
        // special hack to prevent zoom-to-tabs gesture in safari
        document.body.style.zoom = 0.99;
    });

    document.addEventListener('gestureend', function(e) {
        e.preventDefault();
        document.body.style.zoom = 0.99;
    });

    useEffect(() => {
        window.addEventListener('keydown',function(e){if(e.keyIdentifier=='U+000A'||e.keyIdentifier=='Enter'||e.keyCode==13){if(e.target.nodeName=='INPUT'&&e.target.type=='text'){e.preventDefault();return false;}}},true);
        if(navigator?.serviceWorker?.getRegistrations) {
            history.listen((location, action) => {
                // check for sw updates on page change
                navigator.serviceWorker
                    .getRegistrations()
                    .then((regs) => regs.forEach((reg) => reg.update()));
            });
        }
    }, []);

    return (
        <>
            <Detector onChange={(online)=>{
                props.change_network_state(online);
                if(online){
                    // props.sync_offline_data()
                }
            }}
                      render={({ online }) => (
                          <></>
                      )}
            />

            <>
                <FreeTrialToast isLoggedIn={props.isLoggedIn} />
                <React.Suspense fallback={loading}>
                    <Switch>
                        <Route exact path="/login">
                            {props.isLoggedIn && token ? <Redirect to="/" /> : <Login />}
                        </Route>
                        <Route exact path="/register">
                            {props.isLoggedIn && token ? <Redirect to="/" /> : <Registration />}
                        </Route>
                        <Route exact path="/smsVerify">
                            {props.isLoggedIn && token ? <Redirect to="/" /> : <SmsVerification />}
                        </Route>
                        <Route exact path="/resetpassword/">
                            {props.isLoggedIn && token ? <Redirect to="/" /> : <ResetPassword/>}
                        </Route>
                        <Route exact path="/passwordresetmail">
                            {props.isLoggedIn && token ? <Redirect to="/" /> : <ResetEmailPassword/>}
                        </Route>
                        <Route exact path="/terms">
                            {props.isLoggedIn && token ? <Redirect to="/" /> : <Terms/>}
                        </Route>
                        <ProtectedRoute path="/" name="Home" component={TheLayout} />
                    </Switch>
                </React.Suspense>
            </>
        </>
    );
}

const mapStateToProps = state => {
    return { isLoggedIn: state.login } ;
}

export default connect(mapStateToProps , {change_network_state , sync_offline_data})(App);