import React, { useEffect } from 'react';
import * as serviceWorker from './serviceWorkerRegistration';
import Modals from "./reusable/modal";
import {useTranslation} from "react-i18next";

const ServiceWorkerWrapper = () => {

    const{t} = useTranslation()
    const [showReload, setShowReload] = React.useState(false);
    const [waitingWorker, setWaitingWorker] = React.useState(null);
    const model_confirm_button_custom_style = { display:'block' } ;
    const model_cancel_button_custom_style = { display:'block' } ;

    const onSWUpdate = (registration) => {
        console.log(registration, "registration update");
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    useEffect(() => {
        serviceWorker.register({ onUpdate: onSWUpdate });
    }, []);

    const reloadPage = () => {
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        window.location.reload();
    };

    return (
        <Modals ModalTitle={t("New version is available")} ModalSize="lg" color="secondary" confirm_color="secondary" cancel_color="secondary" confirm_text={t('update')} cancel_text={t("cancel")} confirm_style={model_confirm_button_custom_style} cancel_style={model_cancel_button_custom_style} confirm_action={reloadPage} modalmessage={t("A new version is available, Install Now to get the new version")} modalVisiable={showReload} setmodalVisiable = {setShowReload} />
    );
}

export default ServiceWorkerWrapper;


// Install Now