const Types = {
  //auth
  GET_USER_DATA:"GET_USER_DATA",
  CLEAR_GET_USER_DATA:"CLEAR_GET_USER_DATA",
  LOGIN:"LOGIN",
  REGISTER:"REGISTER",
  CLEAR_LOGIN:"CLEAR_LOGIN",
  LOGOUT:"LOGOUT",
  SET_USER_MODEL : "SET_USER_MODEL" ,
  IS_GET_REGISTERATION_DATA_ERROR : "IS_GET_REGISTERATION_DATA_ERROR" ,
  IS_GET_SMS_CODE_ERROR : "IS_GET_SMS_CODE_ERROR" ,
  VERIFY_TOKEN : "VERIFY_TOKEN" ,
  GET_USER_PERSONAL_INFO:"GET_USER_PERSONAL_INFO",
  GET_GEO_INFO:"GET_GEO_INFO",

  //wizard
  FARMS_WIZARD_FORM_SUBMITTED : "FARMS_WIZARD_FORM_SUBMITTED",
  HOUSES_WIZARD_FORM_SUBMITTED : "HOUSES_WIZARD_FORM_SUBMITTED",
  PLACEMENTS_WIZARD_FORM_SUBMITTED : "PLACEMENTS_WIZARD_FORM_SUBMITTED",
  IS_GET_WIZARD_DATA_ERROR : "IS_GET_WIZARD_DATA_ERROR" ,
  CLEAR_WIZARD_FORM_DATA : "CLEAR_WIZARD_FORM_DATA" ,
  BACK_TO_FARM_WIZARD_FORM : "BACK_TO_FARM_WIZARD_FORM",
  BACK_TO_HOUSE_WIZARD_FORM : "BACK_TO_HOUSE_WIZARD_FORM",

  //Broilers Configurations
  GET_ALL_CONFIGURATIONS : "GET_ALL_CONFIGURATIONS",
  SAVE_CONFIGURATIONS_FORM : "SAVE_CONFIGURATIONS_FORM" ,
  RESET_CONFIGURATIONS_FORM : "RESET_CONFIGURATIONS_FORM",
  SET_CONFIGUARTION_SUCCESS_MSG : "SET_CONFIGUARTION_SUCCESS_MSG" ,
  SET_CONFIGUARTION_ERROR_MSG : "SET_CONFIGUARTION_ERROR_MSG",
  SET_CONFIGUARTION_LOADING_REQUEST : "SET_CONFIGUARTION_LOADING_REQUEST" ,

  //Server Error 500 msg
  SET_SUCCESS_MSG_IN_SERVER_ERROR_PAGE : "SET_SUCCESS_MSG_IN_SERVER_ERROR_PAGE" ,
  SET_SERVER_ERROR_EMAIL_LOADING_REQUEST : "SET_SERVER_ERROR_EMAIL_LOADING_REQUEST" ,

  //LOOKUPS
  GET_ALL_FARMS_HOUSES : "GET_ALL_FARMS_HOUSES",
  GET_ALL_FARMS_CYCLES : "GET_ALL_FARMS_CYCLES" ,
  GET_ALL_TIPS : "GET_ALL_TIPS" ,
  SHOW_ALL_TIPS : "SHOW_ALL_TIPS" ,
  HIDE_SELECTED_TIP : "HIDE_SELECTED_TIP" ,
  HIDE_NO_TIP_MSG : "HIDE_NO_TIP_MSG" ,
  GET_ALL_LOOKUPS : "GET_ALL_LOOKUPS",
  GET_ALL_COUNTRIES : "GET_ALL_COUNTRIES" ,

  //dashboard
  GET_ALL_DASHBOARD_DATA : "GET_ALL_DASHBOARD_DATA",
  DASHBOARD_LOADING : "DASHBOARD_LOADING" ,

  //vaccination types
  GET_ALL_VACCINATION_TYPES : "GET_ALL_VACCINATION_TYPES",
  GET_VACCINATION_TYPE_DATA : "GET_VACCINE_TYPE_DATA",
  ADD_VACCINATION_TYPE : "ADD_VACCINATION_TYPE",
  EDIT_VACCINATION_TYPE : "EDIT_VACCINATION_TYPE",
  DELETE_VACCINATION_TYPES : "DELETE_VACCINATION_TYPES" ,
  IS_GET_VACCINATION_TYPE_ERROR : "IS_GET_VACCINATION_TYPE_ERROR",
  IS_GET_VACCINATION_TYPE_DATA_ERROR : "IS_GET_VACCINATION_TYPE_DATA_ERROR",
  IS_GET_VACCINATION_TYPE_LOADING : "IS_GET_VACCINATION_TYPE_LOADING",

//Medication types
  GET_ALL_MEDICATION_TYPES: "GET_ALL_MEDICATION_TYPES",
  GET_MEDICATION_TYPE_DATA:"GET_MEDICATION_TYPE",
  ADD_MEDICATION_TYPE:"ADD_MEDICATION_TYPE",
  EDIT_MEDICATION_TYPE : "EDIT_MEDICATION_TYPE",
  DELETE_MEDICATION_TYPES : "DELETE_MEDICATION_TYPES" ,
  IS_GET_MEDICATION_TYPE_ERROR:"IS_GET_MEDICATION_TYPE_ERROR",
  IS_GET_MEDICATION_TYPE_DATA_ERROR:"IS_GET_MEDICATION_TYPE_DATA_ERROR",
  IS_GET_MEDICATION_TYPE_LOADING : "IS_GET_MEDICATION_TYPE_LOADING",

  //Feed types
  GET_ALL_FEED_TYPES: "GET_ALL_FEED_TYPES",
  GET_FEED_TYPE_DATA:"GET_FEED_TYPE_DATA",
  ADD_FEED_TYPE:"ADD_FEED_TYPE",
  EDIT_FEED_TYPE : "EDIT_FEED_TYPE",
  DELETE_FEED_TYPES : "DELETE_FEED_TYPES",
  IS_GET_FEED_TYPE_ERROR:"IS_GET_FEED_TYPE_ERROR",
  IS_GET_FEED_TYPE_DATA_ERROR:"IS_GET_FEED_TYPE_DATA_ERROR",
  IS_GET_FEED_TYPE_LOADING : "IS_GET_FEED_TYPE_LOADING",

  //Energy types
  GET_ALL_ENERGY_TYPES: "GET_ALL_ENERGY_TYPES",
  GET_ENERGY_TYPE_DATA:"GET_ENERGY_TYPE_DATA",
  ADD_ENERGY_TYPE:"ADD_ENERGY_TYPE",
  EDIT_ENERGY_TYPE : "EDIT_ENERGY_TYPE",
  DELETE_ENERGY_TYPES : "DELETE_ENERGY_TYPES",
  IS_GET_ENERGY_TYPE_ERROR:"IS_GET_ENERGY_TYPE_ERROR",
  IS_GET_ENERGY_TYPE_DATA_ERROR:"IS_GET_ENERGY_TYPE_DATA_ERROR",
  IS_GET_ENERGY_TYPE_LOADING : "IS_GET_ENERGY_TYPE_LOADING",

  //Cull types
  GET_ALL_CULL_TYPES: "GET_ALL_CULL_TYPES",
  GET_CULL_TYPE_DATA:"GET_CULL_TYPE_DATA",
  ADD_CULL_TYPE:"ADD_CULL_TYPE",
  EDIT_CULL_TYPE : "EDIT_CULL_TYPE",
  DELETE_CULL_TYPES : "DELETE_CULL_TYPES",
  IS_GET_CULL_TYPE_ERROR:"IS_GET_CULL_TYPE_ERROR",
  IS_GET_CULL_TYPE_DATA_ERROR:"IS_GET_CULL_TYPE_DATA_ERROR",
  IS_GET_CULL_TYPE_LOADING : "IS_GET_CULL_TYPE_LOADING",


  //Sales types
  GET_ALL_SALES_TYPES: "GET_ALL_SALES_TYPES",
  GET_SALES_TYPE_DATA:"GET_SALES_TYPE_DATA",
  ADD_SALES_TYPE:"ADD_SALES_TYPE",
  EDIT_SALES_TYPE : "EDIT_SALES_TYPE",
  DELETE_SALES_TYPES : "DELETE_SALES_TYPES",
  IS_GET_SALES_TYPE_ERROR:"IS_GET_SALES_TYPE_ERROR",
  IS_GET_SALES_TYPE_DATA_ERROR:"IS_GET_SALES_TYPE_DATA_ERROR",
  IS_GET_SALES_TYPE_LOADING : "IS_GET_SALES_TYPE_LOADING",

  //other types categories
  GET_ALL_OTHER_TYPES_CATEGORIES: "__GET_ALL_OTHER_TYPES_CATEGORIES__",
  GET_OTHER_TYPE_CATEGORY_DATA:"GET_OTHER_TYPE_CATEGORY_DATA",
  ADD_OTHER_TYPE_CATEGORY:"ADD_OTHER_TYPE_CATEGORY",
  EDIT_OTHER_TYPE_CATEGORY : "EDIT_OTHER_TYPE_CATEGORY",
  DELETE_OTHER_TYPE_CATEGORY : "DELETE_OTHER_TYPE_CATEGORY",
  IS_GET_OTHER_TYPE_CATEGORY_ERROR:"IS_GET_OTHER_TYPE_CATEGORY_ERROR",
  IS_GET_OTHER_TYPE_CATEGORY_DATA_ERROR:"IS_GET_OTHER_TYPE_CATEGORY_DATA_ERROR",
  IS_GET_OTHER_TYPES_CATEGORY_LOADING : "IS_GET_OTHER_TYPES_CATEGORY_LOADING",


  //Other types
  GET_ALL_OTHER_TYPES: "GET_ALL_OTHER_TYPES",
  GET_OTHER_TYPE_DATA:"GET_OTHER_TYPE_DATA",
  ADD_OTHER_TYPE:"ADD_OTHER_TYPE",
  EDIT_OTHER_TYPE : "EDIT_OTHER_TYPE",
  DELETE_OTHER_TYPES : "DELETE_OTHER_TYPES",
  IS_GET_OTHER_TYPE_ERROR:"IS_GET_OTHER_TYPE_ERROR",
  IS_GET_OTHER_TYPE_DATA_ERROR:"IS_GET_OTHER_TYPE_DATA_ERROR",
  IS_GET_OTHER_TYPES_LOADING : "IS_GET_OTHER_TYPES_LOADING",


  //farms types
  GET_ALL_FARMS : "GET_ALL_FARMS",
  GET_FARM_DATA : "GET_FARM_DATA",
  ADD_FARM : "ADD_FARM",
  EDIT_FARM : "EDIT_FARM",
  DELETE_FARM : "DELETE_FARM" ,
  GET_FARM_REPORT_DATE:"GET_FARM_REPORT_DATE",
  IS_GET_FARM_ERROR : "IS_GET_FARM_ERROR",
  IS_GET_FARM_DATA_ERROR : "IS_GET_FARM_DATA_ERROR",
  IS_GET_FARM_LOADING : "IS_GET_FARM_LOADING" ,

  // reports types
  IS_GET_DOWNLOAD_REPORT_ERROR : "IS_GET_DOWNLOAD_REPORT_ERROR" ,

  //houses types
  GET_ALL_HOUSES : "GET_ALL_HOUSES",
  GET_HOUSE_DATA : "GET_HOUSE_DATA",
  ADD_HOUSE : "ADD_HOUSE",
  EDIT_HOUSE : "EDIT_HOUSE",
  DELETE_HOUSE : "DELETE_HOUSE" ,
  IS_GET_HOUSE_ERROR : "IS_GET_HOUSE_ERROR",
  IS_GET_HOUSE_DATA_ERROR : "IS_GET_HOUSE_DATA_ERROR",
  IS_GET_HOUSE_LOADING : "IS_GET_HOUSE_LOADING" ,


  //cycles
  GET_ALL_CYCLES : "GET_ALL_CYCLES",
  GET_CYCLE_DATA : "GET_CYCLE_DATA",
  ADD_CYCLE : "ADD_CYCLE",
  EDIT_CYCLE : "EDIT_CYCLE",
  DELETE_CYCLE : "DELETE_CYCLE" ,
  IS_GET_CYCLE_ERROR : "IS_GET_CYCLE_ERROR",
  IS_GET_CYCLE_DATA_ERROR : "IS_GET_CYCLE_DATA_ERROR",
  IS_GET_CYCLE_LOADING : "IS_GET_CYCLE_LOADING",


  //breed
  GET_ALL_BREEDS : "GET_ALL_BREEDS",
  GET_BREED_DATA : "GET_BREED_DATA",
  ADD_BREED : "ADD_BREED",
  EDIT_BREED : "EDIT_BREED",
  DELETE_BREED : "DELETE_BREED" ,
  IS_GET_BREED_ERROR : "IS_GET_BREED_ERROR",
  IS_GET_BREED_DATA_ERROR : "IS_GET_BREED_DATA_ERROR",
  IS_GET_BREED_LOADING : "IS_GET_BREED_LOADING" ,

  //hatcheries
  GET_ALL_HATCHERIES : "GET_ALL_HATCHERIES",
  GET_HATCHERY_DATA : "GET_HATCHERY_DATA",
  ADD_HATCHERY : "ADD_HATCHERY",
  EDIT_HATCHERY : "EDIT_HATCHERY",
  DELETE_HATCHERY : "DELETE_HATCHERY" ,
  IS_GET_HATCHERY_ERROR : "IS_GET_HATCHERY_ERROR",
  IS_GET_HATCHERY_DATA_ERROR : "IS_GET_HATCHERY_DATA_ERROR",
  IS_GET_HATCHER_LOADING : "IS_GET_HATCHER_LOADING",

  //placement types
  GET_ALL_PLACEMENTS : "GET_ALL_PLACEMENTS",
  GET_PLACEMENT_DATA : "GET_PLACEMENT_DATA",
  ADD_PLACEMENT : "ADD_PLACEMENT",
  EDIT_PLACEMENT : "EDIT_PLACEMENT",
  DELETE_PLACEMENT : "DELETE_PLACEMENT" ,
  IS_GET_PLACEMENT_ERROR : "IS_GET_PLACEMENT_ERROR",
  IS_GET_PLACEMENT_DATA_ERROR : "IS_GET_PLACEMENT_DATA_ERROR",
  IS_GET_PLACEMENT_LOADING : "IS_GET_PLACEMENT_LOADING",

  //CLOSURES TYPES
  GET_ALL_CLOSURES : "GET_ALL_CLOSURES",
  GET_CLOSURE_DATA : "GET_CLOSURE_DATA",
  ADD_CLOSURE : "ADD_CLOSURE",
  EDIT_CLOSURE : "EDIT_CLOSURE",
  DELETE_CLOSURE : "DELETE_CLOSURE" ,
  IS_GET_CLOSURE_ERROR : "IS_GET_CLOSURE_ERROR",
  IS_GET_CLOSURE_DATA_ERROR : "IS_GET_CLOSURE_DATA_ERROR",
  IS_GET_CLOSURE_LOADING : "IS_GET_CLOSURE_LOADING",

  //USERS TYPES
  GET_ALL_USERS : "GET_ALL_USERS",
  _GET_USER_DATA : "_GET_USER_DATA",
  ADD_USER : "ADD_USER",
  EDIT_USER : "EDIT_USER",
  DELETE_USER : "DELETE_USER" ,
  IS_GET_USER_ERROR : "IS_GET_USER_ERROR",
  IS_GET_USER_DATA_ERROR : "IS_GET_USER_DATA_ERROR",
  TOGGLE_USER_STATE:"TOGGLE_USER_STATE",
  IS_GET_USER_LOADING : "IS_GET_USER_LOADING",

  //STANDARD TYPES
  GET_ALL_STANDARDS : "GET_ALL_STANDARD",
  GET_STANDARD_DATA : "GET_STANDARD_DATA",
  ADD_STANDARD : "ADD_STANDARD",
  EDIT_STANDARD : "EDIT_STANDARD",
  DELETE_STANDARD : "DELETE_STANDARD" ,
  IS_GET_STANDARD_ERROR : "IS_GET_STANDARD_ERROR",
  IS_GET_STANDARD_DATA_ERROR : "IS_GET_STANDARD_DATA_ERROR",
  IS_GET_STANDARDS_LOADING : "IS_GET_STANDARDS_LOADING" ,

  //COST CATEGORY TYPES
  GET_ALL_COST_CATEGORIES : "GET_ALL_COST_CATEGORIES",
  GET_COST_CATEGORY_DATA : "GET_COST_CATEGORY_DATA",
  ADD_COST_CATEGORY : "ADD_COST_CATEGORY",
  EDIT_COST_CATEGORY : "EDIT_COST_CATEGORY",
  DELETE_COST_CATEGORY : "DELETE_COST_CATEGORY" ,
  IS_GET_COST_CATEGORY_ERROR : "IS_GET_COST_CATEGORY_ERROR",
  IS_GET_COST_CATEGORY_DATA_ERROR : "IS_GET_COST_CATEGORY_DATA_ERROR",
  IS_GET_COST_CATEGORY_LOADING : "IS_GET_COST_CATEGORY_LOADING" ,

  //DIRECT COST
  GET_ALL_DIRECT_COSTS : "GET_ALL_DIRECT_COSTS",
  GET_DIRECT_COST_DATA : "GET_DIRECT_COST_DATA",
  ADD_DIRECT_COST : "ADD_DIRECT_COST",
  EDIT_DIRECT_COST : "EDIT_DIRECT_COST",
  DELETE_DIRECT_COST : "DELETE_DIRECT_COST" ,
  IS_GET_DIRECT_COST_ERROR : "IS_GET_DIRECT_COST_ERROR",
  IS_GET_DIRECT_COST_DATA_ERROR : "IS_GET_DIRECT_COST_DATA_ERROR",
  IS_DIRECT_COST_LOADING : "IS_DIRECT_COST_LOADING" ,

  //INDIRECT COST
  GET_ALL_INDIRECT_COSTS : "GET_ALL_INDIRECT_COSTS",
  GET_INDIRECT_COST_DATA : "GET_INDIRECT_COST_DATA",
  ADD_INDIRECT_COST : "ADD_INDIRECT_COST",
  EDIT_INDIRECT_COST : "EDIT_INDIRECT_COST",
  DELETE_INDIRECT_COST : "DELETE_INDIRECT_COST" ,
  IS_GET_INDIRECT_COST_ERROR : "IS_GET_INDIRECT_COST_ERROR",
  IS_GET_INDIRECT_COST_DATA_ERROR : "IS_GET_INDIRECT_COST_DATA_ERROR",
  IS_INDIRECT_COST_LOADING : "IS_INDIRECT_COST_LOADING" ,

  //DailyData
  GET_ALL_LOAD_DATA : "GET_ALL_LOAD_DATA",
  CHANGE_CALENDER_DATE : "CHANGE_CALENDER_DATE" ,
  SET_CALENDER_MAX_DATE : "SET_CALENDER_MAX_DATE" ,
  IS_GET_CALENDER_DATA_ERROR : "IS_GET_CALENDER_DATA_ERROR" ,
  APP_LOADING_DATA : "APP_LOADING_DATA" ,
  SYNC_DATA : "SYNC_DATA" ,
  UNSYNCED_DATA : "UNSYNCED_DATA" ,
  IS_SUBMIT_DAILY_DATA_ERROR : "IS_SUBMIT_DAILY_DATA_ERROR" ,

  //mortality action type
  SYNC_MORTALITY_RECORD : "SYNC_MORTALITY_RECORD",
  UNSYNCED_MORTALITY_DATA : "UNSYNCED_MORTALITY_DATA" ,
  IS_GET_DAILY_MORTALITY_DATA_ERROR:"IS_GET_DAILY_MORTALITY_DATA_ERROR",

  //culls action types
  SYNC_CULLS_RECORD : "SYNC_CULLS_RECORD",
  UNSYNCED_CULLS_DATA : "UNSYNCED_CULLS_DATA" ,
  IS_GET_DAILY_CULLS_DATA_ERROR:"IS_GET_DAILY_CULLS_DATA_ERROR",

  //energy action types
  SYNC_ENERGY_RECORD : "SYNC_ENERGY_RECORD" ,
  UNSYNCED_ENERGY_DATA : "UNSYNCED_ENERGY_DATA" ,
  IS_GET_DAILY_ENERGY_DATA_ERROR : "IS_GET_DAILY_ENERGY_DATA_ERROR" ,

  //sales action types
  SYNC_SALES_RECORD : "SYNC_SALES_RECORD",
  UNSYNCED_SALES_DATA : "UNSYNCED_SALES_DATA" ,
  IS_GET_DAILY_SALES_DATA_ERROR : "IS_GET_DAILY_SALES_DATA_ERROR",
  //unaccountable action types
  SYNC_UNACCOUNTABLE_RECORD : "SYNC_UNACCOUNTABLE_RECORD",
  UNSYNCED_UNACCOUNTABLE_DATA : "UNSYNCED_UNACCOUNTABLE_DATA",
  IS_GET_UNACCOUNTABLE_DATA_ERROR:"IS_GET_UNACCOUNTABLE_DATA_ERROR",
  //medication consumption action types
  SYNC_MEDICATION_RECORD : "SYNC_MEDICATION_RECORD",
  UNSYNCED_MEDICATION_DATA : "UNSYNCED_MEDICATION_DATA",
  IS_GET_MEDICATION_CONSUMPTION_DATA_ERROR : "IS_GET_MEDICATION_CONSUMPTION_DATA_ERROR",
  //vaccination consumption action types
  SYNC_VACCINATION_RECORD : "SYNC_VACCINATION_RECORD",
  UNSYNCED_VACCINATION_DATA : "UNSYNCED_VACCINATION_DATA",
  IS_GET_VACCINATION_CONSUMPTION_DATA_ERROR : "IS_GET_VACCINATION_CONSUMPTION_DATA_ERROR",
  //other type consumption action type
  SYNC_OTHER_TYPES_RECORD : "SYNC_OTHER_TYPES_RECORD",
  UNSYNCED_OTHER_TYPES_DATA : "UNSYNCED_OTHER_TYPES_DATA",
  IS_GET_OTHER_TYPE_CONSUMPTION_DATA_ERROR:"IS_GET_OTHER_TYPE_CONSUMPTION_DATA_ERROR",

  //Deliveries
  GET_ALL_FEED_DELIVERIES : "GET_ALL_FEED_DELIVERIES" ,
  ADD_FEED_DELIVERY : "ADD_FEED_DELIVERY",
  EDIT_FEED_DELIVERY : "EDIT_FEED_DELIVERY",
  DELETE_FEED_DELIVERY : "DELETE_FEED_DELIVERY",
  GET_FEED_DELIVERY_DATA : "GET_FEED_DELIVERY_DATA" ,
  IS_GET_FEED_DELIVERY_ERROR : "IS_GET_FEED_DELIVERY_ERROR",
  IS_GET_FEED_DELIVERY_DATA_ERROR : "IS_GET_FEED_DELIVERY_DATA_ERROR",
  IS_GET_FEED_DELIVERY_LOADING : "IS_GET_FEED_DELIVERY_LOADING",

  GET_ALL_ENERGY_DELIVERIES : "GET_ALL_ENERGY_DELIVERIES" ,
  ADD_ENERGY_DELIVERY : "ADD_ENERGY_DELIVERY",
  EDIT_ENERGY_DELIVERY : "EDIT_ENERGY_DELIVERY",
  DELETE_ENERGY_DELIVERY : "DELETE_ENERGY_DELIVERY",
  GET_ENERGY_DELIVERY_DATA : "GET_ENERGY_DELIVERY_DATA" ,
  IS_GET_ENERGY_DELIVERY_ERROR : "IS_GET_ENERGY_DELIVERY_ERROR",
  IS_GET_ENERGY_DELIVERY_DATA_ERROR : "IS_GET_ENERGY_DELIVERY_DATA_ERROR",
  IS_GET_ENERGY_DELIVERY_LOADING : "IS_GET_ENERGY_DELIVERY_LOADING",


  GET_ALL_MEDICATION_DELIVERIES : "GET_ALL_MEDICATION_DELIVERIES" ,
  ADD_MEDICATION_DELIVERY : "ADD_MEDICATION_DELIVERY",
  EDIT_MEDICATION_DELIVERY : "EDIT_MEDICATION_DELIVERY",
  DELETE_MEDICATION_DELIVERY : "DELETE_MEDICATION_DELIVERY",
  GET_MEDICATION_DELIVERY_DATA : "GET_MEDICATION_DELIVERY_DATA" ,
  IS_GET_MEDICATION_DELIVERY_ERROR : "IS_GET_MEDICATION_DELIVERY_ERROR",
  IS_GET_MEDICATION_DELIVERY_DATA_ERROR : "IS_GET_MEDICATION_DELIVERY_DATA_ERROR",
  IS_GET_MEDICATION_DELIVERY_LOADING : "IS_GET_MEDICATION_DELIVERY_LOADING",


  GET_ALL_OTHER_DELIVERIES : "GET_ALL_OTHER_DELIVERIES" ,
  ADD_OTHER_DELIVERY : "ADD_OTHER_DELIVERY",
  EDIT_OTHER_DELIVERY : "EDIT_OTHER_DELIVERY",
  DELETE_OTHER_DELIVERY : "DELETE_OTHER_DELIVERY",
  GET_OTHER_DELIVERY_DATA : "GET_OTHER_DELIVERY_DATA" ,
  IS_GET_OTHER_DELIVERY_ERROR : "IS_GET_OTHER_DELIVERY_ERROR",
  IS_GET_OTHER_DELIVERY_DATA_ERROR : "IS_GET_OTHER_DELIVERY_DATA_ERROR",
  IS_GET_OTHER_DELIVERY_LOADING : "IS_GET_OTHER_DELIVERY_LOADING",


  GET_ALL_VACCINATION_DELIVERIES : "GET_ALL_VACCINATION_DELIVERIES" ,
  ADD_VACCINATION_DELIVERY : "ADD_VACCINATION_DELIVERY",
  EDIT_VACCINATION_DELIVERY : "EDIT_VACCINATION_DELIVERY",
  DELETE_VACCINATION_DELIVERY : "DELETE_VACCINATION_DELIVERY",
  GET_VACCINATION_DELIVERY_DATA : "GET_VACCINATION_DELIVERY_DATA" ,
  IS_GET_VACCINATION_DELIVERY_ERROR : "IS_GET_VACCINATION_DELIVERY_ERROR",
  IS_GET_VACCINATION_DELIVERY_DATA_ERROR : "IS_GET_VACCINATION_DELIVERY_DATA_ERROR",
  IS_GET_VACCINATION_DELIVERY_LOADING : "IS_GET_VACCINATION_DELIVERY_LOADING",

  //Network
  CHANGE_NETWORK_STATE : "CHANGE_NETWORK_STATE",
};

export default Types;
