import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from "./redux/reducers/rootReducer";

const persistConfig = {
    // Root
    key: 'root',
    // Storage Method (React)
    storage: storage,
    // Whitelist (Save Specific Reducers)
    whitelist: ['personal_data','changeState','configurationForm','farms_and_houses','farms_and_cycles' , 'tips' ,'all_houses','user_role' , 'feedDeliveries' , 'energyDeliveries' , 'otherDeliveries' , 'vaccinationDeliveries' , 'medicationDeliveries' , 'DailyData' , 'unSyncedData' , 'app_calendar' , 'feed_type_list' , 'energy_type_list' , 'breed_list' , 'cull_type_list','farms_list', 'cost_categories_type_list' , 'direct_cost_list' , 'indirect_cost_type_list' ,'cycle_list', 'hatchery_list','placement_list','closure_list','users_list','standard_list', 'house_list' , 'governorates','countries','sales_type_list', 'collapsibleDailyDataSections','medication_type_list','other_type_category_list' ,'other_type_list', 'vaccination_types_list' ],
    // Blacklist (Don't Save Specific Reducers)
    blacklist: [],
  };

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);
export { persistor, store };