import React, {useEffect, useState} from "react";

import CIcon from "@coreui/icons-react";
import {CInvalidFeedback, CInput, CAlert} from "@coreui/react";
import {Link} from "react-router-dom";
import {Formik} from "formik";
import * as Yup from "yup";
import getData from "../../../api/getData";
import {useTranslation} from "react-i18next";
import AuthLanguageToggle from "../AuthLanguageToggle";
import {versionNumber} from "../../../api/env";

function ResetPassword(props) {
    const {t} = useTranslation()
    const [alertVisibility,setAlertVisibility] = useState(false)
    const [serverErrMsg, setServerErrMsg]=useState("")
    useEffect(()=>{
        require('../../../scss/registration.scss')
        require('../../../scss/setter.scss')

    })

    const showSuccessMessage=()=>{
        setAlertVisibility(true)

    }
    const sendResetPasswordRequest=(values)=>{
        getData.post('/broilers/customer/reset_password',values).then(res=> {
            res.status ===200 ?  showSuccessMessage() : console.log('')
        }).catch(err=> {setServerErrMsg(err.response.data.email)})
    }
    const handleSubmit=(values)=>{
       sendResetPasswordRequest(values)
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().required(t('email_or_phone_number_is_required'))
    });
    // function to check if email format is valid
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const customValidation = (values) => {
        let errors={}
        if(values.email.includes('@') && !validateEmail(values.email)){
            errors.email=t('invalid_mail_format')
        }
        return errors
    }
    return (

        <Formik initialValues={
            {
                email:''
            }
        } onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validate={customValidation}
                >
            {({ handleChange , handleSubmit , handleBlur , errors , values , setFieldValue , touched }) => (
                <>
                    <CAlert  show={alertVisibility} className={"d-flex confirm-reset-password-sent bg-white flex-column justify-content-center align-items-center"} color="success alert-container">

                        <CIcon
                            name="logo-negative"
                            width={135}
                            src={'/avatars/mail-box.png'}
                            className={""}
                        />

                        <h4 className={" mt-4 text-center "}>{t('check_your_mail_or_mobile')}</h4>
                        <span className={"text-center"}>{t('we_sent_you_an_email_or_sms_message_to_confirmed_password_reset')},<br/><strong> {t('to_reset_your_account_password_check_your_email_or_mobile_and_confirm_registration')} </strong></span>
                        <Link to={'login'} className={"chicken-app-btn w-75 pt-3 pb-3 mt-4 text-center"}>{t('back_to_login')}</Link>
                    </CAlert>

                    <div className={"d-flex login-container text-white justify-content-center  position-fixed  align-items-center p-2"}>
                        {versionNumber}
                    </div>
                    <div className="container  vh-100  d-flex justify-content-center align-items-center ">
                        <div className="row registration-wrapper d-flex justify-content-center align-items-center ">
                            <div className="col  reset-password-wrapper">
                                <div className="card m-0">
                                    <div className="card-header  d-flex justify-content-center">
                                        <CIcon
                                            name="logo-negative"
                                            width={135}
                                            src={'/avatars/appLogoBlack.svg'}
                                        />
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className={"mb-2 mt-3 d-flex flex-column"}>
                                            <div>
                                                <strong className={"ml-2 d-block text-center"}>{t('enter_your_email_or_phonenumber_to_reset_password')}</strong>
                                            </div>
                                            <small className={"text-gray-100 d-block text-center mt-1"}>{t('you_will_receive_an_email_or_a_sms_message_to_confirm_password_reset')}</small>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className={"form-row"} >
                                                <div className="col-lg-12">
                                                    <div className={"p-2"}>
                                                        <AuthLanguageToggle/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="email">{t('email_/_phonenumber')}</label>
                                                        <CInput
                                                            type="text"
                                                            className="form-control text-body"
                                                            id="email"
                                                            name="email"
                                                            invalid={(!!errors.email || !!serverErrMsg) }
                                                            onChange={handleChange}
                                                            aria-describedby="emailHelp"
                                                            placeholder={t('please_enter_your_email_or_phonenumber')}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                        />
                                                        <CInvalidFeedback>{serverErrMsg !== "" ? serverErrMsg  :errors.email}</CInvalidFeedback>
                                                    </div>
                                                </div>

                                            </div>
                                            <button type="submit" className="btn btn-primary w-100 mt-1">{t('send_confirmation')}</button>
                                        </form>
                                        <div className={"mobile-login-from-registration  d-flex flex-column align-items-center justify-content-around mt-2"}>
                                            <span className={"text-gray-100"}>{t('already_running_with_chicken_app')}</span>
                                            <Link to='login'>{t('login')}</Link>
                                        </div>
                                    </div>
                                    <div>
                                        <small className={"text-gray-100 text-center  lg-hide pt-0 pb-2"}>
                                            {versionNumber}
                                        </small>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </>

            )}

        </Formik>





   )
}

export default ResetPassword