/**
 * Reducer for closure
 */
import Types from "../../actions/Types";
const initialState ={
    id:'',
    selling_date: '',
    fk_farm: '' ,
    fk_cycle: '',
    litter_sales:''
}
export const closure_list = (state = {closures:[],isLoaded:false} , action) => {
    switch(action.type){
        case Types.GET_ALL_CLOSURES:
            return {...state,closures: action.payload,isLoaded: true} ;
        case Types.IS_GET_CLOSURE_LOADING:
            return {...state , isLoaded: action.payload}
        default :
            return state ;
    }
}
export const closure_data = (state = initialState , action ) => {
    switch(action.type){
        case Types.GET_CLOSURE_DATA :
            return {...state , ...action.payload}
        default :
            return state ;
    }
}
export const get_closure_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_CLOSURE_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}
export const get_closure_data_error = (state = { visiable : false , errors : [] }  , action ) => {
    switch(action.type){
        case Types.IS_GET_CLOSURE_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}