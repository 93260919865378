/**
 * Reducer for house
 */
import Types from "../../actions/Types";

export const house_list = (state = {houses:[],isLoaded:false} , action ) => {
    switch(action.type){
        case Types.GET_ALL_HOUSES:
            return {...state,houses: action.payload,isLoaded: true} ;
        case Types.IS_GET_HOUSE_LOADING:
            return {...state, isLoaded: action.payload}
        default :
            return state ;
    }
}

export const house_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_HOUSE_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}

export const house_data_error = (state = { visiable : false , errors : [] }  , action ) => {
    switch(action.type){
        case Types.IS_GET_HOUSE_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}