import { combineReducers } from "redux";
import {get_otp_page_data_error, login, user_role, personal_data, geo_info} from "./registeration/Login";
import { lang } from "./locales/languages";
import {changeState} from "./sidebar";
import { wizardForm , wizard_data_error } from "./management/wizard";
import { configurationForm } from "./management/configurations";
import {collapsibleDailyDataSections} from "./layout/DailyData";
import {dashboard_data_list, dashboard_loading} from "./dashboard/dashboard";

import { vaccination_types_list , vaccination_type , vaccination_type_error , vaccination_type_data_error } from "./administration/vaccination";
import { medication_type_list,medication_type,medication_type_error,medication_type_data_error } from "./administration/medication";
import { feed_type_list,feed_type,feed_type_error,feed_type_data_error } from "./administration/feed";
import { cull_type_list,cull_type,cull_type_error,cull_type_data_error } from "./administration/cull";
import { sales_type_data_error, sales_type_error, sales_type_list, sales_type} from "./administration/sales";
import { energy_type_list,energy_type,energy_type_data_error,energy_type_error } from "./administration/energy";
import { other_type_list,other_type_error,other_type_data_error } from "./administration/other";
import { other_type_category_data_error, other_type_category_error, other_type_category,other_type_category_list } from "./administration/otherTypeCategory";

import { farms_list , get_farm_error , get_farm_data_error ,farm_report_date_ranges} from "./management/farms";
import { house_list,house_error,house_data_error} from "./management/houses";
import {cycle_data,cycle_data_error,cycle_error,cycle_list } from "./management/cycle";
import {breed_data_error, breed_error, breed_list} from "./management/breed";
import { placement_list,get_placement_error,placement_data, get_placement_data_error } from "./management/placements";
import { hatchery_data,hatchery_error,hatchery_list,hatchery_data_error} from "./management/hatchery";
import { closure_data,get_closure_data_error,get_closure_error,closure_list } from "./management/closures";
import { get_user_error,get_user_data_error,user_data,users_list,toggleUserState } from "./management/users";
import { get_standard_error,get_standard_data_error,standard_list } from "./management/standards";

import {direct_cost_data_error, direct_cost_error, direct_cost_list,} from "./costs/directCost";
import { indirect_cost_data_error, indirect_cost_error, indirect_cost_type_list,  } from "./costs/indirectCost";
import { cost_categories_type_list, cost_category_data_error, cost_category_error} from "./costs/categories";

import {
    all_houses,
    countries,
    farms_and_cycles,
    farms_and_houses,
    governorates,
    network_state, tips , no_tips
} from "./lookups/farms_houses";
import {
    feedDeliveries,
    get_feed_delivery_data_error, get_feed_delivery_error,
    get_feed_delivery_loading
} from "./deliveries/feed";
import {
    medicationDeliveries,
    get_medication_delivery_data_error, get_medication_delivery_error,
    get_medication_delivery_loading
} from "./deliveries/medication";
import {
    vaccinationDeliveries,
    get_vaccination_delivery_data_error, get_vaccination_delivery_error,
    get_vaccination_delivery_loading
} from "./deliveries/vaccination";
import {
    otherDeliveries,
    get_other_delivery_data_error, get_other_delivery_error,
    get_other_delivery_loading
} from "./deliveries/other";
import {app_calendar , get_calendar_data_error } from "./dailydata/calendar";
import {
    app_loading,
    daily_culls_type_data_error, daily_energy_type_data_error,
    daily_mortality_type_data_error,
    daily_sales_type_data_error,
    daily_unaccountable_type_data_error,
    DailyData, get_daily_data_error,
    medication_consumption_type_data_error, other_type_consumption_type_data_error,
    unSyncedData,
    vaccination_consumption_type_data_error
} from "./dailydata/DailyData";
import {get_report_download_error} from "./reports/report";
import Types from "../actions/Types";
import {
    energyDeliveries,
    get_energy_delivery_data_error,
    get_energy_delivery_error,
    get_energy_delivery_loading
} from "./deliveries/energy";
import {serverErrorForm} from "./serverError/error";

const appReducer = combineReducers({
    login ,
    lang ,
    changeState ,
    DailyData ,
    unSyncedData ,
    get_daily_data_error ,
    app_loading ,
    wizardForm ,
    wizard_data_error ,
    configurationForm ,
    serverErrorForm,
    collapsibleDailyDataSections,
    dashboard_data_list,
    dashboard_loading ,
    vaccination_types_list ,
    vaccination_type ,
    vaccination_type_error ,
    vaccination_type_data_error,
    medication_type_list,
    medication_type,
    medication_type_error,
    medication_type_data_error,
    get_report_download_error,
    feed_type_list,
    feed_type,
    feed_type_error,
    feed_type_data_error,
    medicationDeliveries,
    get_medication_delivery_data_error,
    get_medication_delivery_loading ,
    get_medication_delivery_error,
    vaccinationDeliveries,
    get_vaccination_delivery_data_error,
    get_vaccination_delivery_loading ,
    get_vaccination_delivery_error,
    otherDeliveries,
    get_other_delivery_data_error,
    get_other_delivery_loading ,
    get_other_delivery_error,
    cull_type_list,
    cull_type,
    cull_type_error,
    cull_type_data_error,
    sales_type_data_error,
    sales_type_error,
    sales_type_list,
    sales_type,
    energy_type_list,
    energy_type,
    energy_type_error,
    energy_type_data_error,
    other_type_category_data_error,
    other_type_category_error,
    other_type_category,
    other_type_category_list,
    other_type_list,
    other_type_error,
    other_type_data_error,
    farms_list,
    get_farm_error,
    farm_report_date_ranges,
    get_farm_data_error,
    house_list,
    house_error,
    house_data_error,
    hatchery_data,
    hatchery_error,
    hatchery_list,
    hatchery_data_error,
    cycle_data,
    cycle_data_error,
    cycle_error,
    cycle_list,
    breed_data_error,
    breed_error,
    breed_list,
    placement_list,
    get_placement_error,
    placement_data,
    get_placement_data_error,
    closure_data,
    get_closure_data_error,
    get_closure_error,
    closure_list,
    get_user_error,
    get_user_data_error,
    user_data,
    users_list,
    toggleUserState,
    get_standard_error,
    get_standard_data_error,
    standard_list,
    farms_and_houses,
    farms_and_cycles,
    tips ,
    no_tips ,
    all_houses ,
    governorates,
    countries,
    feedDeliveries,
    get_feed_delivery_data_error,
    get_feed_delivery_loading ,
    get_feed_delivery_error ,
    energyDeliveries,
    get_energy_delivery_error,
    get_energy_delivery_data_error,
    get_energy_delivery_loading,
    app_calendar,
    get_calendar_data_error ,
    user_role,
    personal_data,
    get_otp_page_data_error ,
    network_state ,
    direct_cost_data_error,
    direct_cost_error,
    direct_cost_list,
    cost_categories_type_list,
    cost_category_data_error,
    cost_category_error,
    indirect_cost_data_error,
    indirect_cost_error,
    indirect_cost_type_list,
    medication_consumption_type_data_error,
    vaccination_consumption_type_data_error,
    other_type_consumption_type_data_error,
    daily_sales_type_data_error,
    daily_unaccountable_type_data_error,
    daily_culls_type_data_error,
    daily_energy_type_data_error ,
    daily_mortality_type_data_error,
    geo_info
});

const rootReducer = (state, action) => {
    if (action.type === Types.LOGOUT) {
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer ;

