import Types from "../../actions/Types";
let initialState={
    id: '',
    rejects_types_choices: '',
    un_accounted: '',
    fk_customer: ''
}
export const cull_type_list =(state = {cull:[],isLoaded:false},action)=>{
    switch (action.type) {
        case Types.GET_ALL_LOOKUPS :
            return {...state,cull:action.payload.culls_type};
        case Types.GET_ALL_CULL_TYPES:
            return {...state,cull:action.payload,isLoaded:true};
        case Types.IS_GET_CULL_TYPE_LOADING:
            return {...state, isLoaded:action.payload}
        default:
            return state;
    }
}
export const cull_type = (state = initialState , action ) => {
    switch(action.type){
        case Types.GET_CULL_TYPE_DATA :
            return  action.payload
        default :
            return state ;
    }
}
export const cull_type_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_CULL_TYPE_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}
export const cull_type_data_error = (state  = { visiable : false , errors : [],initialState }  , action) => {
    switch(action.type){
        case Types.IS_GET_CULL_TYPE_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}

