import Types from "../../actions/Types";
import {getTodayDate} from "../../../reusable/helpers";

const Init_state = { FarmName : "" , Governorate : "0" , HouseName : "" , HouseArea : "" , HousesNumber : "" , PlacementDate : getTodayDate() ,PlacementQuantity : "" , DoA : "" , breed : "0" , wizardStep : 0 }

export const wizardForm = ( state = Init_state , action ) => {
    switch(action.type){
        case Types.FARMS_WIZARD_FORM_SUBMITTED :
            return {...state , FarmName : action.payload.farmName , HouseName : action.payload.farmName , Governorate : action.payload.governorate , wizardStep : 1 } ;

        case Types.HOUSES_WIZARD_FORM_SUBMITTED :
            return {...state , HouseName : action.payload.houseName , HouseArea : action.payload.houseArea , HousesNumber : action.payload.houseNumber  , wizardStep : 2 } ;

        case Types.PLACEMENTS_WIZARD_FORM_SUBMITTED :
            return {...state , PlacementDate : action.payload.placementDate , PlacementQuantity : action.payload.placementQuantity , DoA : action.payload.placementDoa  , breed : action.payload.placementBreed } ;

        case Types.CLEAR_WIZARD_FORM_DATA :
            state = Init_state ;
            return state;

        case Types.BACK_TO_FARM_WIZARD_FORM :
            return {...state , wizardStep : 0 } ;

        case Types.BACK_TO_HOUSE_WIZARD_FORM :
            return {...state , wizardStep : 1 } ;

        default :
            return state ;
    };
};

export const wizard_data_error = (state = { visiable : false , errors : [] }  , action ) => {
    switch(action.type){
        case Types.IS_GET_WIZARD_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}