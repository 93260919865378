import Types from "../../actions/Types";
import {getTodayDate} from "../../../reusable/helpers";

const Init_state = { selected_date : { Day : String(new Date().getDate()).padStart(2, '0') , Month: String(new Date().getMonth() + 1).padStart(2, '0') , Year : new Date().getFullYear() }  , includeDates : [] , minDate : getTodayDate() , maxDate : getTodayDate() } ;

export const app_calendar = ( state = Init_state , action) => {
    switch (action.type){
        case Types.CHANGE_CALENDER_DATE :
            return {...state , selected_date: action.payload} ;

        case Types.SET_CALENDER_MAX_DATE :
            return {...state , maxDate: getTodayDate() } ;

        case Types.GET_ALL_LOAD_DATA :
            return {...state , minDate: action.payload.min_placement_date } ;

        default :
            return state ;
    }
}

export const get_calendar_data_error = (state = { visiable : false }  , action ) => {
    switch(action.type){
        case Types.IS_GET_CALENDER_DATA_ERROR :
            return {...state , visiable : action.payload } ;
        default :
            return state ;
    }
}