import Types from "../../actions/Types";

export const app_loading = (state = false , action ) => {
    switch (action.type) {
        case Types.APP_LOADING_DATA :
            return action.payload ;

        default :
            return  state ;
    }
}

export const DailyData = (state = {} , action) => {
    switch (action.type) {
        case Types.GET_ALL_LOAD_DATA :
            return action.payload.daily_data ;

        case Types.UNSYNCED_MORTALITY_DATA :
            const mor_key = action.payload[0].the_date.toString() + "/" + action.payload[0].fk_house.toString()
            let mor_state = Object.assign({}, state)
            mor_state[mor_key]['detailed_mortality'] = JSON.stringify(action.mortality_payload)
            return mor_state ;

        case Types.UNSYNCED_CULLS_DATA :
            const cull_key = action.payload[0].the_date.toString() + "/" + action.payload[0].fk_house.toString()
            let cull_state = Object.assign({}, state)
            cull_state[cull_key]['detailed_culls'] = JSON.stringify(action.culls_payload)
            return cull_state ;

        case Types.UNSYNCED_SALES_DATA :
            const sales_key = action.payload[0].the_date.toString() + "/" + action.payload[0].fk_house.toString()
            let sales_state = Object.assign({}, state)
            sales_state[sales_key]['detailed_sales'] = JSON.stringify(action.sales_payload)
            return sales_state ;

        case Types.SYNC_DATA :
            const key = action.payload.the_date.toString() + "/" + action.payload.house_id.toString()
            let new_state = Object.assign({}, state)
            new_state[key] = action.payload
            return new_state

        default :
            return state ;
    }
}

export const unSyncedData = ( state = [] , action ) => {
    switch (action.type) {
        case Types.UNSYNCED_DATA :
            let new_state = state.filter((record)=> !(record.fk_house.toString() === action.payload.fk_house.toString() && record.the_date.toString() == action.payload.the_date.toString()))
            new_state.push(action.payload)
            return new_state ;

        case Types.UNSYNCED_MORTALITY_DATA :
            let mor_found_flag = 0 ;
            let unsynced_mor_state = Object.assign([], state)
            action.mortality_payload.map((mor_record)=> (mor_record.hasOwnProperty('id')&& mor_record['id'].toString().indexOf("_") !== -1) && delete mor_record['id'])
            for (let unsynced_record of unsynced_mor_state ){
                if((unsynced_record.fk_house.toString() === action.payload[0].fk_house.toString() && unsynced_record.the_date.toString() === action.payload[0].the_date.toString())){
                    unsynced_record.mortality = action.mortality_payload
                    mor_found_flag = 1
                }
            }
            if(mor_found_flag != 1){
                unsynced_mor_state.push(action.payload[0])
            }
            return unsynced_mor_state ;


        case Types.UNSYNCED_CULLS_DATA :
            let cull_found_flag = 0 ;
            let unsynced_cull_state = Object.assign([], state)
            action.culls_payload.map((cull_record)=> (cull_record.hasOwnProperty('id')&& cull_record['id'].toString().indexOf("_") !== -1) && delete cull_record['id'])
            for (let unsynced_record of unsynced_cull_state ){
                if((unsynced_record.fk_house.toString() === action.payload[0].fk_house.toString() && unsynced_record.the_date.toString() === action.payload[0].the_date.toString())){
                    unsynced_record.culls = action.culls_payload
                    cull_found_flag = 1
                }
            }
            if(cull_found_flag != 1){
                unsynced_cull_state.push(action.payload[0])
            }
            return unsynced_cull_state ;

        case Types.GET_ALL_LOAD_DATA :
            return [] ;
        default :
            return state ;
    }
}

export const get_daily_data_error = (state = { visiable : false , errors : [] }  , action ) => {
    switch(action.type){
        case Types.IS_SUBMIT_DAILY_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}

export const medication_consumption_type_data_error = (state={visible : false , errors : []}, action) => {
    switch (action.type) {
        case Types.IS_GET_MEDICATION_CONSUMPTION_DATA_ERROR:
            return {...state,visible : action.payload, errors:action.errors}
        default :
            return state
    }
}

export const vaccination_consumption_type_data_error = (state={visible : false , errors : []}, action) => {
    switch (action.type) {
        case Types.IS_GET_VACCINATION_CONSUMPTION_DATA_ERROR:
            return {...state,visible : action.payload, errors:action.errors}
        default :
            return state
    }
}
export const other_type_consumption_type_data_error = (state={visible : false , errors : []}, action) => {
    switch (action.type) {
        case Types.IS_GET_OTHER_TYPE_CONSUMPTION_DATA_ERROR:
            return {...state,visible : action.payload, errors:action.errors}
        default :
            return state
    }
}
export const daily_sales_type_data_error = (state={visible : false , errors : []}, action) => {
    switch (action.type) {
        case Types.IS_GET_DAILY_SALES_DATA_ERROR:
            return {...state,visible : action.payload, errors:action.errors}
        default :
            return state
    }
}
export const daily_unaccountable_type_data_error = (state={visible : false , errors : []}, action) => {
    switch (action.type) {
        case Types.IS_GET_UNACCOUNTABLE_DATA_ERROR:
            return {...state,visible : action.payload, errors:action.errors}
        default :
            return state
    }
}
export const daily_culls_type_data_error = (state={visible : false , errors : []}, action) => {
    switch (action.type) {
        case Types.IS_GET_DAILY_CULLS_DATA_ERROR:
            return {...state,visible : action.payload, errors:action.errors}
        default :
            return state
    }
}

export const daily_energy_type_data_error = (state={visible : false , errors : []}, action) => {
    switch (action.type) {
        case Types.IS_GET_DAILY_ENERGY_DATA_ERROR:
            return {...state,visible : action.payload, errors:action.errors}
        default :
            return state
    }
}

export const daily_mortality_type_data_error = (state={visible : false , errors : []}, action) => {
    switch (action.type) {
        case Types.IS_GET_DAILY_MORTALITY_DATA_ERROR:
            return {...state,visible : action.payload, errors:action.errors}
        default :
            return state
    }
}