import Types from "../../actions/Types";

export const farms_list = (state = {farms:[],isLoaded:false} , action ) => {
    switch(action.type){
        case Types.GET_ALL_FARMS:
            return {...state,farms: action.payload,isLoaded: true} ;
        case Types.IS_GET_FARM_LOADING :
            return {...state,isLoaded:action.payload};
        default :
            return state ;
    }
}

export const get_farm_error = (state = false , action ) => {
    switch(action.type){
        case Types.IS_GET_FARM_ERROR :
            return action.payload ;
        default :
            return state ;
    }
}

export const farm_report_date_ranges=(state={},action)=>{
    switch (action.type) {
        case Types.GET_FARM_REPORT_DATE:
            return{...state,...action.payload}
        default:
            return state
    }
}

export const get_farm_data_error = (state = { visiable : false , errors : [] }  , action ) => {
    switch(action.type){
        case Types.IS_GET_FARM_DATA_ERROR :
            return {...state , visiable : action.payload , errors : action.errors } ;
        default :
            return state ;
    }
}