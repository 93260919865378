import requester from "../../../api/requester";
import Types from "../Types";
import {get_all_loadData} from "../lookups";
import {store} from "../../../../src/store" ;
import {get_string_date} from "../../../reusable/helpers";

export const change_calender_date = (date) => async dispatch => {
    dispatch({
        type: Types.CHANGE_CALENDER_DATE,
        payload: date,
    });
};

export const display_calendar_alert_msg = (alert_value) => async dispatch => {
    dispatch({
        type: Types.IS_GET_CALENDER_DATA_ERROR,
        payload: alert_value,
    });
}

export const set_calender_max_date = () => async dispatch => {
    dispatch({
        type: Types.SET_CALENDER_MAX_DATE,
    });
}

export const sync_data = (reducer_body , api_body) => async dispatch => {
    dispatch({ type : Types.IS_SUBMIT_DAILY_DATA_ERROR , payload : false , errors : [] });

    await requester()
        .post('dailydata/save_data' , [api_body])
        .then((response) => {
            if (response) {
                dispatch(get_all_loadData(api_body.the_date))
            }
        })
        .catch((error) => {
            if(error.message === "Network Error"){
                dispatch({ type : Types.SYNC_DATA , payload : reducer_body });
                dispatch({ type : Types.UNSYNCED_DATA , payload : api_body });
            }else{
                dispatch({ type : Types.IS_SUBMIT_DAILY_DATA_ERROR , payload : true , errors : Object.values(error.response.data[0]) });
                window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            }
            console.log(error , "error message");
        });
}

export const sync_offline_data = () => async dispatch => {
    const request_body = store.getState().unSyncedData ;
    const selected_date = get_string_date(store.getState().app_calendar.selected_date)
    if(request_body.length !== 0) {

        await requester()
            .post('dailydata/save_data', request_body)
            .then((response) => {
                if (response) {
                    dispatch(get_all_loadData(selected_date))
                }
            })
            .catch((error) => {
                console.log(error,error.message, "error message in syncing offline data");
            });
    }
}

export const sync_mortality_record = (mortality_data , house_mor_record) => async dispatch => {
    await requester()
        .post('dailydata/save_data' , mortality_data)
        .then((response) => {
            if (response) {
                dispatch({
                    type: Types.SYNC_MORTALITY_RECORD,
                    payload: response.data,
                });
                window.location = `/dailydata/${mortality_data[0].fk_house}`;
            }
        })
        .catch((error) => {
            console.log(error, "error message");
            if(error.message === "Network Error"){
                dispatch({ type : Types.UNSYNCED_MORTALITY_DATA , payload : mortality_data , mortality_payload : house_mor_record });
            }
            else {
                dispatch({type:Types.IS_GET_DAILY_MORTALITY_DATA_ERROR ,payload:true ,errors:Object.values(error.response.data) })
                window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            }
        });
};

export const sync_culls_record = (culls_data , house_culls_record) => async dispatch => {
    await requester()
        .post('dailydata/save_data' , culls_data)
        .then((response) => {
            if (response) {
                dispatch({
                    type: Types.SYNC_CULLS_RECORD,
                    payload: response.data,
                });
                window.location = `/dailydata/${culls_data[0].fk_house}`;
            }
        })
        .catch((error) => {
            if(error.message === "Network Error"){
                dispatch({ type : Types.UNSYNCED_CULLS_DATA , payload : culls_data , culls_payload : house_culls_record });
            }else
            {
                dispatch({type:Types.IS_GET_DAILY_CULLS_DATA_ERROR ,payload:true ,errors:Object.values(error.response.data) })
                window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            }
            console.log(error, "error message");
        });
};

export const sync_energy_record = (energy_data , house_energy_record) => async dispatch => {
    await requester()
        .post('dailydata/save_data' , energy_data)
        .then((response) => {
            if (response) {
                dispatch({
                    type: Types.SYNC_ENERGY_RECORD,
                    payload: response.data,
                });
                window.location = `/dailydata/${energy_data[0].fk_house}`;
            }
        })
        .catch((error) => {
            if(error.message === "Network Error"){
                dispatch({ type : Types.UNSYNCED_ENERGY_DATA , payload : energy_data , energy_payload : house_energy_record });
            }else
            {
                dispatch({type:Types.IS_GET_DAILY_ENERGY_DATA_ERROR ,payload:true ,errors:Object.values(error.response.data) })
                window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            }
            console.log(error, "error message");
        });
};

export const sync_unaccountable_record = (unaccountable_data , house_culls_record) => async dispatch => {
    await requester()
        .post('dailydata/save_data' , unaccountable_data)
        .then((response) => {
            if (response) {
                dispatch({
                    type: Types.SYNC_UNACCOUNTABLE_RECORD,
                    payload: response.data,
                });
                window.location = `/dailydata/${unaccountable_data[0].fk_house}`;
            }
        })
        .catch((error) => {
            if(error.message === "Network Error"){
                dispatch({ type : Types.UNSYNCED_UNACCOUNTABLE_DATA , payload : unaccountable_data , unaccountable_payload : house_culls_record });
            }
            else {
                dispatch({type:Types.IS_GET_UNACCOUNTABLE_DATA_ERROR ,payload:true ,errors:Object.values(error.response.data) })
            }
            console.log(error, "error message");
        });
};

export const sync_medication_record = (medication_data , house_culls_record) => async dispatch => {
    await requester()
        .post('dailydata/save_data' , medication_data)
        .then((response) => {
            if (response) {
                dispatch({
                    type: Types.SYNC_MEDICATION_RECORD,
                    payload: response.data,
                });
            }
            window.location = `/dailydata/${medication_data[0].fk_house}`;
        })
        .catch((error) => {
            console.log(error, "error message");
            if(error.message === "Network Error"){
                dispatch({ type : Types.UNSYNCED_MEDICATION_DATA , payload : medication_data , medication_payload : house_culls_record });
            }
            else
            {
                dispatch({type:Types.IS_GET_MEDICATION_CONSUMPTION_DATA_ERROR ,payload:true ,errors:Object.values(error.response.data) })
            }
        });
};

export const sync_vaccination_record = (vaccination_data , house_culls_record) => async dispatch => {
    await requester()
        .post('dailydata/save_data' , vaccination_data)
        .then((response) => {
            if (response) {
                dispatch({
                    type: Types.SYNC_VACCINATION_RECORD,
                    payload: response.data,
                });
                window.location = `/dailydata/${vaccination_data[0].fk_house}`;
            }
        })
        .catch((error) => {
            if(error.message === "Network Error"){
                dispatch({ type : Types.UNSYNCED_VACCINATION_DATA , payload : vaccination_data , vaccination_payload : house_culls_record });
            }
            else
            {
                dispatch({type:Types.IS_GET_VACCINATION_CONSUMPTION_DATA_ERROR ,payload:true ,errors:Object.values(error.response.data) })
                window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            }
        });
};

export const sync_other_type_record = (othertype_data , house_culls_record) => async dispatch => {
    await requester()
        .post('dailydata/save_data' , othertype_data)
        .then((response) => {
            if (response) {
                dispatch({
                    type: Types.SYNC_OTHER_TYPES_RECORD,
                    payload: response.data,
                });
               window.location = `/dailydata/${othertype_data[0].fk_house}`;
            }
        })
        .catch((error) => {
            if(error.message === "Network Error"){
                dispatch({ type : Types.UNSYNCED_OTHER_TYPES_DATA , payload : othertype_data , vaccination_payload : house_culls_record });
            }
            else {
                dispatch({type:Types.IS_GET_OTHER_TYPE_CONSUMPTION_DATA_ERROR ,payload:true ,errors:Object.values(error.response.data) })
                window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            }
            console.log(error, "error message");
        });
};


export const sync_sales_record = (sales_data , house_sales_record) => async dispatch => {
    await requester()
        .post('dailydata/save_data' , sales_data)
        .then((response) => {
            if (response) {
                dispatch({
                    type: Types.SYNC_SALES_RECORD,
                    payload: response.data,
                });
                window.location = `/dailydata/${sales_data[0].fk_house}`;
            }
        })
        .catch((error) => {
            if(error.message === "Network Error"){
                dispatch({ type : Types.UNSYNCED_SALES_DATA , payload : sales_data , sales_payload : house_sales_record });
            }
            else
            {
                dispatch({type:Types.IS_GET_DAILY_SALES_DATA_ERROR ,payload:true ,errors:Object.values(error.response.data) })
                window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            }
            console.log(error, "error message");
        });
};

export const set_error_in_daily_data_submit_page = (error_data_value) => dispatch => {
    dispatch({ type : Types.IS_SUBMIT_DAILY_DATA_ERROR , payload : error_data_value , errors : [] });
}

export const set_medication_consumption_type_data_error = (error_data_value) => dispatch => {
    dispatch({ type : Types.IS_GET_MEDICATION_CONSUMPTION_DATA_ERROR , payload : error_data_value , errors : [] });
};

export const set_vaccination_consumption_type_data_error = (error_data_value) => dispatch => {
    dispatch({ type : Types.IS_GET_VACCINATION_CONSUMPTION_DATA_ERROR , payload : error_data_value , errors : [] });
};

export const set_other_type_consumption_type_data_error = (error_data_value) => dispatch => {
    dispatch({ type : Types.IS_GET_OTHER_TYPE_CONSUMPTION_DATA_ERROR , payload : error_data_value , errors : [] });
};

export const set_daily_data_sales_data_error = (error_data_value) => dispatch => {
    dispatch({ type : Types.IS_GET_DAILY_SALES_DATA_ERROR , payload : error_data_value , errors : [] });
};

export const set_unaccountable_data_error = (error_data_value) => dispatch => {
    dispatch({ type : Types.IS_GET_UNACCOUNTABLE_DATA_ERROR , payload : error_data_value , errors : [] });
};

export const set_daily_culls_data_error = (error_data_value) => dispatch => {
    dispatch({ type : Types.IS_GET_DAILY_CULLS_DATA_ERROR , payload : error_data_value , errors : [] });
}

export const set_daily_energy_data_error = (error_data_value) => dispatch => {
    dispatch({ type : Types.IS_GET_DAILY_ENERGY_DATA_ERROR , payload : error_data_value , errors : [] });
}

export const set_daily_mortality_data_error = (error_data_value) => dispatch => {
    dispatch({ type : Types.IS_GET_DAILY_MORTALITY_DATA_ERROR , payload : error_data_value , errors : [] });
}

